import React, { useEffect, useState } from "react";

import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useFirestore } from "../../providers/FirestoreProvider";
import ClassCard from "../../components/ClassCard/ClassCard";
import IconButton from "../../components/IconButton/IconButton";
import { useNotification } from "../../providers/NotificationProvider";
import ScheduleCard from "../../components/ScheduleCard/ScheduleCard";
import InfoModal from "../../components/InfoModal/InfoModal";
import GroupClassModal from "../../components/GroupClassModal/GroupClassModal";
import GroupCard from "../../components/GroupCard/GroupCard";
import "./Classes.css";

const Classes = () => {
  const firestore = useFirestore();
  const notification = useNotification();

  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalIsOpen] = useState(false);
  const [university, setUniversity] = useState({});

  const [classes, setClasses] = useState([]);
  const [schedule, setSchedule] = useState([]);
  // const [questions, setQuestions] = useState([]);

  /**
   * Get university data
   */
  useEffect(() => {
    if (!firestore.userData?.universityId) {
      return;
    }

    let isMounted = true;

    firestore
      .getDocument(`universities/${firestore.userData.universityId}`)
      .then((doc) => {
        if (isMounted) {
          setUniversity(firestore.docData(doc));
        }
      })
      .catch((e) => {
        notification.error("Error retrieving data");
      });

    return () => {
      isMounted = false;
    };
  }, [firestore, firestore.userData?.universityId, notification]);

  /**
   * Get evaluation questions
   */
  useEffect(() => {
    const fetchData = async () => {
      if (!firestore.userData?.universityId) {
        return;
      }

      const q = firestore.query(`questions`);
      try {
        const questionSetter = [];
        const documents = await firestore.getDocuments(q);
        documents.forEach((doc) => {
          const data = doc.data();

          questionSetter.push({
            label: data["question"],
            value: doc.id,
          });
        });
        // setQuestions(questionSetter);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };
    fetchData();
  }, [firestore]);

  /**
   * Get class data
   */
  useEffect(() => {
    let isMounted = true;

    const classList = [];

    firestore.userData?.classList?.forEach((classId) => {
      classList.push(
        firestore.getDocument(
          `universities/${firestore.userData.universityId}/classes/${classId}`
        )
      );
    });

    Promise.all(classList)
      .then((values) => {
        if (isMounted) {
          setClasses(
            values
              .map((doc) => firestore.docData(doc))
              .sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      })
      .catch(() => {
        notification.error("Error retrieving data");
      });

    return () => {
      isMounted = false;
    };
  }, [firestore, firestore.userData?.classList, notification]);

  /**
   * Set schedule
   */
  useEffect(() => {
    const now = new Date();

    // Get only the current day's classes that are in progress or later in the day
    let todaysClasses = classes?.filter((current) => {
      if (current.times && current.times[now.getDay()]) {
        const endDate = current.times[now.getDay()].endTime.toDate();

        // Adjust the dates to match today's date
        endDate.setFullYear(now.getFullYear());
        endDate.setMonth(now.getMonth());
        endDate.setDate(now.getDate());

        return now <= endDate;
      }

      return false;
    });

    setSchedule(
      todaysClasses.sort(
        (a, b) =>
          a.times[now.getDay()].startTime - b.times[now.getDay()].endTime
      )
    );
  }, [classes]);

  return (
    <div className="Classes__container">
      <GroupClassModal
        isOpen={selectModalOpen}
        setIsOpen={setSelectModalOpen}
        role={firestore.userData?.role}
      ></GroupClassModal>

      <InfoModal isOpen={infoModalOpen} setIsOpen={setInfoModalIsOpen} />
      <div className="Classes__class-section">
        <div className="Classes__header">
          <h1 className="Classes__university-name">{university.name}</h1>
          <div className="Classes__header-buttons">
            {" "}
            <IconButton
              icon={faPlus}
              onClick={() =>
                setSelectModalOpen(
                  (prevSelectModalIsOpen) => !prevSelectModalIsOpen
                )
              }
            />
            {/* <IconButton
              icon={faQuestion}
              onClick={() =>
                setInfoModalIsOpen(
                  (prevInfoModalIsOpen) => !prevInfoModalIsOpen
                )
              }
            /> */}
          </div>
        </div>

        <div className="Classes__class-list">
          {classes.length ? (
            classes.map((classData, i) => {
              // Check if the "Type" property is equal to "class"
              if (classData.type === "class") {
                return <ClassCard classData={classData} key={i} />;
              }
              if (classData.type === "group") {
                return <GroupCard groupData={classData} key={i} />;
              }

              // Add a default return statement
              return null; // or return <></>;
            })
          ) : (
            <div className="Classes__add-class-msg">
              {firestore.userData?.role === "teacher"
                ? "Use the plus to add your first class!"
                : "Use the plus to join your first class!"}
            </div>
          )}
        </div>
      </div>

      <div className="Classes__schedule">
        <h1 className="Classes__header">Today's Schedule</h1>
        <div className="Classes__class-list">
          {schedule.length ? (
            schedule.map((classData, i) => (
              <ScheduleCard
                classData={classData}
                showGoButton={i === 0}
                key={i}
              />
            ))
          ) : (
            <p className="Classes__no-classes-msg">
              You do not have any classes today
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Classes;
