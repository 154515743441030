import { useState, useEffect, useRef } from "react";
import { sendEmailVerification } from "firebase/auth";
import { Link } from "react-router-dom";

import "./VerifyEmail.css";
import AuthForm from "../../components/AuthForm/AuthForm";
import { useAuth } from "../../providers/AuthProvider";
import { useNotification } from "../../providers/NotificationProvider";

const COOLDOWN = 60_000;

export default function VerifyEmail() {
  const auth = useAuth();
  const notification = useNotification();

  const initialEmailSent = useRef(false);
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResendDisabled(false);
    }, COOLDOWN);

    if (initialEmailSent.current) {
      return;
    }

    sendEmailVerification(auth.user).catch(() => {
      notification.error("Error sending email. Please try again in a minute.");
    });

    initialEmailSent.current = true;

    return () => clearTimeout(timeoutId);
  }, [auth.user, notification]);

  function onResend() {
    setResendDisabled(true);
    sendEmailVerification(auth.user).catch(() => {
      notification.error("Error sending email. Please try again in a minute.");
    });
    setTimeout(() => {
      setResendDisabled(false);
    }, COOLDOWN);
  }

  return (
    <div className="auth-container">
      <AuthForm
        headerLeft="Verify your email"
        subheaderLeft="A link has been sent to your email. Click the link to verify your email."
        buttonLeftText="Log In"
        buttonLeftLink="/login"
        headerRight=""
        subheaderRight={
          <div>
            <p className="text-gray text-center VerifyEmail__mobile-text">
              A link has been sent to your email. Click the link to verify your
              email. Once you've verified your email,{" "}
              <Link to="/login">click here</Link> to log in and finish setting
              up your account.
            </p>
            <p className="text-gray text-center">
              Didn't recieve a link? Click the button below to resend. You can
              only resend once per minute.{" "}
            </p>
          </div>
        }
        submitText="Resend"
        onSubmit={onResend}
        submitBtnDisabled={resendDisabled}
      />
    </div>
  );
}
