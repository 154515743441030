import React, { useCallback, useEffect, useRef, useState } from "react";
import "./BarChart.css";

const BarChart = ({
  data,
  labels = [],
  title,
  config = { aspectRatio: 3 / 1 },
  chartWidth,
  chartHeight,
  titleFontSize
}) => {
  const containerRef = useRef(null);
  const dataRef = useRef(null);

  const [height, setHeight] = useState(0);
  const [dataHeight, setDataHeight] = useState(0);
  const [yAxisLabels, setYAxisLabels] = useState([]);
  const [maxYValue, setMaxYValue] = useState(1);

  const getRemPixels = useCallback((remCount) => {
    return (
      remCount * parseFloat(getComputedStyle(document.documentElement).fontSize)
    );
  }, []);

  const ceilToNearestN = useCallback((value, n) => {
    return Math.ceil(value / n) * n;
  }, []);

  const roundToNearestPowerOf10 = useCallback((value, roundFunction) => {
    if (value <= 0) {
      return 0;
    }

    return Math.pow(10, roundFunction(Math.log10(value)));
  }, []);

  const updateHeight = useCallback(() => {
    setHeight(
      containerRef?.current
        ? containerRef.current.clientWidth / config.aspectRatio
        : 0
    );

    setDataHeight(dataRef?.current ? dataRef.current.clientHeight : 0);
  }, [setHeight, setDataHeight, config?.aspectRatio]);

  /**
   * Window resize listener
   */
  useEffect(() => {
    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [updateHeight]);

  /**
   * Update the height when data updates
   */
  useEffect(() => {
    updateHeight();
  }, [
    containerRef?.current?.clientWidth,
    dataRef?.current?.clientHeight,
    config?.aspectRatio,
    dataHeight,
    updateHeight,
  ]);

  /**
   * Update the Y values
   */
  useEffect(() => {
    setYAxisLabels(() => {
      const dataSource = data ? data : [0];

      let maxYValue = Math.max(...dataSource);
      let maxLabelCount = Math.max(Math.floor(height / getRemPixels(2)) - 1, 2);
      let minStepSize = Math.max(Math.floor(maxYValue / maxLabelCount), 1);

      let ceilNearestPowerOf10 = roundToNearestPowerOf10(
        minStepSize,
        Math.ceil
      );
      let floorNearestPowerOf10 = roundToNearestPowerOf10(
        minStepSize,
        Math.floor
      );

      let sequence = [1, 2, 5];
      let step = sequence
        .flatMap((x) => [x * floorNearestPowerOf10, x * ceilNearestPowerOf10])
        .sort(
          (a, b) => Math.abs(a - minStepSize) - Math.abs(b - minStepSize)
        )[0];

      let labels = [];

      for (let i = 0; i < Math.ceil(maxYValue / step) + 1; i++) {
        labels.push(i * step);
      }

      if (labels.length === 1) {
        labels.push(1);
      }

      maxYValue = labels[labels.length - 1];
      setMaxYValue(maxYValue);

      return labels;
    });
  }, [data, height, getRemPixels, ceilToNearestN, roundToNearestPowerOf10]);

  const calculateBarHeight = (value) => {
    let barHeight = (value / maxYValue) * 100;

    return barHeight <= 100 ? barHeight : 0;
  };

  return (
    <div
      ref={containerRef}
      className="BarChart__container"
      style={{ width: chartWidth, height: chartHeight }}
    >
      <h2
        className="BarChart__title"
        style={{ fontSize : titleFontSize }}
      >
        {title}
      </h2>
      <div className="BarChart__top" style={{ height: `${height}px` }}>
        <div className="BarChart__y-axis">
          {yAxisLabels?.map((label, i) => (
            <p key={i}>{label || label === 0 ? label : ""}</p>
          ))}
        </div>
        <div ref={dataRef} className="BarChart__data">
          {data?.map((value, i) => (
            <div className="BarChart__bar-container" key={i}>
              <div
                key={i}
                className="BarChart__bar"
                style={{ height: `${calculateBarHeight(value)}%` }}
              />
              <div
                className="BarChart__hover"
                style={{ bottom: `${calculateBarHeight(value) / 2}%` }}
              >
                {value}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="BarChart__x-axis">
        {labels?.map((label, i) => (
          <p key={i}>{label}</p>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
