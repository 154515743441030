import React, { useEffect, useState } from "react";

import CalendarDate from "../CalendarDate/CalendarDate";
import "./CalendarDays.css";

const CalendarDays = ({
  month,
  year,
  firstDay,
  data,
  classesDetails,
  legend,
  filterfunc,
  handleDateClick,
  handleClassClick,
  handleResultsClick,
}) => {
  const [blanks, setBlanks] = useState(null); //state to control the number of blanks at beginning of calendar
  const [dateObjects, setDateObjects] = useState(null); //date objects for the selected month with proper data
  const [dateRows, setDateRows] = useState(null);

  useEffect(() => {
    /**
     * This useEffect creates Calendar date objects through the use of the function "filterfunc"
     * to filter and collect the scheduled topics for each class on a given date
     *
     */
    const firstDayVal = firstDay(year, month);

    const blanksArray = [];
    for (let i = 0; i < firstDayVal; i++) {
      blanksArray.push(<CalendarDate key={i} blank={true} />);
    }

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    const datesToBePushed = [];
    let dateIterator = firstDayOfMonth;

    let current = false;
    let past = false;

    // Iterate from the first day to the last day of the month
    while (dateIterator <= lastDayOfMonth) {
      if (
        formatDateToString(new Date(dateIterator)) ===
        formatDateToString(new Date())
      ) {
        current = true;
      }
      if (new Date(dateIterator) < new Date()) {
        past = true;
      }
      datesToBePushed.push(
        <CalendarDate
          key={dateIterator}
          blank={false}
          date={new Date(dateIterator)}
          classesDetails={classesDetails}
          dateData={filterfunc(
            data,
            formatDateToString(new Date(dateIterator))
          )}
          legend={legend}
          current={current}
          past={past}
          handleDateClick={handleDateClick}
          handleClassClick={handleClassClick}
          handleResultsClick={handleResultsClick}
        ></CalendarDate>
      );
      current = false;
      past = false;
      dateIterator.setDate(dateIterator.getDate() + 1); // Move to the next day
    }
    setBlanks(blanksArray);
    setDateObjects(datesToBePushed);
  }, [
    month,
    year,
    data,
    firstDay,
    classesDetails,
    handleDateClick,
    handleClassClick,
    handleResultsClick,
    legend,
    filterfunc,
  ]);

  //Use Effect to Construct the Calendar Object
  useEffect(() => {
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    if (blanks && dateObjects) {
      var totalSlots = [...blanks, ...dateObjects]; //array containing all created date objects (blanks and dateObjects)
      let rows = [];
      let cells = [];

      // Create the row for weekdays
      const weekdaysRow = weekdays.map((day, index) => (
        <td className="CalendarDays__header-box" key={index}>
          <h3 className="CalendarDays__header">{day}</h3>
        </td>
      ));
      rows.push(
        <tr className="CalendarDays__weekday-header" key="weekdays">
          {weekdaysRow}
        </tr>
      );

      totalSlots.forEach((row, i) => {
        //loop to go through all date objects
        if (i % 7 !== 0) {
          cells.push(
            <td className="CalendarDays__item" key={i}>
              {row}
            </td>
          ); // Wrap row in <td> for proper table structure
        } else {
          //if a week of cells has been created (true when i % 7 == 0)
          //create new row full of previous cell values
          rows.push(
            <tr className="CalendarDays__row" key={i}>
              {cells}
            </tr>
          ); // Wrap cells in <tr> for proper table structure
          cells = [];
          cells.push(
            <td className="CalendarDays__item" key={i}>
              {row}
            </td>
          ); // in current loop we still push current row to new container
        }
        if (i === totalSlots.length - 1) {
          // when end loop we add remaining cells
          rows.push(
            <tr className="CalendarDays__row" key={i + 1}>
              {cells}
            </tr>
          );
        }
      });

      setDateRows(rows);
    }
  }, [dateObjects, blanks]);

  function formatDateToString(date) {
    // Get the year, month, and day components from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with '0'
    const day = String(date.getDate()).padStart(2, "0");

    // Create the formatted string "MM-DD-YYYY"
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  }

  return (
    <div className="CalendarDays__container">
      {dateRows && (
        <table className="CalendarDays__calendar-table">
          <tbody>
            {}
            {dateRows.map((d, i) => {
              return d;
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CalendarDays;
