import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState,
  isValidElement,
} from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";
import "./AuthForm.css";

const AuthForm = ({
  headerLeft = "",
  subheaderLeft = "",
  buttonLeftText = "",
  buttonLeftLink = "",
  headerRight = "",
  subheaderRight = "",
  submitText = "Submit",
  lastPageSubmitText = submitText,
  navLink = "",
  footer = "",
  onSubmit = () => {},
  fieldsPerPage = 4,
  children = [],
  submitBtnDisabled = false,
}) => {
  const navLinkRef = useRef(null);

  const childrenArray = Children.toArray(children);

  const [page, setPage] = useState(0);
  const [navLinkVisible, setNavLinkVisible] = useState(false);

  const handleResize = useCallback(() => {
    setNavLinkVisible(
      navLinkRef?.current &&
        window.getComputedStyle(navLinkRef?.current).display === "block",
    );
  }, []);

  /**
   * Handle resize listener
   */
  useEffect(() => {
    setNavLinkVisible(
      navLinkRef?.current &&
        window.getComputedStyle(navLinkRef?.current).display === "block",
    );

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, setNavLinkVisible]);

  /**
   * Handles form submission
   *
   * @param {Event} event event triggered on form submission
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    if ((page + 1) * fieldsPerPage < childrenArray.length) {
      setPage((prevPage) => prevPage + 1);
    } else {
      onSubmit(event);
    }
  };

  return (
    <div className="AuthForm__container">
      <div className="AuthForm__left">
        {headerLeft && <h1>{headerLeft}</h1>}
        {subheaderLeft && <p className="text-large">{subheaderLeft}</p>}

        {buttonLeftText && buttonLeftLink && (
          <Link to={buttonLeftLink} className="cta-btn-white">
            {buttonLeftText}
          </Link>
        )}
      </div>
      <div className="AuthForm__right">
        <img className="AuthForm__logo" src={logo} alt="Brite" />
        <div className="AuthForm__main">
          <div className="AuthForm__header">
            {headerRight && <h1 className="text-center">{headerRight}</h1>}
            {subheaderRight &&
              (isValidElement(subheaderRight) ? (
                subheaderRight
              ) : (
                <p className="text-gray text-center">{subheaderRight}</p>
              ))}
          </div>

          <form className="AuthForm__form" onSubmit={handleSubmit}>
            {childrenArray.slice(
              page * fieldsPerPage,
              (page + 1) * fieldsPerPage,
            )}

            <div
              className="AuthForm__form-btns"
              style={{
                marginTop: childrenArray.length ? "1rem" : 0,
              }}
            >
              {childrenArray.length > fieldsPerPage && (
                <button
                  className="cta-btn"
                  disabled={page === 0}
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                >
                  Back
                </button>
              )}

              <button
                type="submit"
                className="cta-btn"
                disabled={submitBtnDisabled}
              >
                {(page + 1) * fieldsPerPage < childrenArray.length
                  ? submitText
                  : lastPageSubmitText}
              </button>
            </div>
          </form>
        </div>
        <div ref={navLinkRef} className="AuthForm__nav-link">
          {navLink}
        </div>

        {footer}

        {!navLinkVisible && !footer && <div />}
      </div>
    </div>
  );
};

export default AuthForm;
