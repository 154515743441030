import React, { useEffect, useState } from "react";
import { useFirestore } from "../../providers/FirestoreProvider";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../IconButton/IconButton";
import { red } from "../../colors";
import "./UserCard.css";

const UserCard = ({
  uid,
  user,
  removeUser = () => {},
  showDeleteButton = true,
}) => {
  const firestore = useFirestore();

  const [userData, setUserData] = useState(user);

  /**
   * Get User Info
   */
  useEffect(() => {
    if (user) {
      return;
    }

    let isMounted = true;

    firestore.getDocument(`users/${uid}`).then((doc) => {
      if (isMounted) {
        setUserData(firestore.docData(doc));
      }
    });

    return () => {
      isMounted = false;
    };
  }, [firestore, uid, user]);

  return (
    <div className="UserCard__container">
      <div>
        <h3>
          {userData?.firstName} {userData?.lastName}
        </h3>
        <p className="UserCard__role">{userData?.role}</p>
      </div>
      {showDeleteButton && (
        <IconButton
          icon={faXmark}
          backgroundColor={`rgb(${red})`}
          color="white"
          onClick={() => removeUser(userData)}
        />
      )}
    </div>
  );
};

export default UserCard;
