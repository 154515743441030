import React from "react";

import "./ToggleInput.css";

const ToggleInput = ({ ...props }) => {
  return (
    <div>
      <input type="checkbox" id="ToggleInput__checkbox" {...props} />
      <label
        className="ToggleInput__background"
        htmlFor="ToggleInput__checkbox"
      />
    </div>
  );
};

export default ToggleInput;
