import React from "react";

import "./TabSwitcher.css";

const TabSwitcher = ({
  options = [],
  selectedValue,
  setSelectedValue = () => {},
}) => {
  return (
    <div className="TabSwitcher__container">
      <div className="TabSwitcher__switcher">
        {options.map((option, i) => (
          <button
            onClick={() => setSelectedValue(option)}
            className={
              option === selectedValue
                ? "TabSwitcher__selected TabSwitcher__tab"
                : "TabSwitcher__tab"
            }
            key={i}
          >
            <p className="TabSwitcher__tab-label">{option}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabSwitcher;
