import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useFirestore } from "../../providers/FirestoreProvider";
// import DashboardStudent from "./DashboardStudent";
import DashboardTeacher from "./DashboardTeacher";
import { useNotification } from "../../providers/NotificationProvider";
import "./Dashboard.css";

const Dashboard = () => {
  const firestore = useFirestore();
  const notification = useNotification();

  const [selectedDate, setSelectedDate] = useState(
    new Date()
      .toLocaleString("en-us", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
      .replaceAll("/", "-")
  );

  const { universityId, classId } = useParams();

  const [classData, setClassData] = useState(null);

  /**
   * Get class data
   */
  useEffect(() => {
    let isMounted = true;

    firestore
      .getDocument(`universities/${universityId}/classes/${classId}`)
      .then((doc) => {
        if (isMounted) {
          setClassData(firestore.docData(doc));
        }
      })
      .catch(() => notification.error("Error fetching class data"));

    return () => {
      isMounted = false;
    };
  }, [firestore, notification, universityId, classId]);

  return (
    <DashboardTeacher
      universityId={universityId}
      classId={classId}
      date={selectedDate}
      setDate={setSelectedDate}
      classData={classData}
    />
  );
};

export default Dashboard;
