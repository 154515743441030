import React from "react";

const TimeInput = ({
  value = new Date().toLocaleTimeString([], {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  }),
  ...props
}) => {
  return (
    <input type="time" className="TimeInput__input" value={value} {...props} />
  );
};

export default TimeInput;
