import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import TopicDisplay from "../../components/TopicDisplay/TopicDisplay";
import Chat from "../../components/Chat/Chat";
import IconButton from "../../components/IconButton/IconButton";
import DateInput from "../../components/DateInput/DateInput";
import "./Dashboard.css";

const DashboardTeacher = ({
  universityId,
  classId,
  date,
  setDate = () => {},
  classData,
}) => {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const notification = useNotification();

  const [lectureData, setLectureData] = useState(null);
  const [questions, setQuestions] = useState({});

  /**
   * Get lecture data
   *
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        let isMounted = true;

        // Getting the question documents for the selected class/group
        const questionsQuery = firestore.query(
          `universities/${universityId}/classes/${classId}/questions`
        );
        const documents = await firestore.getDocuments(questionsQuery);

        const questionsDict = {};

        documents.forEach((doc) => {
          const data = doc.data();
          questionsDict[doc.id] = data;
        });

        setQuestions(questionsDict);

        // Fetching lecture data
        const lectureDoc = await firestore.getDocument(
          `universities/${universityId}/classes/${classId}/lectures/${date}`
        );

        if (isMounted) {
          setLectureData(firestore.docData(lectureDoc) || {});
        }
      } catch (error) {
        notification.error("Error fetching data: " + error.message);
      }
    };

    fetchData();

    return () => {
      // Cleanup function
    };
  }, [firestore, universityId, classId, date, notification]);

  /**
   * Convert date string from YYYY-MM-DD to MM-DD-YYYY
   *
   * @param {String} dateString date string formatted in YYYY-MM-DD to convert
   * @returns date string formatted to MM-DD-YYYY
   */
  const yearMonthDayToMonthDayYear = (dateString) => {
    const [year, month, day] = dateString.split("-");

    return `${month}-${day}-${year}`;
  };

  /**
   * Convert date string from MM-DD-YYYY to YYYY-MM-DD
   *
   * @param {String} dateString date string formatted in MM-DD-YYYY to convert
   * @returns date string formatted to YYYY-MM-DD
   */
  const monthDayYearToYearMonthDay = (dateString) => {
    const [month, day, year] = dateString.split("-");

    return `${year}-${month}-${day}`;
  };

  /**
   * Updates the client side list of topics
   *
   * @param {String[]} topics list of topics to set
   */
  const setTopics = (topics) => {
    setLectureData((prevLectureData) => ({
      ...prevLectureData,
      topics,
    }));
  };

  return (
    <div className="Dashboard__container">
      <div className="Dashboard__data-section">
        <div className="Dashboard__header">
          <div className="Dashboard__header-text">
            <h1>
              {classData?.name}: {classData?.title}
            </h1>
          </div>

          <div className="Dashboard__header-right-btns">
            <DateInput
              value={monthDayYearToYearMonthDay(date)}
              onChange={(event) => {
                setLectureData(null);
                setDate(yearMonthDayToMonthDayYear(event.target.value));
              }}
            />
            <IconButton icon={faGear} onClick={() => navigate("settings")} />
          </div>
        </div>

        <div className="Dashboard__topic-display">
          <TopicDisplay
            setTopics={setTopics}
            lecture={lectureData}
            universityId={universityId}
            classId={classId}
            day={date}
            questions={questions}
          />
        </div>
      </div>

      {classData != null && (
        <div className="Dashboard__chat">
          <Chat
            universityId={universityId}
            classId={classId}
            teacherUID={classData.teacherUID}
            disabled={classData?.chatDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardTeacher;
