import React, { useState } from "react";

import chatSVG from "../../assets/images/tutorialrenders/Chat.svg";
import addClassModalSVG from "../../assets/images/tutorialrenders/AddClassModal.svg";
import topicsModal from "../../assets/images/tutorialrenders/TopicsModal.svg";
import joinCode from "../../assets/images/tutorialrenders/JoinCode.svg";
import BarModal from "../../assets/images/tutorialrenders/BarTutorial.svg";
import ChatPhone from "../../assets/images/tutorialrenders/ChatPhone.svg";
import SurveyPhone from "../../assets/images/tutorialrenders/SurveyPhone.svg";
import TabSwitcher from "../TabSwitcher/TabSwitcher";

import "./InfoModal.css";
import PopupModal from "../PopupModal/PopupModal";

const modalTabs = ["For Educators", "For Students"];

const InfoModal = ({ isOpen = false, setIsOpen = () => {} }) => {
  const [selectedTab, setSelectedTab] = useState(modalTabs[0]);
  return (
    <div>
      <PopupModal isOpen={isOpen} setIsOpen={setIsOpen} height = {"40rem"} width = {"70rem"}>
        <div className="InfoModal__header">
          <h1>How it Works</h1>
          <TabSwitcher
            options={modalTabs}
            selectedValue={selectedTab}
            setSelectedValue={setSelectedTab}
          />
        </div>

        {selectedTab === modalTabs[0] && (
          <div className="InfoModal__container">
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>1. Create a Class</h1>
                <p>Click the + button and enter course details</p>
              </div>
              <img src={addClassModalSVG} alt={"add class"}></img>
            </div>
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>2. Share Join Code</h1>
                <p>
                  Share with your students through email or in person. Students
                  will then be able to join the class through the partner app.
                </p>
              </div>
              <img src={joinCode} alt={"join code"}></img>
            </div>
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>3. Add Topics</h1>
                <p>
                  Prior to the start of class, enter in the topics you will be
                  convering that day, or schedule them in advance using the
                  calendar.{" "}
                </p>
                <p> </p>
                <p>
                  These topics will be used to generate surveys sent out to your
                  students 10 minutes before the end of each class.
                </p>
              </div>
              <img src={topicsModal} alt={"topics"}></img>
            </div>
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>4. Interact in Chat</h1>
                <p>
                  Respond to student's questions or comments either through the
                  dashboard or on the app. Questions or comments posted by
                  students will be anonymous to other students but not to you as
                  the educator.{" "}
                </p>
              </div>
              <img src={chatSVG} alt={"chat"}></img>
            </div>
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>5. Get Feedback</h1>
                <p>
                  After each class, go to the class dashboard to view your
                  student's survey responses. Optionally, this data can be
                  included in a promotion and tenure teaching report.{" "}
                </p>
              </div>
              <img src={BarModal} alt={"bar graph"}></img>
            </div>
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>6. Questions?</h1>
                <p>Contact us at info@brite.company. </p>
              </div>
            </div>
          </div>
        )}
        {selectedTab === modalTabs[1] && (
          <div className="InfoModal__container">
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>1. Anonymous Chat</h1>
                <p>
                  On students’ mobile app, they can ask questions anonymously
                  during or after class without having the fear of being judged
                  by peers, encouraging participation in class discussion.
                  Innappropriate chats will be filtered.{" "}
                </p>
              </div>
              <img src={ChatPhone} alt={"chat"}></img>
            </div>
            <div className="InfoModal__section">
              <div className="InfoModal__section-text">
                <h1>2. Digital Exit Ticket</h1>
                <p>
                  We send students a quick and incentivized survey based on your
                  topic inputs 10 minutes before class ends. Remind your
                  students at the end of class to maximize participation.{" "}
                </p>
              </div>
              <img src={SurveyPhone} alt={"topics"}></img>
            </div>
          </div>
        )}
      </PopupModal>
    </div>
  );
};

export default InfoModal;
