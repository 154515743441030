import { useState, useEffect } from "react";
import { onSnapshot, doc } from "@firebase/firestore";

import { firestore } from "../services/firebase";

/**
 * Subscribe to a class document.
 *
 * @param {string} universityId the university id
 * @param {string} classId the class id
 * @returns {object} the class data
 */
export default function useClassDataSnapshot(universityId, classId) {
  const [classData, setClassData] = useState(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `universities/${universityId}/classes/${classId}`),
      (doc) => setClassData(doc.data())
    );

    return unsubscribe;
  }, [universityId, classId]);

  return classData;
}
