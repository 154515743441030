import React, { useEffect, useState, useCallback } from "react";

import { useFirestore } from "../../providers/FirestoreProvider";
import { useAuth } from "../../providers/AuthProvider";
import ChatMessage from "../ChatMessage/ChatMessage";
import Loader from "../Loader/Loader";
import IconButton from "../IconButton/IconButton";
import ToggleInput from "../../components/ToggleInput/ToggleInput";
import {
  faChevronLeft,
  faPaperPlane,
  faPencil,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./Chat.css";
import { useNotification } from "../../providers/NotificationProvider";
import { useNavigate } from "react-router-dom";
import { primary } from "../../colors";

const messagesPerLoad = 25;

const Chat = ({
  header = "Chat",
  universityId,
  classId,
  disabled,
  teacherUID,
  fullscreen = false,
}) => {
  const auth = useAuth();
  const firestore = useFirestore();
  const notification = useNotification();
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [messageSenders, setMessageSenders] = useState({
    [auth.user
      .uid]: `${firestore.userData.firstName} ${firestore.userData.lastName}`,
  });
  const [messageInputText, setMessageInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasLastMessageLoaded, setHasLastMessageLoaded] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [messageIdsToDelete, setMessageIdsToDelete] = useState([]);

  const [chatDisabled, setChatDisabled] = useState(disabled);

  /**
   * Send a message to the db
   *
   * @param {Event} event the event triggered on form submission
   */
  const sendMessage = useCallback(
    (event) => {
      event.preventDefault();

      if (!messageInputText) {
        return;
      }

      let message = {
        name: auth.user.email,
        text: messageInputText,
        date: firestore.serverTimestamp(),
        userUID: auth.user.uid,
      };

      firestore.createDoc(
        `universities/${universityId}/classes/${classId}/chats`,
        true,
        message
      );

      setMessageInputText((_) => "");
    },
    [
      auth.user.email,
      auth.user.uid,
      firestore,
      universityId,
      classId,
      messageInputText,
      setMessageInputText,
    ]
  );

  /**
   * Handles the keydown event to prevent the enter key from typing in the
   * textarea unless shift is pressed. Without shift pressed, a message
   * will be sent
   *
   * @param {Event} event event triggered on keydown
   */
  const handleKeyDown = useCallback(
    (event) => {
      const keyCode = event.which || event.keyCode;

      if (keyCode === 13 && !event.shiftKey) {
        // Do not allow new line if shift is not pressed
        event.preventDefault();
        sendMessage(event);
        handleResize(event);
      }
    },
    [sendMessage]
  );

  /**
   * Load messages from querySnapshot
   *
   * @param {QuerySnapshot<any>} querySnapshot snapshot containing messages
   */
  const loadMessages = useCallback(
    (querySnapshot) => {
      setIsLoading(true);

      setMessages((prevMessages) => {
        let updatedMessages = [...prevMessages];

        querySnapshot.docChanges().forEach((change) => {
          let message = firestore.docData(change.doc);

          if (
            (firestore.userData.role === "teacher" ||
              firestore.userData.role === "admin") &&
            !messageSenders[message.userUID]
          ) {
            messageSenders[message.userUID] = firestore.getDocument(
              `users/${message.userUID}`
            );
          }

          message.date = message.date ? message.date.toDate() : new Date();

          if (change.type === "removed") {
            updatedMessages = updatedMessages.filter(
              (m) => m.id !== message.id
            );
          } else {
            // added or modified
            let index = updatedMessages.findIndex((m) => m.id === message.id);

            if (index === -1) {
              // add
              if (
                updatedMessages.length &&
                message.date >= updatedMessages[updatedMessages.length - 1].date
              ) {
                // message is new
                updatedMessages.unshift(message);
              } else {
                // message is old
                updatedMessages.push(message);
              }
            } else {
              // modify
              updatedMessages[index] = message;
            }
          }
        });

        if (!hasLastMessageLoaded) {
          setHasLastMessageLoaded(querySnapshot.docs.length < messagesPerLoad);
        }

        return updatedMessages;
      });

      setIsLoading(false);
    },
    [firestore, hasLastMessageLoaded, messageSenders]
  );

  /**
   * Subscription to load incoming messages
   */
  useEffect(() => {
    const q = firestore.query(
      `universities/${universityId}/classes/${classId}/chats`,
      firestore.orderBy("date", "desc"),
      firestore.limit(messagesPerLoad)
    );

    const unsubscribe = firestore.subscribeToQuery(q, (querySnapshot) => {
      loadMessages(querySnapshot);
    });

    return unsubscribe;
  }, [firestore, universityId, classId, sendMessage, loadMessages]);

  /**
   * Key down listener
   */
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  /**
   * When the top of the chat is reached, load more messages
   *
   * @param {Event} event event triggered on chat scroll
   */
  const handleScroll = async (event) => {
    // If user reaches the top load more
    const scrollPadding = 4;

    if (
      event.target.clientHeight - event.target.scrollTop >=
        event.target.scrollHeight - scrollPadding &&
      !hasLastMessageLoaded &&
      !isLoading
    ) {
      const lastMessageDate = messages[messages.length - 1].date;
      const q = firestore.query(
        `universities/${universityId}/classes/${classId}/chats`,
        firestore.orderBy("date", "desc"),
        firestore.startAfter(lastMessageDate),
        firestore.limit(messagesPerLoad)
      );

      loadMessages(await firestore.getDocuments(q));
    }
  };

  /**
   * Resize the textarea up to 4 lines in height
   *
   * @param {Event} event event triggered on textarea change
   */
  const handleResize = (event) => {
    event.preventDefault();

    const maxRows = 4;

    // Make it the smallest it can be based on the text
    while (
      event.target.clientHeight <= event.target.scrollHeight &&
      event.target.rows > 1
    ) {
      event.target.rows--;
    }

    // Increase the size up to 4 rows
    while (
      event.target.clientHeight < event.target.scrollHeight &&
      event.target.rows < maxRows
    ) {
      event.target.rows++;
    }
  };

  /**
   * Sets the sender of a uid
   *
   * @param {String} uid UID of the sender to set
   * @param {String} sender name of the sender
   */
  const setSender = useCallback((uid, sender) => {
    setMessageSenders((prevSenders) => ({
      ...prevSenders,
      [uid]: sender,
    }));
  }, []);

  /**
   * Returns true if a message should show its info. Info will show if
   * the message has a different sender than the previous message or if the
   * send time between the messages is greater than 1 minute
   *
   * @param {Object} message current message
   * @param {Object} prevMessage previous message
   * @returns true if the messages info should be shown
   */
  const getShowInfo = (message, prevMessage) => {
    return (
      message.userUID !== prevMessage.userUID ||
      message.date - prevMessage.date < 60 * 1000
    );
  };

  /**
   * Sets if a message should be deleted or not
   *
   * @param {String} id id of the message to set
   * @param {Boolean} shouldDelete whether the message should be deleted
   */
  const setDeleteMessage = (id, shouldDelete) => {
    setMessageIdsToDelete((prevMessageIdsToDelete) => {
      let updatedMessageIdsToDelete = [...prevMessageIdsToDelete];

      if (!shouldDelete) {
        updatedMessageIdsToDelete = updatedMessageIdsToDelete.filter(
          (messageId) => messageId !== id
        );
      } else {
        let index = updatedMessageIdsToDelete.indexOf(id);

        if (index === -1) {
          updatedMessageIdsToDelete.push(id);
        }
      }

      return updatedMessageIdsToDelete;
    });
  };

  const deleteMessages = () => {
    messageIdsToDelete.forEach((id) => {
      firestore.removeDocument(
        `universities/${universityId}/classes/${classId}/chats/${id}`
      );
    });

    setMessageIdsToDelete([]);
    setInEditMode(false);

    notification.success("Messages deleted");
  };

  const toggleChat = async (checked) => {
    setChatDisabled(checked);

    await firestore.updateDoc(
      `universities/${universityId}/classes/${classId}`,
      {
        chatDisabled: !chatDisabled,
      },
      { merge: true }
    );
    if (checked === false) {
      notification.success("Chat Has Been Enabled!");
    }
    if (checked === true) {
      notification.warn(
        "Chat Has Been Disabled! Your students are no longer able to send messages."
      );
    }
  };

  return (
    <div className="Chat__chat-container">
      <div
        className={
          fullscreen ? "Chat__chat-header-fullscreen" : "Chat__chat-header"
        }
      >
        {fullscreen && (
          <IconButton
            icon={faChevronLeft}
            onClick={() => navigate(-1)}
            backgroundColor={`rgb(${primary})`}
            color="white"
          />
        )}

        {firestore.userData?.role === "teacher" ||
          (firestore.userData?.role === "admin" && (
            <div className="Chat__chat-toggle">
              <p className="little-text">Enabled</p>
              <ToggleInput
                checked={!chatDisabled}
                onChange={(event) => toggleChat(!event.target.checked)}
              />
            </div>
          ))}

        <h3
          className={
            fullscreen ? "Chat__chat-title header-2" : "Chat__chat-title-center"
          }
        >
          {header}
        </h3>
        <div>
          {(firestore.userData?.role === "teacher" ||
            firestore.userData?.role === "admin") && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <>
                {inEditMode && (
                  <button
                    style={{ maxWidth: "50%" }}
                    className="cta-btn-red"
                    onClick={deleteMessages}
                  >
                    Delete
                  </button>
                )}
                <IconButton
                  icon={inEditMode ? faXmark : faPencil}
                  onClick={() =>
                    setInEditMode((prevInEditMode) => !prevInEditMode)
                  }
                />
              </>
            </div>
          )}
        </div>
      </div>

      <div className="Chat__chat-list" onScroll={handleScroll}>
        {messages.length ? (
          messages.map((message, i) => (
            <React.Fragment key={message.id}>
              <ChatMessage
                message={message}
                sender={messageSenders[message.userUID] || ""}
                setSender={(sender) => setSender(message.userUID, sender)}
                teacherUID={teacherUID}
                showInfo={
                  i === messages.length - 1 ||
                  getShowInfo(message, messages[i + 1])
                }
                showCheckbox={inEditMode}
                setDeleteMessage={setDeleteMessage}
              />
              {(i === messages.length - 1 ||
                message?.date?.toLocaleDateString() !==
                  messages[i + 1]?.date?.toLocaleDateString()) && (
                <div className="Chat__date-line">
                  <div />
                  <p className="text-small">
                    {message?.date?.toLocaleDateString()}
                  </p>
                  <div />
                </div>
              )}
            </React.Fragment>
          ))
        ) : hasLastMessageLoaded ? (
          <p className="Chat__no-messages-msg">Send a message to get started</p>
        ) : (
          ""
        )}
        {!hasLastMessageLoaded && (
          <div className="Chat__loader">
            <Loader />
          </div>
        )}
      </div>
      {!chatDisabled && (
        <form className="Chat__sendMessageForm" onSubmit={sendMessage}>
          <textarea
            name="message"
            placeholder="Send a message"
            rows="1"
            value={messageInputText}
            onChange={(event) => {
              setMessageInputText(event.target.value);
              handleResize(event);
            }}
            required
          />
          <IconButton
            className="Chat__send-btn"
            icon={faPaperPlane}
            type="submit"
          />
        </form>
      )}
    </div>
  );
};

export default Chat;
