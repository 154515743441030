import React, { useEffect, useState } from "react";
import { useAuth } from "../../providers/AuthProvider";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import ClassCard from "../../components/ClassCard/ClassCard";
import "./AccountSettings.css";

const AccountSettings = () => {
  const auth = useAuth();
  const firestore = useFirestore();
  const notification = useNotification();

  const [university, setUniversity] = useState(null);
  const [classes, setClasses] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  /**
   * Get user data
   */
  useEffect(() => {
    setFirstName(firestore.userData?.firstName);
    setLastName(firestore.userData?.lastName);
    // setEmail(firestore.userData?.email);
  }, [
    firestore.userData?.firstName,
    firestore.userData?.lastName,
    // firestore.userData?.email,
  ]);

  /**
   * Get university data
   */
  useEffect(() => {
    if (!firestore.userData?.universityId) {
      return;
    }

    let isMounted = true;

    firestore
      .getDocument(`universities/${firestore.userData?.universityId}`)
      .then((doc) => {
        if (isMounted) {
          setUniversity(firestore.docData(doc));
        }
      })
      .catch(() => {
        notification.error("Error retrieving data");
      });

    return () => {
      isMounted = false;
    };
  }, [firestore, firestore.userData?.universityId, notification]);

  /**
   * Get class data
   */
  useEffect(() => {
    let isMounted = true;

    const classList = [];

    firestore.userData?.classList?.forEach((classId) => {
      classList.push(
        firestore.getDocument(
          `universities/${firestore.userData.universityId}/classes/${classId}`
        )
      );
    });

    Promise.all(classList)
      .then((values) => {
        if (isMounted) {
          setClasses(
            values
              .map((doc) => firestore.docData(doc))
              .sort((a, b) => a.name.localeCompare(b.name))
          );
        }
      })
      .catch(() => {
        notification.error("Error retrieving data");
      });

    return () => {
      isMounted = false;
    };
  }, [firestore, firestore.userData?.classList, notification]);

  /**
   * Updates the users info in the database
   *
   * @param {Event} event event triggered on form submission
   */
  const updateUser = (event) => {
    event.preventDefault();

    firestore.updateUser(auth.user.uid, {
      firstName,
      lastName,
      // email,
    });

    firestore.setUserData({
      ...firestore.userData,
      firstName,
      lastName,
      // email,
    });

    notification.success("Changes saved!");
  };

  /**
   * Checks password validity, ensuring the password contains
   * 8 characters minumum
   * uppercase letters
   * lowercase letters
   * numbers
   * password and confirm password are the same
   *
   * @returns true if valid, otherwise false
   */
  const validatePassword = () => {
    // ensure password is at least 8 characters
    if (newPassword?.length < 8) {
      notification.error("Password must be at least 8 characters long");
      return false;
    }

    // contain uppercase letters
    if (!/[A-Z]/.test(newPassword)) {
      notification.error("Password must contain at least one uppercase letter");
      return false;
    }

    // contain lowercase letters
    if (!/[a-z]/.test(newPassword)) {
      notification.error("Password must contain at least one lowercase letter");
      return false;
    }

    // contain numbers
    if (!/\d+/.test(newPassword)) {
      notification.error("Password must contain at least one number");
      return false;
    }

    if (newPassword !== confirmPassword) {
      notification.error("Passwords must match");
      return false;
    }

    return true;
  };

  /**
   * Resets the current user's password after validating the user
   *
   * @param {Event} event event triggered on form submission
   */
  const resetPassword = async (event) => {
    event.preventDefault();

    if (!validatePassword()) {
      return;
    }

    if (await auth.reauthenticate(currentPassword)) {
      auth.updatePassword(auth.user, newPassword);

      notification.success("Password successfully changed!");
    }
  };

  /**
   * Submit feedback to the database
   *
   * @param {Event} event event triggered on form submission
   */
  const submitFeedback = (event) => {
    event.preventDefault();

    firestore.createDoc(`users/${auth.user.uid}/feedback`, true, {
      feedback,
    });

    setFeedback("");
    notification.success("Feedback Submitted");
  };

  /**
   * Resize the textarea to the required height
   *
   * @param {Event} event event triggered on textarea change
   */
  const handleResize = (event) => {
    event.preventDefault();

    const minRows = 4;

    // Make it the smallest it can be based on the text
    while (
      event.target.clientHeight <= event.target.scrollHeight &&
      event.target.rows > minRows
    ) {
      event.target.rows--;
    }

    // Increase the size
    while (event.target.clientHeight < event.target.scrollHeight) {
      event.target.rows++;
    }
  };

  return (
    <div className="AccountSettings__container">
      <div className="AccountSettings__center">
        <div className="AccountSettings__header">
          <h1>Account</h1>
        </div>

        <form className="AccountSettings__settings-form" onSubmit={updateUser}>
          <label htmlFor="AccountSettings__firstName">
            <h3>First Name:</h3>
          </label>
          <input
            type="text"
            id="AccountSettings__firstName"
            placeholder="First Name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            required
          />

          <label htmlFor="AccountSettings__lastName">
            <h3>Last Name:</h3>
          </label>
          <input
            type="text"
            id="AccountSettings__lastName"
            placeholder="Last Name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            required
          />

          {/* <label htmlFor="AccountSettings__email">
            <h3>Email:</h3>
          </label>
          <input
            type="text"
            id="AccountSettings__email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          /> */}

          <div />
          <button type="submit" className="AccountSettings__save-btn cta-btn">
            Save
          </button>
        </form>

        <div className="AccountSettings__header">
          <h1>Change Password</h1>
        </div>

        <form
          className="AccountSettings__settings-form"
          onSubmit={resetPassword}
        >
          <label htmlFor="AccountSettings__currentPassword">
            <h3>Current Password:</h3>
          </label>
          <input
            type="password"
            id="AccountSettings__currentPassword"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
            required
          />

          <label htmlFor="AccountSettings__newPassword">
            <h3>New Password:</h3>
          </label>
          <input
            type="password"
            id="AccountSettings__newPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            required
          />

          <label htmlFor="AccountSettings__confirmPassword">
            <h3>Confirm Password:</h3>
          </label>
          <input
            type="password"
            id="AccountSettings__confirmPassword"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            required
          />

          <div />
          <button type="submit" className="AccountSettings__save-btn cta-btn">
            Save
          </button>
        </form>

        <div className="AccountSettings__header">
          <h1>University</h1>
        </div>

        <h3 className="AccountSettings__university">{university?.name}</h3>

        <div className="AccountSettings__header">
          <h1>Feedback</h1>
        </div>

        <form
          className="AccountSettings__feedback-form"
          onSubmit={submitFeedback}
        >
          <textarea
            className="AccountSettings__feedback-input"
            placeholder="Send feedback"
            rows="4"
            value={feedback}
            onChange={(event) => {
              setFeedback(event.target.value);
              handleResize(event);
            }}
            required
          />

          <button type="submit" className="cta-btn">
            Submit Feedback
          </button>
        </form>
      </div>

      <div className="AccountSettings__registered">
        <div className="AccountSettings__header">
          <h1>Registered Classes</h1>
        </div>

        <div className="AccountSettings__class-list">
          {classes.map((classData, i) => (
            <ClassCard key={i} classData={classData} minimal={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
