import useClassList from "../../hooks/useClassList";
import ClassCard from "../../components/ClassCard/ClassCard";

import { routeOptions } from "../../components/ClassCard/ClassCard";
import "./AnalyticsClassList.css";

const AnalyticsClassList = () => {
  const classes = useClassList();

  return (
    <main className="AnalyticsClassList__container">
      <h1>Class Analytics</h1>
      <hr className="AnalyticsClassList__divider" />

      <div className="AnalyticsClassList__class-list">
        {classes.length ? (
          classes.map((classData) => {
            if (classData.type === "class") {
              return (
                <ClassCard
                  classData={classData}
                  route={routeOptions.ANALYTICS}
                  key={classData.id}
                />
              );
            } else if (classData.type === "group") {
            }
            return null; // Skip rendering if type is neither "class" nor "groupCard"
          })
        ) : (
          <p>You don't have any classes.</p>
        )}
      </div>
    </main>
  );
};

export default AnalyticsClassList;
