import React, { useState, useEffect } from "react";
import { faHand } from "@fortawesome/free-solid-svg-icons";

import { useFirestore } from "../../providers/FirestoreProvider";
// import TopicModal from "../../components/TopicModal/TopicModal";
import Topic from "../Topic/Topic";
// import IconButton from "../IconButton/IconButton";
import BarChart from "../BarChart/BarChart";
import PercentDisplay from "../PercentDisplay/PercentDisplay";
import "./TopicDisplay.css";
import Question from "../Question/Question";

export const responseConfig = {
  understanding: {
    prefix: "understanding",
    options: {
      Lost: "Lost",
      Confused: "Confused",
      Almost: "Almost",
      "Got It": "Got It",
    },
  },
};

const TopicDisplay = ({
  setTopics = () => {},
  lecture,
  universityId,
  classId,
  day,
  questions,
}) => {
  const firestore = useFirestore();

  // const [modalIsOpen, setModalIsOpen] = useState(false);

  const [responseCounts, setResponseCounts] = useState(null);
  const [enrollmentCount, setEnrollmentCount] = useState(0);

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const [participation, setParticipation] = useState(null);

  /**
   * Default to showing the first topic
   */

  useEffect(() => {
    // setModalIsOpen(lecture && !lecture.topics?.length);

    if (lecture?.topics?.length) {
      setSelectedTopic(lecture.topics[0]);
      if (lecture.questionIds != null) {
        setSelectedQuestion(lecture.questionIds[0]);
      }
    } else {
      setSelectedTopic(null);
      setSelectedQuestion(null);
    }
  }, [lecture, lecture?.topics, day]);

  /**
   * Get response counts for each topic
   */
  useEffect(() => {
    if (!lecture?.topics) {
      return;
    }

    /**
     *
     * @param {array} options
     * @param {*} whereField
     * @returns
     */
    const getCounts = async (options, whereField) => {
      const promises = [];

      for (let i = 0; i < options.length; i++) {
        const query = firestore.query(
          `universities/${universityId}/classes/${classId}/lectures/${day}/responses`,
          firestore.where(whereField, "==", options[i])
        );

        promises.push(firestore.count(query));
      }

      return (await Promise.all(promises)).map(
        (response) => response.data().count
      );
    };

    const index = lecture.topics.indexOf(selectedTopic);

    /**
     * calls getCounts for each key in response config, passing the options of the key in the responseConfig,
    and the index of the response object corresponding to the prefix of the response 

    **/

    for (const key in responseConfig) {
      getCounts(
        Object.keys(responseConfig[key].options),
        `${responseConfig[key].prefix}_${index}`
      ).then((counts) => {
        setResponseCounts((prevResponseCounts) => {
          return {
            ...prevResponseCounts,
            [selectedTopic]: {
              ...prevResponseCounts?.[selectedTopic],
              [responseConfig[key].prefix]: counts,
            },
          };
        });
      });
    }
  }, [firestore, universityId, classId, day, lecture?.topics, selectedTopic]);

  /**
   * Get the response counts for each custom question
   */
  useEffect(() => {
    const updateResponseCounts = async (questionId, counts) => {
      setResponseCounts((prevResponseCounts) => {
        const updatedCounts = { ...prevResponseCounts };
        updatedCounts[questionId] = counts;
        return updatedCounts;
      });
    };

    if (lecture?.questionIds) {
      // creates the structure for responseCounts by iterating through each question and getting the response data for each
      const fetchCountsForQuestions = async () => {
        for (let i = 0; i < lecture.questionIds.length; i++) {
          //for each question for the lecture
          const questionId = lecture.questionIds[i];

          const q = firestore.query(
            `universities/${universityId}/classes/${classId}/questions`
          );
          const documents = await firestore.getDocuments(q); //get the data for that question

          documents.forEach((doc) => {
            const data = doc.data();
            if (questionId === doc.id) {
              const responsesDay = data?.responseData?.[day];

              updateResponseCounts(questionId, responsesDay); //append it to responseCounts
            }
          });
        }
      };

      fetchCountsForQuestions();
    }
  }, [
    firestore,
    universityId,
    classId,
    day,
    lecture?.questionIds,
    questions,
    selectedQuestion,
    setResponseCounts,
  ]);

  /**
   * Get enrollment count and the participation percentage for the given lecture
   * The participation percentage is calculated by taking the number of documents
   * in the lectures "responses" collection, and dividing that by the number of enrolled
   * users in the class/group
   */
  useEffect(() => {
    let isMounted = true;

    const fetchEnrollmentCount = async () => {
      const query = firestore.query(
        `universities/${universityId}/classes/${classId}/enrolled`
      );
      const enrollmentDocs = await firestore.getDocuments(query);
      if (isMounted) {
        setEnrollmentCount(enrollmentDocs?.docs.length);
      }
    };

    const fetchParticipation = async () => {
      const queryResponses = firestore.query(
        `universities/${universityId}/classes/${classId}/lectures/${day}/responses`
      );
      const responseDocs = await firestore.getDocuments(queryResponses);
      if (isMounted) {
        const count = responseDocs?.docs.length;
        console.log(count);
        setParticipation((count / enrollmentCount) * 100);
      }
    };

    fetchEnrollmentCount();
    fetchParticipation();

    return () => {
      isMounted = false;
    };
  }, [firestore, universityId, classId, day, enrollmentCount]);

  return (
    <div className="TopicDisplay__container">
      {/* <TopicModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        topics={lecture?.topics}
        setTopics={setTopics}
        classId={classId}
        date={day}
      /> */}

      <div className="TopicDisplay__header">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 style={{ marginBottom: "10px" }}>Topic Results</h1>

          <div className="TopicDisplay__topic-list">
            {lecture?.topics?.map((topic, i) => (
              <Topic
                key={i}
                topic={topic}
                isSelected={selectedTopic === topic}
                setSelected={() => setSelectedTopic(topic)}
              />
            ))}
            {!lecture?.topics?.length && (
              <p>You haven't added any topics. Do so on the calendar.</p>
            )}
          </div>
        </div>

        {/* <IconButton icon={faPencil} onClick={() => setModalIsOpen(true)} /> */}
      </div>

      <PercentDisplay
        icon={faHand}
        title="Participation"
        percent={participation}
      />

      <BarChart
        labels={Object.values(responseConfig.understanding.options)}
        data={
          responseCounts?.[selectedTopic]?.[responseConfig.understanding.prefix]
        }
        title="Understanding"
      />
      <div className="TopicDisplay__header">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1 style={{ marginBottom: "10px" }}>Custom Question Results</h1>
          <div className="TopicDisplay__question-list">
            {lecture?.questionIds?.map((questionId, i) => (
              <Question
                key={i}
                question={questionId}
                questionsStruct={questions}
                isSelected={selectedQuestion === questionId}
                setSelected={() => setSelectedQuestion(questionId)}
              />
            ))}
            {!lecture?.questionIds?.length && (
              <p>You haven't added any questions. Do so on the calendar.</p>
            )}
          </div>
        </div>

        {/* <IconButton icon={faPencil} onClick={() => setModalIsOpen(true)} /> */}
      </div>
      <div>
        {questions && selectedQuestion && (
          <div>
            <BarChart
              labels={questions[selectedQuestion].options}
              data={responseCounts?.[selectedQuestion]?.responseTallies}
              title={questions[selectedQuestion].question}
            />
          </div>
        )}
      </div>
      <div>
        <div>
          <h2 className="TopicDisplay__additional-feedback-header">
            Additional Feedback
          </h2>
          {lecture?.optionalFeedbackResponses?.length ? (
            <ul className="TopicDisplay__additional-feedback-list">
              {lecture.optionalFeedbackResponses.map((response, i) => (
                <li key={i} className="TopicDisplay__additional-response-card">
                  {response}
                </li>
              ))}
            </ul>
          ) : (
            <p>No additional feedback</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicDisplay;
