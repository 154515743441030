import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthProvider } from "./providers/AuthProvider";
import { NotificationProvider } from "./providers/NotificationProvider";
import { FirestoreProvider } from "./providers/FirestoreProvider";

import RequireAuth from "./components/RequireAuth/RequireAuth";
import SignUp from "./pages/SignUp/SignUp";
import Login from "./pages/Login/Login";
import Classes from "./pages/Classes/Classes";
import Loading from "./pages/Loading/Loading";
import Dashboard from "./pages/Dashboard/Dashboard";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import AppStoreRedirect from "./pages/AppStoreRedirect/AppStoreRedirect";
import AccountSettings from "./pages/AccountSettings/AccountSettings";
import ClassSettings from "./pages/ClassSettings/ClassSettings";
import GroupSettings from "./pages/GroupSettings/GroupSettings";
import Calendar from "./pages/Calendar/Calendar";
import RequireRole from "./components/RequireRole/RequireRole";
import Setup from "./pages/Setup/Setup";
import Analytics from "./pages/Analytics/Analytics";
import AnalyticsClassList from "./pages/AnalyticsClassList/AnalyticsClassList";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import DashboardGroup from "./pages/Dashboard/DashboardGroup";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Loading />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/verify-email",
    element: (
      <RequireAuth hasNav={false}>
        <VerifyEmail />,
      </RequireAuth>
    ),
  },
  {
    path: "/reset-password",
    element: <PasswordReset />,
  },
  {
    path: "/redirect",
    element: <AppStoreRedirect />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/setup",
    element: (
      <RequireAuth hasNav={false}>
        <Setup />
      </RequireAuth>
    ),
  },
  {
    path: "/classes",
    element: (
      <RequireAuth smallerNav={false}>
        <Classes />
      </RequireAuth>
    ),
  },
  {
    path: "/dashboard/:universityId/:classId",
    element: (
      <RequireAuth smallerNav={false}>
        <Dashboard />
      </RequireAuth>
    ),
  },
  {
    path: "/calendar",
    element: (
      <RequireRole roles={["teacher", "admin"]}>
        <RequireAuth smallerNav={true}>
          <Calendar />
        </RequireAuth>
      </RequireRole>
    ),
  },
  {
    path: "/analytics",
    element: (
      <RequireRole roles={["teacher", "admin"]}>
        <RequireAuth smallerNav={false}>
          <AnalyticsClassList />
        </RequireAuth>
      </RequireRole>
    ),
  },
  {
    path: "/analytics/:universityId/:classId",
    element: (
      <RequireRole roles={["teacher", "admin"]}>
        <RequireAuth smallerNav={false}>
          <Analytics />
        </RequireAuth>
      </RequireRole>
    ),
  },
  {
    path: "/dashboardgroup/:universityId/:classId",
    element: (
      <RequireRole roles={["teacher", "admin"]}>
        <RequireAuth smallerNav={true}>
          <DashboardGroup />
        </RequireAuth>
      </RequireRole>
    ),
  },
  {
    path: "/dashboard/:universityId/:classId/settings",
    element: (
      <RequireRole roles={["teacher", "admin"]}>
        <RequireAuth smallerNav={false}>
          <ClassSettings />
        </RequireAuth>
      </RequireRole>
    ),
  },
  {
    path: "/dashboardgroup/:universityId/:classId/groupsettings",
    element: (
      <RequireRole roles={["admin"]}>
        <RequireAuth smallerNav={false}>
          <GroupSettings />
        </RequireAuth>
      </RequireRole>
    ),
  },
  {
    path: "/account",
    element: (
      <RequireAuth smallerNav={false}>
        <AccountSettings />
      </RequireAuth>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NotificationProvider>
      <AuthProvider>
        <FirestoreProvider>
          <RouterProvider router={router} />
        </FirestoreProvider>
      </AuthProvider>
    </NotificationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
