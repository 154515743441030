import React, { useEffect, useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import AdminQuestion from "../../components/AdminQuestion/AdminQuestion";
import AdminDisplay from "../../components/AdminDisplay/AdminDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../components/IconButton/IconButton";
import { useNavigate } from "react-router-dom";
import { faGear } from "@fortawesome/free-solid-svg-icons";

import "./DashboardGroup.css";

//initializing the details of the lines that will make up the line chart
const lineProperties = [
  {
    backgroundColor: "rgba(0, 93, 172, 0.5)",
    borderColor: "rgba(0, 93, 172, 1)",
    pointRadius: 5,
    cubicInterpolationMode: "monotone",
    tension: 0.4,
  },
  {
    backgroundColor: "rgba(0, 255, 0, 0.5)", // Green color
    borderColor: "rgba(0, 255, 0, 1)", // Green color
    pointRadius: 5,
    cubicInterpolationMode: "monotone",
    tension: 0.4,
  },
  {
    backgroundColor: "rgba(255, 0, 0, 0.5)", // Red color
    borderColor: "rgba(255, 0, 0, 1)", // Red color
    pointRadius: 5,
    cubicInterpolationMode: "monotone",
    tension: 0.4,
  },
  {
    backgroundColor: "rgba(255, 165, 0, 0.5)", // Orange color
    borderColor: "rgba(255, 165, 0, 1)", // Orange color
    pointRadius: 5,
    cubicInterpolationMode: "monotone",
    tension: 0.4,
  },
  {
    backgroundColor: "rgba(128, 0, 128, 0.5)", // Purple color
    borderColor: "rgba(128, 0, 128, 1)", // Purple color
    pointRadius: 5,
    cubicInterpolationMode: "monotone",
    tension: 0.4,
  },
  {
    backgroundColor: "rgba(255, 255, 0, 0.5)", // Yellow color
    borderColor: "rgba(255, 255, 0, 1)", // Yellow color
    pointRadius: 5,
    cubicInterpolationMode: "monotone",
    tension: 0.4,
  },
  // Add more colors as needed
];

const DashboardGroup = () => {
  const firestore = useFirestore();
  const notification = useNotification();
  const [groupData, setGroupData] = useState(null); // data corresponding to the group such as the name, and admin
  // const [lectureData, setLectureData] = useState({});
  const [questionsData, setQuestionsData] = useState(null); //dictionary using questionIds as keys and question data as value pairs
  const [selectedQuestion, setSelectedQuestion] = useState(null); //stores the selected questionId
  const [selectedData, setSelectedData] = useState(null); //stores a dictionary of the selected questions data (the docData from the db for the selectedQuestion)
  const [selectedDate, setSelectedDate] = useState(null); //the selected date corresponding to the selected question
  const [enrolledData, setEnrolledData] = useState(null); //data pulled from the enrolled collection underneath the group

  const [lineStruct, setLineStruct] = useState({}); //structure to create the line plot for the selected question
  const [partLineStruct, setPartLineStruct] = useState({}); //structure to create the line plot of participation over time
  const [chartOptions, setChartOptions] = useState({}); //chart options passed to the line plot for the selected question (dynamically changes based on question selected)

  const [groupInitialized, setGroupInitialized] = useState(false); //true if there exist both questions and users for a given group

  const [optionalFeedbacks, setOptionalFeedback] = useState(null);

  const [searchedQuestion, setSearchedQuestion] = useState(""); //stores the user input for the searchedQuestion
  const { universityId, classId } = useParams();

  const [sortCondition, setSortCondition] = useState("recent"); //a string that states which condition the current questions table is being sorted by

  const [tagsStruct, setTagsStruct] = useState({});
  const [selectedTag, setSelectedTag] = useState("none");

  const memoizedLineProperties = useMemo(() => lineProperties, []);

  const navigate = useNavigate();
  //setup for the participation line plot
  const partPlotOptions = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
  };

  useEffect(() => {
    if (questionsData && enrolledData) {
      if (
        Object.keys(questionsData).length !== 0 &&
        Object.keys(enrolledData).length !== 0
      ) {
        setGroupInitialized(true);
      } else {
        setGroupInitialized(false);
      }
    }
  }, [enrolledData, questionsData]);

  //dynamically changes the selected data based on the selected question
  useEffect(() => {
    if (selectedQuestion) {
      let newData = questionsData[selectedQuestion]; //variable to store the data for the newly selected date

      if (newData["responseData"] !== undefined) {
        // reorganize new data so that the dates are in order
        // Sort date keys
        let dateKeys = Object.keys(newData["responseData"]).sort(
          (a, b) =>
            new Date(a.replace(/-/g, "/")) - new Date(b.replace(/-/g, "/"))
        );

        // Update newData with sorted responseData
        newData["responseData"] = dateKeys.reduce((sortedData, date) => {
          sortedData[date] = newData["responseData"][date];
          return sortedData;
        }, {});

        setSelectedData(questionsData[selectedQuestion]);
        /**
         * initializes the selected Date to be the first available
         * date with data for the newly selected question
         */
        setSelectedDate(Object.keys(newData["responseData"])[0]);

        /**
         * Creation of the structure that contains the information needed to make the line plot
         * for the selected question
         */
        const lineData = [];
        for (let i = 0; i < newData.options.length; i++) {
          //for each option of the question
          // loop to initialize the data structure for the line plot
          const optionData = Object.keys(newData["responseData"]).map(
            (date) => {
              //gets the value for a specified option across all dates that a given question was scheduled
              return newData["responseData"][date]["responseTallies"][i];
            }
          );
          lineData.push(optionData);
        }

        //getting the labels needed to make the line plot
        const lineLabels = Object.keys(newData["responseData"]);

        //setting state variables
        setLineStruct({
          labels: lineLabels,
          datasets: lineData.map((data, index) => ({
            data: data,
            ...memoizedLineProperties[index],
            label: newData.options[index],
          })),
        });

        setChartOptions({
          responsive: true,
          scales: {
            y: {
              min: 0,
              max: Object.keys(enrolledData).length + 10, //the max number of responses should be the number enrolled give or take a few
            },
          },
        });
      }
    }
  }, [selectedQuestion, enrolledData, memoizedLineProperties, questionsData]);

  /**
   * Get group data
   */
  useEffect(() => {
    const fetchData = async () => {
      //Code to retrieve the group document from the db
      const groupDoc = await firestore.getDocument(
        `universities/${universityId}/classes/${classId}`
      );
      setGroupData(firestore.docData(groupDoc));

      let tagsStructIn = {};
      if (firestore.docData(groupDoc).tags !== undefined) {
        tagsStructIn = firestore.docData(groupDoc).tags.map((tag, i) => ({
          label: tag,
          value: tag,
        }));
      }

      setTagsStruct(tagsStructIn);

      /*creates a dictionary where every key is a lecture date, and the lecture data is stored
      as the key pair*/
      // const lecturesStruct = {};
      // const lecturesQuery = firestore.query(
      //   `universities/${universityId}/classes/${classId}/lectures`
      // );
      // const lectureDocuments = await firestore.getDocuments(lecturesQuery);
      // lectureDocuments.forEach((doc) => {
      //   const data = doc.data();
      //   lecturesStruct[doc.id] = data;
      // });
      // setLectureData(lecturesStruct);

      /*code to retreive all the questions asked and set up a structure to access their 
      results*/
      const questionsStruct = {};
      const newParticipationStruct = {};

      const questionsQuery = firestore.query(
        `universities/${universityId}/classes/${classId}/questions`
      );
      const questionDocuments = await firestore.getDocuments(questionsQuery);

      /**
       * gathers the data needed to setup questionStruct and participationStruct
      participationStruct is built by getting the number of responses and
      enrolled users for each question asked on a particular date
      and taking the sum of each*/
      /**
       * Participation Struct Schema
       * participationStruct[date] = [responses, enrolled, participationPct] for the given date
       */

      /**participationStruct is used to render the line graph that show's the group's
       * participation over time
       */
      questionDocuments.forEach((doc) => {
        const data = doc.data();

        if (data["responseData"] !== undefined) {
          //new key pairs in 'data' to keep track of the total number of responses and
          //enrolled users across all dates
          data["responsesCompiled"] = 0;
          data["enrolledCompiled"] = 0;
          Object.keys(data["responseData"]).map((date) => {
            data["responsesCompiled"] +=
              data["responseData"][date]["responses"];
            data["enrolledCompiled"] += data["responseData"][date]["enrolled"];

            if (newParticipationStruct[date] !== undefined) {
              newParticipationStruct[date][0] =
                newParticipationStruct[date][0] +
                data["responseData"][date]["responses"];
              newParticipationStruct[date][1] =
                newParticipationStruct[date][1] +
                data["responseData"][date]["enrolled"];
              newParticipationStruct[date][2] =
                (newParticipationStruct[date][0] /
                  newParticipationStruct[date][1]) *
                100;
            } else {
              newParticipationStruct[date] = [
                data["responseData"][date]["responses"],
                data["responseData"][date]["enrolled"],
                (data["responseData"][date]["responses"] /
                  data["responseData"][date]["enrolled"]) *
                  100,
              ];
            }

            return null;
          });

          data["participationCompiled"] =
            data["responsesCompiled"] / data["enrolledCompiled"];

          questionsStruct[doc.id] = data;
        }
      });

      //creates a structure of sorted dates for newParticpationStruct
      const sortedDates = Object.keys(newParticipationStruct).sort((a, b) => {
        const dateA = new Date(a.replace(/-/g, "/"));
        const dateB = new Date(b.replace(/-/g, "/"));
        return dateA - dateB;
      });

      // Create a new object with sorted dates
      const sortedParticipationStruct = {};
      sortedDates.forEach((date) => {
        sortedParticipationStruct[date] = newParticipationStruct[date];
      });

      setPartLineStruct({
        datasets: [
          {
            data: Object.keys(sortedParticipationStruct).map((date) => {
              return sortedParticipationStruct[date][2];
            }),
            label: "Participation",
            ...memoizedLineProperties[0],
            borderColor: "rgb(51, 51, 204)", // Change 'red' to the color you want
          },
        ],
        labels: Object.keys(sortedParticipationStruct).map((date) => {
          return date;
        }),
      });

      const enrolledStuct = {};
      //code to retreive the enrolled users in the group
      const enrolledQuery = firestore.query(
        `universities/${universityId}/classes/${classId}/enrolled`
      );
      const enrolledDocuments = await firestore.getDocuments(enrolledQuery);
      enrolledDocuments.forEach((doc) => {
        const data = doc.data();
        enrolledStuct[doc.id] = data;
      });

      /**code to retreive the lectures documents for the group
      used to acquire optional feedback**/

      let pulledOptionalFeedback = [];
      const lecturesQuery = firestore.query(
        `universities/${universityId}/classes/${classId}/lectures`
      );
      const lectureDocs = await firestore.getDocuments(lecturesQuery);

      lectureDocs.forEach((doc) => {
        const data = doc.data();
        //checking to ensure optional feedback exists
        if (data.optionalFeedbackResponses !== undefined) {
          pulledOptionalFeedback = [
            ...pulledOptionalFeedback,
            ...data.optionalFeedbackResponses,
          ];
        }
      });

      /**default sort questions so that more recent questions are shown first in the question
       * div.
       * **/
      const sortedQuestions = Object.keys(questionsStruct)
        .sort((a, b) => {
          const dateA = new Date(questionsStruct[a].earliestDate);
          const dateB = new Date(questionsStruct[b].earliestDate);
          // Reverse the comparison to sort in descending order (more recent dates first)
          return dateB - dateA;
        })
        .reduce((acc, key) => {
          acc[key] = questionsStruct[key];
          return acc;
        }, {});

      //setting relevant state data
      setEnrolledData(enrolledStuct);
      setQuestionsData(sortedQuestions);
      setOptionalFeedback(pulledOptionalFeedback);
      setSelectedQuestion(Object.keys(questionsStruct)[0]);
    };

    fetchData();
  }, [firestore, universityId, classId, notification, memoizedLineProperties]);

  const selectedQCheck = (questionId) => {
    if (questionsData[questionId]["responseData"] !== undefined) {
      setSelectedQuestion(questionId);
    } else {
      notification.info(
        "Responses do not exist for the requested survey. Check back later."
      );
    }
  };

  /**
   * The below use effect will trigger conditional reorganization of questionsData
   * For example, if the user were to click on the questions header of "earliest date scheduled"
   * questionsData will either change or its current organization to reflect the click, and sort
   * the questions by either the most recent or oldest scheduled questions.
   */
  useEffect(() => {
    if (questionsData) {
      let questionsStruct = questionsData; //making copy of questions data

      if (sortCondition === "recent") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const dateA = new Date(questionsStruct[a].earliestDate);
            const dateB = new Date(questionsStruct[b].earliestDate);
            // Reverse the comparison to sort in descending order (more recent dates first)
            return dateB - dateA;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "oldest") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const dateA = new Date(questionsStruct[a].earliestDate);
            const dateB = new Date(questionsStruct[b].earliestDate);
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return dateA - dateB;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "mostScheduled") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const freqA = questionsStruct[a].dates.length;
            const freqB = questionsStruct[b].dates.length;
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return freqA - freqB;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "leastScheduled") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const dateA = questionsStruct[a].dates.length;
            const dateB = questionsStruct[b].dates.length;
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return dateB - dateA;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "mostParticipation") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const dateA = questionsStruct[a]["participationCompiled"];
            const dateB = questionsStruct[b]["participationCompiled"];
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return dateA - dateB;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "leastParticipation") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const dateA = questionsStruct[a]["participationCompiled"];
            const dateB = questionsStruct[b]["participationCompiled"];
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return dateB - dateA;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "mostRecent") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            const dateA = new Date(
              questionsStruct[a]["dates"][
                questionsStruct[a]["dates"].length - 1
              ]
            );
            const dateB = new Date(
              questionsStruct[b]["dates"][
                questionsStruct[b]["dates"].length - 1
              ]
            );
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return dateA - dateB;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      } else if (sortCondition === "leastRecent") {
        const sortedQuestions = Object.keys(questionsStruct)
          .sort((a, b) => {
            console.log(questionsStruct[a]["dates"]);
            const freqA = new Date(
              questionsStruct[a]["dates"][
                questionsStruct[a]["dates"].length - 1
              ]
            );
            const freqB = new Date(
              questionsStruct[b]["dates"][
                questionsStruct[b]["dates"].length - 1
              ]
            );
            // Reverse the comparison to sort in ascendoing order (older dates first)
            return freqB - freqA;
          })
          .reduce((acc, key) => {
            acc[key] = questionsStruct[key];
            return acc;
          }, {});
        setQuestionsData(sortedQuestions);
      }
    }
  }, [sortCondition]);

  const earliestDateToggle = () => {
    if (sortCondition === "recent") {
      setSortCondition("oldest");
    } else if (sortCondition === "oldest") {
      setSortCondition("recent");
    } else {
      setSortCondition("recent");
    }
  };

  const mostScheduledToggle = () => {
    if (sortCondition === "mostScheduled") {
      setSortCondition("leastScheduled");
    } else if (sortCondition === "leastScheduled") {
      setSortCondition("mostScheduled");
    } else {
      setSortCondition("mostScheduled");
    }
  };
  const recentDateToggle = () => {
    if (sortCondition === "mostRecent") {
      setSortCondition("leastRecent");
    } else if (sortCondition === "leastRecent") {
      setSortCondition("mostRecent");
    } else {
      setSortCondition("mostRecent");
    }
  };

  const participationToggle = () => {
    if (sortCondition === "mostParticipation") {
      setSortCondition("leastParticipation");
    } else if (sortCondition === "leastParticipation") {
      setSortCondition("mostParticipation");
    } else {
      setSortCondition("mostParticipation");
    }
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
  };

  console.log(tagsStruct);

  return (
    <div>
      {questionsData && enrolledData && (
        <div className="Dashboard__container">
          {groupInitialized === false && (
            <div
              style={{ textAlign: "center", backgroundColor: "white" }}
              className="GroupDashboard__emptymodal"
            >
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <h1>{groupData?.name}</h1>
                <div>
                  <p>This group has yet to be fully initialized.</p>
                  <p>
                    Complete the following tasks to view survey results and data
                    over time.
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "80%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "space-between",
                    width: "70%",
                    textAlign: "left",
                  }}
                >
                  <div>
                    <h2>Add Users</h2>
                    <h3>Join Code: {groupData?.joinCode}</h3>
                  </div>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2x"
                    className={
                      Object.keys(enrolledData).length === 0
                        ? "DashboardGroup__task-not-done"
                        : "DashboardGroup__task-done"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "space-between",
                    width: "70%",
                    textAlign: "left",
                  }}
                >
                  <div>
                    <h2>Create Surveys</h2>
                    <h3>
                      Do so on the calendar :{" "}
                      <span>
                        {"  "}
                        <FontAwesomeIcon icon={faCalendar} />
                      </span>
                    </h3>
                  </div>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="2x"
                    className={
                      Object.keys(questionsData).length === 0
                        ? "DashboardGroup__task-not-done"
                        : "DashboardGroup__task-done"
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className="Dashboard__data-section">
            <div className="Dashboard__header">
              <div className="Dashboard__header-text">
                <h1>{groupData?.name}</h1>
              </div>

              {enrolledData && (
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "5px" }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2>Join Code : {groupData?.joinCode}</h2>
                    <h2>Enrolled : {Object.keys(enrolledData).length}</h2>
                  </div>

                  <IconButton
                    icon={faGear}
                    onClick={() => navigate("groupsettings")}
                  />
                </div>
              )}
            </div>
            <div className="GroupDashboard__dashboard-middle">
              {groupInitialized === true && (
                <>
                  <div className="GroupDashboard__dashboard-left">
                    <h2>Existing Surveys</h2>
                    <div className="GroupDashboard__search-div">
                      <input
                        type="text"
                        placeholder="Search for a question"
                        value={searchedQuestion}
                        onChange={(event) =>
                          setSearchedQuestion(event.target.value)
                        }
                      />
                      <select value={selectedTag} onChange={handleTagChange}>
                        <option value="none">Filter By Tag</option>
                        {Object.keys(tagsStruct).length === 0 ? (
                          <option disabled>No tags available</option>
                        ) : (
                          tagsStruct.map((tag, index) => (
                            <option key={index} value={tag.value}>
                              {tag.label}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="GroupDashboard__question-labels">
                      <h4 style={{ flex: "0.6" }}>Question</h4>
                      <h4
                        style={{
                          flex: "0.3",
                          textAlign: "center",
                          cursor: "pointer" /* Maintain button cursor */,
                        }}
                        className="GroupDashboard__question-label"
                        onClick={() => {
                          earliestDateToggle();
                        }}
                      >
                        Earliest Date Scheduled
                      </h4>
                      <h4
                        style={{
                          flex: "0.3",
                          textAlign: "center",
                          cursor: "pointer" /* Maintain button cursor */,
                        }}
                        className="GroupDashboard__question-label"
                        onClick={() => {
                          mostScheduledToggle();
                        }}
                      >
                        Times Scheduled
                      </h4>
                      <h4
                        style={{
                          flex: "0.3",
                          textAlign: "center",
                          cursor: "pointer" /* Maintain button cursor */,
                        }}
                        className="GroupDashboard__question-label"
                        onClick={() => {
                          recentDateToggle();
                        }}
                      >
                        Last Scheduled
                      </h4>
                      <h4
                        style={{
                          flex: "0.3",
                          textAlign: "center",
                          cursor: "pointer" /* Maintain button cursor */,
                        }}
                        className="GroupDashboard__question-label"
                        onClick={() => {
                          participationToggle();
                        }}
                      >
                        Participation
                      </h4>
                    </div>
                    <div className="GroupDashboard__questions">
                      {Object.keys(questionsData).map((questionId, i) => {
                        // Filter questions based on the search input
                        const questionText =
                          questionsData[questionId]?.question;

                        /**
                         * Getting the tags of a question
                         */

                        const questionTags =
                          questionsData[questionId]?.tags || [];

                        /**checking to see if the question matches the
                         * conditions put forth by the search parameters (Tag and search filters)
                         **/
                        const matchesSearch =
                          searchedQuestion === "" ||
                          questionText
                            .toLowerCase()
                            .includes(searchedQuestion.toLowerCase());
                        const matchesTag =
                          selectedTag === "none" ||
                          questionTags.includes(selectedTag);

                        if (matchesSearch && matchesTag) {
                          return (
                            <AdminQuestion
                              key={i}
                              question={questionText}
                              questionData={questionsData[questionId]}
                              isSelected={selectedQuestion === questionId}
                              setSelected={() => selectedQCheck(questionId)}
                            />
                          );
                        }

                        return null;
                      })}
                    </div>
                  </div>

                  <div className="GroupDashboard__dashboard-right">
                    <h2>Participation Over Time</h2>
                    <Line options={partPlotOptions} data={partLineStruct} />
                  </div>
                </>
              )}
            </div>

            <div className="GroupDashboard__bottom">
              {selectedData && groupInitialized ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <AdminDisplay
                    selectedData={selectedData}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    lineStruct={lineStruct}
                    chartOptions={chartOptions}
                    lineProperties={lineProperties}
                  ></AdminDisplay>
                  <h2>Optional Feedback</h2>
                  <div style={{ maxHeight: "100px", overflowY: "scroll" }}>
                    {optionalFeedbacks.map((response, i) => (
                      <p key={i} className="GroupDashboard-response-card">
                        {response}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {groupInitialized === true && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p>responses have not been submitted for this question</p>
                      <p>check back later</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardGroup;
