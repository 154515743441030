import "./Topic.css";

const Topic = ({ topic, isSelected, setSelected }) => {
  return (
    <button
      className={
        isSelected ? "Topic__topic-btn Topic__selected" : "Topic__topic-btn"
      }
      onClick={setSelected}
    >
      <h3>{topic}</h3>
    </button>
  );
};

export default Topic;
