import "./ClassLegend.css";

const ClassLegend = ({ classesDetails, legend }) => {
  return (
    <div className="ClassLegend__container">
      <div className="ClassLegend__classes-container">
        {Object.keys(legend).map((key) => {
          return (
            <div className="ClassLegend__legend-row">
              <h3 className="ClassLegend__class-name text-medium">
                {classesDetails[key].name}
              </h3>
              <div
                className="ClassLegend__legend-dot"
                style={{ backgroundColor: legend[key] }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ClassLegend;
