import React, { useEffect, useState, useRef } from "react";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import { faX } from "@fortawesome/free-solid-svg-icons";
import PopupModal from "../PopupModal/PopupModal";
import IconButton from "../IconButton/IconButton";
const GroupDateModal = ({
  isOpen,
  setIsOpen,
  fullData,
  setParentData,
  classesDetails,
  selectedDateIn,

  selectedClass,
  selectedClassData,
}) => {
  /**
   * isOpen (boolean) : variable that states whether the modal is open or not
   * setIsOpen (func) : variable to control whether the modal is open or not
   * fullData (Object): the full data passed in from the main calendar component. Contains all questions and topics scheduled for a class
   * setParentData (func) : sets the overall data for the entire calendar
   * classesDetails (Object) : contains class information such as the title, name, and times (keys are the doc id for each given class in the db)
   * selectedDateIn (Date) : the date of the currently selected class
   * selectedClass (string) : the doc id of the current selected class
   * selectedClassData (Object) : object that contains the selected topics and questions for a scheduled class on a certain date
   */
  /* Mount-time Initializations */
  /* Utilities */
  const firestore = useFirestore();
  const notification = useNotification();
  /* Inputs */
  const [input, setInput] = useState({questions: {}});
  /* UI State Management */

  const forceClose = useRef({
    forceCloseStatus: false,
  });

  /* Constants */
  const MAX_CHARS_PER_LINE_QUESTIONS = 38;
  const MAX_CHARS_PER_LINE_OPTIONS = 36;


  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );
  console.log("START GroupDateModal.jsx");
  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );

  console.log("fullData")
  console.log(fullData)

  console.log("selectedClassData")
  console.log(selectedClassData)

  console.log("selectedDateIn")
  console.log(selectedDateIn)

  console.log("input")
  console.log(input)


  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );
  console.log("END GroupDateModal.jsx");
  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );


  /**
   * Converts a date object to a string
   * @param {Date} date
   * @param {String} order
   * @returns a string representing the inputted date object
   */
  function formatDateToString(date) {
    // Get the year, month, and day components from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with '0'
    const day = String(date.getDate()).padStart(2, "0");

    // Return formatted date string representation
    return `${month}-${day}-${year}`;
  }
  /**
   * Initializes state variables derived from selectedClassData (which is in turn derived from fullData).
   * Also initializes input for particular modal.
   * Runs whenever the modal is opened by the user.
   */
  useEffect(() => {
    // Run only when modal is being opened
    if (isOpen === true) {
      /* Initialize UI */
      const updatedInput = {
                            questions: {}
                          };
      const questionIds = Object.keys(selectedClassData.questions);
      // Ensure qids in input are always index values, and never true DB IDs
      if (questionIds.length > 0) {
        questionIds.forEach((qid, index) => {
          updatedInput.questions[index] = selectedClassData.questions[qid];
        });
      }
      // Set input
      setInput({
        ...updatedInput,
      });

      // Dynamically resize input text boxes based on contents
      const timer = setTimeout(() => {
        // Resize question / option text boxes
        for (const tempQuestionId of Object.keys(updatedInput.questions)) {
          resizeTextarea(
            `#question-input-${tempQuestionId}`,
            MAX_CHARS_PER_LINE_QUESTIONS - 5 // Reduce threshold to address internal textarea display bug
          );
          const currentQuestion = updatedInput.questions[tempQuestionId];
          const currentOptions = currentQuestion.options;
          for (const optionIndex in currentOptions) {
            resizeTextarea(
              `#option-input-${tempQuestionId}-${optionIndex}`,
              MAX_CHARS_PER_LINE_OPTIONS
            );
          }
        }
      }, 0);

      // Set forceCloseStatus to false
      forceClose.current.forceCloseStatus = true;

      // Clean up timer
      return () => clearTimeout(timer);
 
    }
  }, [isOpen, fullData, selectedClass, selectedClassData]);

  /**
   * Closes modal.
   * Warns user against closing without submitting changes.
   *
   * @param None
   */
  const close = () => {
    // Warn user that unsaved changes will be discarded if modal is closed without submitting survey
    if (forceClose.current.forceCloseStatus === false) {
      notification.warn(
        "Submit changes before closing. Click again to override."
      );
      forceClose.current.forceCloseStatus = true;
      setIsOpen(true);
    } else {
      // Close if user overrides warning
      setIsOpen(false);
    }
  };


  /**
   * Dynamically resizes given textarea based on specified maximum characters per length.
   *
   * @param {textareaId} the textarea ID
   * @param {maxCharsPerLine} the maximum number of characters a line can hold
   */
  const resizeTextarea = (textareaId, maxCharsPerLine) => {
    // Get textarea if it exists
    let textarea = null;
    try {
      textarea = document.querySelector(textareaId);
    }
    catch (error) {
      return;
    }

    // Resize element according to maxCharsPerLine
    if (
      textarea.value.length >= maxCharsPerLine ||
      textarea.value.includes("\n")
    ) {
      textarea.style.height = "auto";
      textarea.style.maxHeight = "none"; // Remove maxHeight restriction
      textarea.style.height = textarea.scrollHeight + "px";
    }
    if (
      textarea.value.length < maxCharsPerLine &&
      textarea.value.includes("\n") === false
    ) {
      textarea.style.maxHeight = "35px";
    }
  };

  /**
   * Submits questions to database and writes to fullData for in-session persistence.
   *
   * @param {event} the event
   */
  const submit = async (event = null) => {
    event.preventDefault();

    // Create deepcopy of fullData
    const newFullData = JSON.parse(JSON.stringify(fullData));

    /**
     * Questions
     *
     */
    const updatedQuestions = {};
    for (const questionId in input.questions) {
      const question = input.questions[questionId];

      // Disallow empty questions or empty option sets
      if (
        question.question.trim() === "" ||
        question.options.every((opt) => opt.trim() === "")
      ) {
        notification.error("Incomplete questions are not allowed.");
        return;
      }

      // Remove all blank options from question option set
      const updatedOptions = question.options.filter(
        (opt) => opt.trim() !== ""
      );
      question.options = updatedOptions;

      // Search for match in displayedQuestions
      let matchingDisplayedQuestionId = null;
      for (const displayedQuestionId in fullData[selectedClass]
        .displayedQuestions) {
        const displayedQuestion =
          fullData[selectedClass].displayedQuestions[displayedQuestionId];

        if (
          question.question.toLowerCase().trim() ===
          displayedQuestion.question.toLowerCase()
        ) {
          // Potential match, compare option sets
          if (question.options.length === displayedQuestion.options.length) {
            const sortedQuestionOptions = [...question.options].sort();
            const sortedDisplayedQuestionOptions = [
              ...displayedQuestion.options,
            ].sort();
            // Option sets match perfectly, matching displayed question found so get ID
            if (
              sortedQuestionOptions.every(
                (value, index) =>
                  value.toLowerCase() ===
                  sortedDisplayedQuestionOptions[index].toLowerCase()
              )
            ) {
              matchingDisplayedQuestionId = displayedQuestionId; // db id + idx
              break;
            }
          }
        }
      }

      if (matchingDisplayedQuestionId !== null) {
        // Get deepcopy of matchingDisplayedQuestion to avoid prematurely mutating data model and its derived states
        const matchingDisplayedQuestion = {
          question:
            fullData[selectedClass].displayedQuestions[
              matchingDisplayedQuestionId
            ].question,
          options:
            fullData[selectedClass].displayedQuestions[
              matchingDisplayedQuestionId
            ].options,
          dates:
            fullData[selectedClass].displayedQuestions[
              matchingDisplayedQuestionId
            ].dates,
          earliestDate:
            fullData[selectedClass].displayedQuestions[
              matchingDisplayedQuestionId
            ].earliestDate,
        };
        const matchingDisplayedQuestionType =
          fullData[selectedClass].displayedQuestions[
            matchingDisplayedQuestionId
          ].type; // "global" or "local"
        /**
         * if questionType === "local":
         *  Get local qid from matchingDisplayedQuestion by substringing characters [0, 20]
         *
         *  {Update matchingDisplayedQuestion deepcopy}
         *    @dates
         *    @earliestDate
         *
         *  {Update internal data model}
         *    Copy updated matchingDisplayedQuestion:
         *      @fullData [selectedClass][selectedDateIn].questions
         *      @fullData [selectedClass].allQuestions
         *      @fullData [selectedClass].displayedQuestions
         *
         *  {Update database}
         *  =>classes / =>questions:
         *    *dates
         *    *earliestDate
         *
         *
         */
        if (matchingDisplayedQuestionType === "local") {
          /* Update matchingDisplayedQuestion */
          // @dates
          const currentDate = formatDateToString(selectedDateIn);
          if (matchingDisplayedQuestion.dates.includes(currentDate) === false) {
            matchingDisplayedQuestion.dates.push(currentDate);
          }

          // @earliestDate
          if (
            new Date(currentDate) <
              new Date(matchingDisplayedQuestion.earliestDate) ||
            matchingDisplayedQuestion.earliestDate === ""
          ) {
            matchingDisplayedQuestion.earliestDate = currentDate;
          }

          /* Update internal data model */
          const localQuestionId = matchingDisplayedQuestionId.slice(0, 20);

          // @fullData [selectedClass][selectedDateIn].questions
          updatedQuestions[localQuestionId] = matchingDisplayedQuestion;

          // @fullData [selectedClass].allQuestions
          newFullData[selectedClass].allQuestions[localQuestionId] =
            matchingDisplayedQuestion;

          // @fullData [selectedClass].displayedQuestions
          newFullData[selectedClass].displayedQuestions[localQuestionId] = {
            ...matchingDisplayedQuestion,
            type: matchingDisplayedQuestionType,
          };

          /* Update database */
          /*
           *  =>classes / =>questions:
           *    *dates
           *    *earliestDate
           */
          firestore.updateDoc(
            `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions/${localQuestionId}`,
            {
              ...matchingDisplayedQuestion,
            },
            { merge: true }
          )
        } else if (matchingDisplayedQuestionType === "global") {
          /**
           * if questionType === "global":
           *
           *  {Update matchingDisplayedQuestion deepcopy}
           *    @dates
           *    @earliestDate
           *
           *  {Update database}
           *    Create document in =>classes / =>questions
           *    Get localQuestionId
           *
           *  {Update internal data model}
           *    Copy updated matchingDisplayedQuestion:
           *      @fullData [selectedClass][selectedDateIn].questions
           *      @fullData [selectedClass].allQuestions
           *      @fullData [selectedClass].displayedQuestions
           *
           *
           */
          /* Update matchingDisplayedQuestion */
          // @dates
          const currentDate = formatDateToString(selectedDateIn);
          if (matchingDisplayedQuestion.dates.includes(currentDate) === false) {
            matchingDisplayedQuestion.dates.push(currentDate);
          }

          // @earliestDate
          if (
            new Date(currentDate) <
              new Date(matchingDisplayedQuestion.earliestDate) ||
            matchingDisplayedQuestion.earliestDate === ""
          ) {
            matchingDisplayedQuestion.earliestDate = currentDate;
          }

          /* Update database */
          // Create document in =>classes / =>questions
          const result = await firestore.createDoc(
            `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions`,
            true,
            {
              ...matchingDisplayedQuestion,
            }
          );
          const localQuestionId = result.id;

          /* Update internal data model */
          // @fullData [selectedClass][selectedDateIn].questions
          updatedQuestions[localQuestionId] = matchingDisplayedQuestion;

          // @fullData [selectedClass].allQuestions
          newFullData[selectedClass].allQuestions[localQuestionId] =
            matchingDisplayedQuestion;

          // @fullData [selectedClass].displayedQuestions
          newFullData[selectedClass].displayedQuestions[localQuestionId] = {
            ...matchingDisplayedQuestion,
            type: "local",
          };
          delete newFullData[selectedClass].displayedQuestions[
            matchingDisplayedQuestionId
          ];

          // @fullData [selectedClass].globalQuestions
          delete newFullData[selectedClass].globalQuestions[
            matchingDisplayedQuestionId
          ];
        } else {
          // Error invalid question type
          throw new Error(
            `Invalid question type in fullData[${selectedClass}].displayedQuestions object`
          );
        }
      } else {
        /**
         * if questionType === null: i.e. new custom user question
         *
         *  Create new question object i.e. userQuestion
         *
         *  {Update database}
         *    Create document in =>classes / =>questions
         *    Get localQuestionId
         *
         *  {Update internal data model}
         *    Copy userQuestion:
         *      @fullData [selectedClass][selectedDateIn].questions
         *      @fullData [selectedClass].allQuestions
         *      @fullData [selectedClass].displayedQuestions
         *
         *
         */
        /* Create new question object */
        const userQuestion = {
          question: question.question.trim(),
          options: question.options,
          dates: [formatDateToString(selectedDateIn)],
          earliestDate: formatDateToString(selectedDateIn),
        };

        /* Update database */
        // Create document in =>classes / =>questions
        const result = await firestore.createDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions`,
          true,
          {
            ...userQuestion,
          }
        );
        const localQuestionId = result.id;

        /* Update internal data model */
        // @fullData [selectedClass][selectedDateIn].questions
        updatedQuestions[localQuestionId] = userQuestion;

        // @fullData [selectedClass].allQuestions
        newFullData[selectedClass].allQuestions[localQuestionId] = userQuestion;

        // @fullData [selectedClass].displayedQuestions
        // Delete existing identical question in fullData[selectedClass].displayedQuestions, if any, and add userQuestion to object
        for (const displayedQuestionId in fullData[selectedClass]
          .displayedQuestions) {
          const displayedQuestion =
            fullData[selectedClass].displayedQuestions[displayedQuestionId];
          if (
            displayedQuestion.question.toLowerCase() ===
            userQuestion.question.toLowerCase()
          ) {
            delete newFullData[selectedClass].displayedQuestions[
              displayedQuestionId
            ];
            break;
          }
        }
        newFullData[selectedClass].displayedQuestions[localQuestionId] = {
          ...userQuestion,
          type: "local",
        };

      }
    } // end loop over temporary questionIds

    /**
     *
     * {Update internal data model}
     *  @fullData [selectedClass][selectedDateIn].questionIds
     *  Call setParentData
     *
     * {Update database}
     *    =>lectures
     *      *questionIds i.e. copy updatedQuestionIds aggregator
     *
     *    =>classes / =>questions
     *      *dates i.e. correct *dates fields in question docs
     */

    /* Update internal data model */
    // @fullData [selectedClass][selectedDateIn].questionIds
    const updatedQuestionIds = Object.keys(updatedQuestions);
    newFullData[selectedClass][formatDateToString(selectedDateIn)].questionIds =
      updatedQuestionIds;

    newFullData[selectedClass][formatDateToString(selectedDateIn)].questions =
      updatedQuestions;

    // Call setParentData
    setParentData(newFullData);

    /* Update database */
    /**
     *  =>lectures
     *    *questionIds i.e. copy updatedQuestionIds aggregator
     */
    firestore.updateDoc(
      `universities/${
        firestore.userData.universityId
      }/classes/${selectedClass}/lectures/${formatDateToString(
        selectedDateIn
      )}`,
      {
        questionIds: updatedQuestionIds
      },
      { merge: true }
    );

    /**    =>classes / =>questions
     *       i.e. find all questions in db where *dates field includes currentDate
     *            verify that they have not been deleted by the user from the current date in the course of the most recent session by
     *            checking to see if the doc ID is stored in updatedQuestionIds
     *            if updatedQuestionIds.includes(questionDocId) === false {
     *               question has been deleted by user in most recent session, delete selectedDateIn from *dates field and modify *earliestDate if needed
     *            }
     *           else {
     *             question has not been deleted in most recent session, no modifications to questionDoc needed
     *           }
     */

    // Iterate over =>classes / =>questions docs
    const currentDate = formatDateToString(selectedDateIn);
    const questionsQuery = firestore.query(
      `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions`
    );
    const questionsSnapshot = await firestore.getDocuments(questionsQuery);
    const questionDocuments = questionsSnapshot.docs;
    for (const questionDoc of questionDocuments) {
      const questionData = questionDoc.data();
      const dates = [...questionData.dates];
      if (dates.includes(currentDate) === false) {
        continue;
      } // Disregard questions without any ties to the current date
    

      const questionId = questionDoc.id;  
      if (updatedQuestionIds.includes(questionId) === false) { // Question represented by current doc has been deleted by user in most recent session
        // Delete selectedDateIn from *dates
        const updatedDates = dates.filter((date) => date !== currentDate);

        // Deleted selectedDateIn from *datesMetadata
        const updatedDatesMetadata = {
          ...questionData.datesMetadata
        };
        delete updatedDatesMetadata[currentDate];

        console.log("updatedDatesMetadata")
        console.log(updatedDatesMetadata)

        // Update *earliestDate, if currently stored earliest date is selectedDateIn 
        let updatedEarliestDate = questionData.earliestDate;
        if (updatedDates.length === 0) {
          updatedEarliestDate = "";
        } else {
          // Find minimum in updatedDates
          const updatedDatesYearFirst = updatedDates.map((date) => {
            const [month, day, year] = date.split("-");
            return `${year}-${month}-${day}`;
          });
          updatedDatesYearFirst.sort();
          updatedEarliestDate = updatedDatesYearFirst[0];
        }

        // Update questionData / write to db with merge: false to bypass Firebase bug (updateDoc writes outdated version of updatedDatesMetadata)
        questionData.dates = updatedDates;
        questionData.datesMetadata = updatedDatesMetadata;
        questionData.earliestDate = updatedEarliestDate;
        // Write updated dates to database
        firestore.updateDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions/${questionId}`,
          {
            dates: updatedDates,
            datesMetadata: {
                                ...updatedDatesMetadata
                           },
            earliestDate: updatedEarliestDate
          },
          { merge: true }
        );
      }
    }

    // Close modal
    setIsOpen(false);

    // Send success notification
    notification.success("Surveys Updated!");
  };

  /**
   * Removes the specified question from the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {targetQId} the target qid
   */
  const removeQuestion = (targetQId) => {
    if (selectedClass !== "") {
      // Create updated questions object, remove specified question
      const updatedQuestions = JSON.parse(JSON.stringify(input.questions));
      delete updatedQuestions[targetQId];

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  return (
    <PopupModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={close}
      minHeight={"45rem"}
      style={{ overflowY: "auto" }}
    >
      {classesDetails && selectedClass && (
        <div className="ClassDateModal__container">
          {/* Modal Header */}
          <div>
            <h1>{classesDetails[selectedClass]?.name}</h1>
            <h2>{formatDateToString(selectedDateIn)}</h2>
          </div>
          {Object.keys(input.questions) && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              {Object.keys(input.questions).map((tempQuestionId, index) => (
                <div
                  key={tempQuestionId}
                  className="ClassDateModal__topic-oval"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                    gap: "5px",
                    width: "100%",
                  }}
                >
                  {/* Question Oval Component */}
                  <div className="input-icon-container">
                    {/* Input Text Box */}
                    <textarea
                      id={`question-input-${tempQuestionId}`}
                      className="question-input"
                      value={input.questions[tempQuestionId].question}
                      readOnly={true}
                      style={{
                        resize: "none",
                        overflow: "hidden",
                        maxHeight: "35px",
                      }}
                    />
                    {/* Close Icon */}
                    <IconButton
                      icon={faX}
                      backgroundColor="rgb(255, 255, 255)"
                      className="question-remove-button"
                      onClick={() => removeQuestion(tempQuestionId)}
                    ></IconButton>
                  </div>
                  {/* End Question Input Text Box + X Icon */}
                  {/* Options Section */}
                  {input.questions[tempQuestionId].options.map((option, optIndex) => (
                    <div className="option-row" key={optIndex}>
                      <textarea
                        id={`option-input-${tempQuestionId}-${optIndex}`}
                        className="option-input"
                        type="text"
                        readOnly={true}
                        value={option}
                        style={{
                          resize: "none",
                          overflow: "hidden",
                          maxHeight: "35px",
                        }}
                      />
                    </div>
                  ))}{" "}
                  {/* End Options Section */}
                </div>
              ))}
            </div>
          )}
          <button
            type="button"
            style={{
              width: "80%",
              backgroundColor: "rgb(var(--primary))", // Darker blue
              color: "white",
              padding: "10px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              marginLeft: "25px",
              marginTop: "80px",
            }}
            onClick={submit}
          >
            Submit
          </button>
        </div>
      )}
    </PopupModal>
  );
};
export default GroupDateModal;
