import { useState, useEffect, useRef } from "react";
import { clsx } from "clsx";
import {
  faCircleInfo,
  faRepeat,
  faXmark,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import IconButton from "../IconButton/IconButton";
import DateInput from "../../components/DateInput/DateInput";

import "../PopupModal/PopupModal.css";
import "../AdminModalScheduler/AdminModalScheduler.css";

const REPEAT_INTERVALS = {
  WEEKLY: "Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
};

export default function AdminModalScheduler({
  isOpen,
  setIsOpen,
  fullData,
  setParentData,
  classesDetails,
  setClassesDetails,
}) {
  const firestore = useFirestore();
  const notification = useNotification();

  const [input, setInput] = useState({});
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedTag, setSelectedTag] = useState("");
  const [searchedQuestion, setSearchedQuestion] = useState("");
  const [searchedTag, setSearchedTag] = useState("");
  const [repeating, setRepeating] = useState(false);
  const [repeatInterval, setRepeatInterval] = useState(REPEAT_INTERVALS.WEEKLY);

  const [selectedQuestion, setSelectedQuestion] = useState(""); //stores the value (string) of the selected question's docid
  const [selectedDateIn, setSelectedDateIn] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });
  const [selectedDateOut, setSelectedDateOut] = useState(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  });

  /* UI State Management */
  const forceClose = useRef({
    forceCloseStatus: false,
  });

  let SCHEDULING_DEADLINE = new Date();
  SCHEDULING_DEADLINE.setHours(14, 0, 0, 0); // Admins cannot send questions for current day after 2p

  useEffect(() => {
    if (isOpen === true) {
      setInput({ questions: {} });
    }
  }, [isOpen]);

  const groups = classesDetails
    ? Object.values(classesDetails).filter(({ type }) => type === "group")
    : [];

  const filteredQuestions = selectedGroupId
    ? Object.entries(fullData[selectedGroupId].displayedQuestions).filter(
        ([_, { question, tags }]) => {
          const matchesSearch =
            !searchedQuestion ||
            question.toLowerCase().includes(searchedQuestion.toLowerCase());
          const matchesTag = !selectedTag || tags?.includes(selectedTag);
          return matchesSearch && matchesTag;
        }
      )
    : [];

  // const buildGroupsStruct = () => {
  //   if (classesDetails !== null) {
  //     // Build and return groupsStruct
  //     return Object.keys(classesDetails)
  //       .filter((classId) => classesDetails[classId].type === "group")
  //       .map((classId) => ({
  //         value: classId, // Assuming 'classId' is the property you want as the value
  //         label: classesDetails[classId].name, // Access the title property from the inner dictionary
  //       }));
  //   } else {
  //     // Otherwise return empty array
  //     return [];
  //   }
  // };

  /**
   * Builds questionsStruct, a hashmap <K:question <str>, V: qid <str>>.
   * It is used by the dropdown menu to display suggested questions.
   *
   * @param None.
   *
   */
  // const buildQuestionsStruct = () => {
  //   if (selectedGroupId !== null) {
  //     // Build and return questionsStruct
  //     return Object.keys(fullData[selectedGroupId].displayedQuestions).map(
  //       (qid) => ({
  //         value: qid,
  //         label: fullData[selectedGroupId].displayedQuestions[qid].question,
  //       })
  //     );
  //   } else {
  //     // Otherwise return empty array
  //     return [];
  //   }
  // };

  /**
   * Adds an empty question to the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {event} event
   */
  const addQuestion = (event = null, questionObj = null, newQuestion = "") => {
    if (event !== null) {
      event.preventDefault();
    }

    if (selectedGroupId !== null) {
      if (questionObj === null) {
        questionObj = {
          question: newQuestion,
          options: [],
          tags: [],
        };
      }

      // ensuring questionObj has a tags field that is not undefined
      if (questionObj["tags"] === undefined) {
        questionObj["tags"] = [];
      }

      // Add questionObj to bottom of UI
      const tempQuestionIds = Object.keys(input.questions) || [];
      let nextTempQuestionId = null;
      if (tempQuestionIds.length === 0) {
        nextTempQuestionId = 0;
      } else {
        nextTempQuestionId =
          parseInt(tempQuestionIds[tempQuestionIds.length - 1]) + 1;
      }

      const updatedQuestions = {
        ...input.questions,
        [nextTempQuestionId]: questionObj,
      };

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;

      /*
      if (event === null) {
        setTimeout(() => {
          // Perform dynamic resizing on target textarea element for displayed questions
          resizeTextarea(
            `#question-input-${nextTempQuestionId}`,
            MAX_CHARS_PER_LINE_QUESTIONS,
          );
          for (const oi in questionObj.options) {
            resizeTextarea(
              `#option-input-${nextTempQuestionId}-${oi}`,
              MAX_CHARS_PER_LINE_OPTIONS,
            );
          }
        }, 10);
      }
      */
    } else {
      notification.warn("Select a target group before adding questions.");
    }
  };

  /**
   * Adds an empty option field under the specified question on the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {event} event
   * @param {qid}   the qid
   * @param {key}   signals whether user used Enter key to create topic
   */
  const addOption = (event, qid, index = null, key = null) => {
    event.preventDefault();

    if (selectedGroupId !== null) {
      // Add empty option field to input.questions[qid]
      const updatedOptions = [...input.questions[qid].options];
      if (index === null) {
        updatedOptions.push("");
      } else {
        updatedOptions.splice(index + 1, 0, "");
      }
      const updatedQuestions = {
        ...input.questions,
        [qid]: {
          ...input.questions[qid],
          options: updatedOptions,
        },
      };
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      if (key === "Enter") {
        // Move cursor to next option input text box if user added option with Enter key
        setTimeout(() => {
          const nextInput = document.querySelector(
            `#option-input-${qid}-${index + 1}`
          );
          if (nextInput) {
            nextInput.focus();
          }
        }, 5);
      }
    }
  };

  /**
   * Removes the specified option from specified question from the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {targetQId} the target qid
   * @param {targetIdx} the target option index
   */
  const removeOption = (targetQId, targetIdx) => {
    if (selectedGroupId !== null) {
      // Create updated option set for passed question
      const updatedOptions = JSON.parse(
        JSON.stringify(input.questions[targetQId].options)
      );
      updatedOptions.splice(targetIdx, 1);

      // Create updated question object
      const updatedQuestions = {
        ...input.questions,
        [targetQId]: {
          ...input.questions[targetQId],
          options: updatedOptions,
        },
      };

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  /**
   * Adds an empty option field under the specified question on the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {qid}   the qid
   * @param {tagInput} the inputted tag
   */
  const addTag = (qid, tagInput) => {
    if (selectedGroupId !== null) {
      // Add empty option field to input.questions[qid]
      const updatedTags = [...input.questions[qid].tags];
      updatedTags.push(tagInput);

      const updatedQuestions = {
        ...input.questions,
        [qid]: {
          ...input.questions[qid],
          tags: updatedTags,
        },
      };
      setInput({
        ...input,
        questions: updatedQuestions,
      });
    }
  };

  /**
   * removes a specified tag from the question
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {qid} the target qid
   * @param {targetIdx} the target option index
   */
  const removeTag = (qid, tagToRemove) => {
    if (selectedGroupId !== null) {
      // Create updated option set for passed question
      const updatedTags = input.questions[qid].tags.filter(
        (tag) => tag !== tagToRemove
      );

      // Create updated question object
      const updatedQuestions = {
        ...input.questions,
        [qid]: {
          ...input.questions[qid],
          tags: updatedTags,
        },
      };

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  /**
   * Closes modal.
   * Warns user against closing without submitting changes.
   *
   * @param None
   */
  const close = () => {
    // Warn user that unsaved changes will be discarded if modal is closed without submitting survey
    if (forceClose.current.forceCloseStatus === false) {
      notification.warn(
        "Submit changes before closing. Click again to override."
      );
      forceClose.current.forceCloseStatus = true;
      setIsOpen(true);
    } else {
      // Close if user overrides warning
      setIsOpen(false);

      // Clean up
      setSelectedGroupId(null);
      if (selectedQuestion) {
        console.log(selectedQuestion);
      }
      setSelectedQuestion("");

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setSelectedDateIn(today);
      setSelectedDateOut(today);
      setSearchedQuestion("");

      setSelectedTag("none");
      setRepeating(false);
    }
  };

  /**
   * Submits and schedules the question.
   *
   * @return None
   */
  const submit = async (event) => {
    event.preventDefault();

    // Compute date-time information of current moment
    const now = new Date();
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);

    // Disallow start date being after end date
    if (repeating === true && selectedDateIn > selectedDateOut) {
      notification.error("Start date must be before the end date.");
      return;
    }
    // Disallow start date being equivalent to end date
    else if (
      repeating === true &&
      formatDateToString(selectedDateIn) === formatDateToString(selectedDateOut)
    ) {
      notification.error(
        'Start date cannot be the same as the end date. Uncheck "Repeating" to issue a question on a single day.'
      );
      return;
    }
    // Disallow quetion scheduling for current date after SCHEDULING_DEADLINE
    else if (
      now > SCHEDULING_DEADLINE &&
      formatDateToString(selectedDateIn) ===
        formatDateToString(SCHEDULING_DEADLINE)
    ) {
      notification.error(
        `Cannot schedule questions for current day past ${SCHEDULING_DEADLINE.getHours()}:00p`
      );
      return;
    }
    // Start date cannot be before current date
    else if (selectedDateIn < today) {
      notification.error("Start date must be on or after the current date.");
      return;
    }
    // User must specify repeatability
    else if (repeating === true && repeatInterval === null) {
      notification.error("Must set repeatability cadence.");
      return;
    }

    const newFullData = JSON.parse(JSON.stringify(fullData));
    const submittedQuestions = {};
    const submittedDates = computeDates();
    const staleDatesToQuestionIds = {}; // <K: date, V: qids[]>
    /**
     * Compute (dates, datesMetadata, earliesDate) for each submitted question
     * Update date-independent structures i.e. data model: fullData[class].(allQuestions, displayedQuestions, globalQuestions)
     *                                         database:   =>questions docs
     * At the same time, build data structures that enable efficient updates of date-dependent structures
     *                                   i.e.  data model: fullData[class][date].(questions, questionIds)
     * Specifically, these are submittedQuestions and staleDatesToQuestionIds.
     *
     * Placing all submitted questions into a single map allows us to iterate over submitted dates
     * and update date-dependent structures in a single pass later.
     *
     * This approach keeps database I/O operations at O(n + m) where n is the number of questions
     *                                                         and m is the number of submitted dates
     * which is the global minimum for this problem.
     */

    let newTags = classesDetails[selectedGroupId]?.tags || [];
    for (const tempQuestionId in input.questions) {
      const question = {
        question: input.questions[tempQuestionId].question,
        options: input.questions[tempQuestionId].options,
        tags: input.questions[tempQuestionId].tags,
      };
      for (const tag of question.tags) {
        console.log(tag);
        // Check if the 'classesDetails' object does not include the current tag
        if (!newTags.includes(tag)) {
          console.log("not in");
          newTags.push(tag);
        }
      }
      console.log(newTags);

      //check to see if all of the questions tags already exist in the field for the group

      // Disallow empty questions or empty option sets
      if (
        question.question.trim() === "" ||
        question.options.every((opt) => opt.trim() === "")
      ) {
        notification.error("Incomplete questions are not allowed.");
        return;
      }

      // Remove all blank options from question option set
      const updatedOptions = question.options.filter(
        (opt) => opt.trim() !== ""
      );

      // Remove all blank tags from question tags set
      const updatedTags = question.tags.filter((tag) => tag.trim() !== "");

      question.tags = updatedTags;
      question.options = updatedOptions;

      // Search for match in displayedQuestions
      let matchingDisplayedQuestionId = null;
      for (const displayedQuestionId in fullData[selectedGroupId]
        .displayedQuestions) {
        const displayedQuestion =
          fullData[selectedGroupId].displayedQuestions[displayedQuestionId];

        if (
          question.question.toLowerCase().trim() ===
          displayedQuestion.question.toLowerCase()
        ) {
          // Potential match, compare option sets
          if (question.options.length === displayedQuestion.options.length) {
            const sortedQuestionOptions = [...question.options].sort();
            const sortedDisplayedQuestionOptions = [
              ...displayedQuestion.options,
            ].sort();
            // Option sets match perfectly, matching displayed question found so get ID
            if (
              sortedQuestionOptions.every(
                (value, index) =>
                  value.toLowerCase() ===
                  sortedDisplayedQuestionOptions[index].toLowerCase()
              )
            ) {
              matchingDisplayedQuestionId = displayedQuestionId;
              break;
            }
          }
        }
      } // End of displayedQuestions search

      //if there was a matching question in the search above
      if (matchingDisplayedQuestionId !== null) {
        const matchingDisplayedQuestion = JSON.parse(
          JSON.stringify(
            fullData[selectedGroupId].displayedQuestions[
              matchingDisplayedQuestionId
            ]
          )
        );
        const matchingDisplayedQuestionType = matchingDisplayedQuestion.type; // "global" or "local"

        console.log("matchingDisplayedQuestion found...");
        console.log(matchingDisplayedQuestion);

        if (matchingDisplayedQuestionType === "local") {
          const oldDates = [...matchingDisplayedQuestion.dates];
          const oldDatesMetadata = matchingDisplayedQuestion.datesMetadata;
          const oldEarliestDate = matchingDisplayedQuestion.earliestDate;
          let scheduleModified = true;
          if (oldDates.length === submittedDates.length) {
            // oldDates === newDates, so merging is unecessary
            if (
              oldDates.every(
                (oldDate, index) =>
                  oldDate === submittedDates[index] &&
                  oldDatesMetadata[oldDate].repeating ===
                    oldDatesMetadata[submittedDates[index]].repeating
              )
            ) {
              scheduleModified = false;
            }
          }
          if (scheduleModified === true) {
            const datesInfo = mergeDates(
              oldDates,
              submittedDates,
              oldDatesMetadata,
              oldEarliestDate,
              now
            );
            /*
              datesInfo:
              {
                currentDates,
                staleDates,
                datesMetadata,
                earliestDate
              }
            */
            question.dates = datesInfo.currentDates;
            question.datesMetadata = datesInfo.datesMetadata;
            question.earliestDate = datesInfo.earliestDate;

            //updating the question doc in the db with information that could have changed in the new submission
            firestore.updateDoc(
              `universities/${firestore.userData.universityId}/classes/${selectedGroupId}/questions/${matchingDisplayedQuestionId}`,
              {
                dates: question.dates,
                datesMetadata: {
                  ...question.datesMetadata,
                },
                earliestDate: question.earliestDate,
                tags: question.tags,
              },
              { merge: true }
            );

            submittedQuestions[matchingDisplayedQuestionId] = question;

            for (const staleDate of datesInfo.staleDates) {
              if (Object.hasOwn(staleDatesToQuestionIds, staleDate) === false) {
                staleDatesToQuestionIds[staleDate] = [];
              }
              staleDatesToQuestionIds[staleDate].push(
                matchingDisplayedQuestionId
              );
            }

            newFullData[selectedGroupId].allQuestions[
              matchingDisplayedQuestionId
            ] = question;
            newFullData[selectedGroupId].displayedQuestions[
              matchingDisplayedQuestionId
            ] = {
              ...question,
              type: "local",
            };
          }
        } else if (matchingDisplayedQuestionType === "global") {
          question.dates = submittedDates;

          const datesMetadata = {};
          submittedDates.forEach((submittedDate) => {
            datesMetadata[submittedDate] = {
              repeating: repeating,
            };
          });
          question.datesMetadata = datesMetadata;

          question.earliestDate = submittedDates[0];

          const result = await firestore.createDoc(
            `universities/${firestore.userData.universityId}/classes/${selectedGroupId}/questions`,
            true,
            {
              ...question,
            }
          );
          const localQuestionId = result.id;

          submittedQuestions[localQuestionId] = question;

          newFullData[selectedGroupId].allQuestions[localQuestionId] = question;
          newFullData[selectedGroupId].displayedQuestions[localQuestionId] = {
            ...question,
            type: "local",
          };
          delete newFullData[selectedGroupId].displayedQuestions[
            matchingDisplayedQuestionId
          ];
          delete newFullData[selectedGroupId].globalQuestions[
            matchingDisplayedQuestionId
          ];
        } else {
          // Error invalid question type
          throw new Error(
            `Invalid question type in fullData[${selectedGroupId}].displayedQuestions object: ${matchingDisplayedQuestionType}`
          );
        }
      }
      // User typed in new question
      else {
        question.dates = submittedDates;

        const datesMetadata = {};
        submittedDates.forEach((submittedDate) => {
          datesMetadata[submittedDate] = {
            repeating: repeating,
          };
        });
        question.datesMetadata = datesMetadata;

        question.earliestDate = submittedDates[0];

        const result = await firestore.createDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedGroupId}/questions`,
          true,
          {
            ...question,
          }
        );
        const localQuestionId = result.id;

        submittedQuestions[localQuestionId] = question;

        newFullData[selectedGroupId].allQuestions[localQuestionId] = question;
        newFullData[selectedGroupId].displayedQuestions[localQuestionId] = {
          ...question,
          type: "local",
        };
      }

      // Update date-dependent data structures
      for (const date of submittedDates) {
        let oldQuestions = {};
        if (Object.hasOwn(fullData[selectedGroupId], date) === true) {
          oldQuestions = fullData[selectedGroupId][date].questions;
        }

        const updatedQuestions = {
          ...oldQuestions,
          ...submittedQuestions,
        };
        if (Object.hasOwn(newFullData[selectedGroupId], date) === false) {
          newFullData[selectedGroupId][date] = {};
        }
        newFullData[selectedGroupId][date].questions = updatedQuestions;

        const updatedQuestionIds = Object.keys(updatedQuestions);
        newFullData[selectedGroupId][date].questionIds = updatedQuestionIds;

        firestore.updateDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedGroupId}/lectures/${date}`,
          {
            questionIds: updatedQuestionIds,
          },
          { merge: true }
        );
      }

      // Handle stale dates
      for (const date in staleDatesToQuestionIds) {
        const staleQuestionIds = staleDatesToQuestionIds[date];
        const updatedQuestions = newFullData[selectedGroupId][date].questions;
        for (const staleQuestionId of staleQuestionIds) {
          delete updatedQuestions[staleQuestionId];
        }

        newFullData[selectedGroupId][date].questions = updatedQuestions;

        const updatedQuestionIds = Object.keys(updatedQuestions);
        newFullData[selectedGroupId][date].questionIds = updatedQuestionIds;

        firestore.updateDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedGroupId}/lectures/${date}`,
          {
            questionIds: updatedQuestionIds,
          },
          { merge: true }
        );
      }
    }

    //updating classesDetails to add the newly added tags to the "tags" field of the selectedClass
    let newClassesDetails = classesDetails;
    newClassesDetails[selectedGroupId]["tags"] = newTags;
    setClassesDetails(newClassesDetails);

    //updating the class tags in the db to add the newly added tags to the "tags" field of the selectedClass
    try {
      if (JSON.stringify(newTags) !== JSON.stringify(classesDetails.tags)) {
        await firestore.updateDoc(
          `universities/${firestore.userData.universityId}/classes/${setSelectedGroupId}`,
          {
            tags: newTags,
          },
          { merge: true }
        );
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }

    //updating the data used to create the calendar to reflect the new schedule
    setParentData(newFullData);

    //resetting states in the modal
    // Close modal
    setIsOpen(false);

    // Clean up
    setSelectedGroupId(null);
    setSelectedDateIn(today);
    setSelectedDateOut(today);
    setRepeating(false);
    setSearchedQuestion("");

    // Send success notification
    notification.success("Survey submitted!");
  };

  /**
   * Converts a Date object to a string
   *
   * @param {Date} date
   * @returns a string representing the inputted date object
   */
  function formatDateToString(date) {
    // Get the year, month, and day components from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with '0'
    const day = String(date.getDate()).padStart(2, "0");

    return `${month}-${day}-${year}`;
  }

  /**
   * Merges the existing and new schedules together according to the following rules:
   *  - all past dates are preserved
   *  - all dates corresponding to one-off questions are preserved
   *  - all future dates in the existing schedule will be overwritten by the new schedule
   *
   * @returns newDates: the merged dates
   *
   */
  const mergeDates = (oldDates, newDates, datesMetadata, earliestDate, now) => {
    const today = new Date(now);
    today.setHours(0, 0, 0, 0);

    let currentDates = [];
    let staleDates = [];
    if (repeating === true) {
      // Save all dates in oldDates that precede the current date since corresponding lecture / question docs in db presumably contain past response data
      // If it is past scheduling deadline for current date, save current date too if it appears in oldDates bc question has already been sent out
      let oldDatesPivotIndex = oldDates.findIndex((oldDate) => {
        return stringToDate(oldDate) >= today;
      });
      if (oldDatesPivotIndex !== -1) {
        if (
          oldDates.includes(formatDateToString(today)) &&
          now > SCHEDULING_DEADLINE
        ) {
          oldDatesPivotIndex++;
        }
        currentDates = [...oldDates.slice(0, oldDatesPivotIndex)];
      }

      // Push all future one-off questions in oldDates to currentDates && find all stale dates in oldDates
      for (let i = oldDatesPivotIndex; i < oldDates.length; i++) {
        const oldDate = oldDates[i];
        if (datesMetadata[oldDate].repeating === false) {
          // One-off question
          currentDates.push(oldDate);
        } else {
          if (newDates.includes(oldDate) === false) {
            // Stale date
            staleDates.push(oldDate);
          }
        }
      }

      // Push all new dates into currentDates provided they aren't already there (this ensures one-off questions from oldDates aren't overwritten)
      // Update datesMetadata
      for (let i = 0; i < newDates.length; i++) {
        const newDate = newDates[i];
        if (currentDates.includes(newDate) === false) {
          currentDates.push(newDate);

          // Update datesMetadata
          datesMetadata[newDate] = {
            repeating: true,
          };
        }
      }
    } else {
      // One-off questions should be silently inserted into oldDates without disturbing existing data
      const oneOffQuestionDate = newDates[0];
      currentDates = [...oldDates, oneOffQuestionDate];
      datesMetadata[oneOffQuestionDate] = {
        repeating: false,
      };
    }

    // Sort currentDates by date field
    currentDates.sort((dateA, dateB) => {
      return stringToDate(dateA) - stringToDate(dateB);
    });

    // Prune stale dates from datesMetadata
    staleDates.forEach((staleDate) => {
      delete datesMetadata[staleDate];
    });

    // Update earliestDate
    let updatedEarliestDate = earliestDate;
    if (
      updatedEarliestDate === "" ||
      stringToDate(currentDates[0]) < stringToDate(updatedEarliestDate)
    ) {
      updatedEarliestDate = currentDates[0];
    }

    // Return schedule information in separate object
    const datesInfo = {
      currentDates: currentDates,
      staleDates: staleDates,
      datesMetadata: datesMetadata,
      earliestDate: updatedEarliestDate,
    };

    return datesInfo;
  };

  /**
   * Computes the set of scheduled dates with selectedDateIn and selectedDateOut.
   *
   * @returns dates: the set of scheduled dates
   *
   */
  const computeDates = () => {
    // Store date endpoints in proxy variables
    let trackedDate = selectedDateIn;
    let endDateDate = selectedDateOut;

    // Initialize dates
    let dates = [];
    dates.push(formatDateToString(trackedDate));
    // Walk through [selectedDateIn, endDate] interval with specified stride (weekly, monthly, or yearly)
    while (trackedDate < endDateDate) {
      if (repeatInterval === REPEAT_INTERVALS.WEEKLY) {
        trackedDate = new Date(
          trackedDate.getFullYear(),
          trackedDate.getMonth(),
          trackedDate.getDate() + 7
        );
        trackedDate.setHours(0, 0, 0, 0);
        if (dates.includes(formatDateToString(trackedDate)) === false) {
          dates.push(formatDateToString(trackedDate));
        }
      }
      if (repeatInterval === REPEAT_INTERVALS.MONTHLY) {
        trackedDate = new Date(
          trackedDate.getFullYear(),
          trackedDate.getMonth() + 1,
          trackedDate.getDate()
        );
        trackedDate.setHours(0, 0, 0, 0);
        dates.push(formatDateToString(trackedDate));
      }
      if (repeatInterval === REPEAT_INTERVALS.YEARLY) {
        trackedDate = new Date(
          trackedDate.getFullYear() + 1,
          trackedDate.getMonth(),
          trackedDate.getDate()
        );
        trackedDate.setHours(0, 0, 0, 0);
        dates.push(formatDateToString(trackedDate));
      }
    }

    // Guard against inherent off-by-one behavior of loop above by doing conditional deletion on last element in dates
    const finalDate = stringToDate(dates[dates.length - 1]);
    if (repeating === true && finalDate > selectedDateOut) {
      dates = dates.slice(0, -1);
    }

    return dates;
  };

  /**
   * Converts a string formatted "MM-DD-YYYY" to a Date object.
   *
   * @param {String} the date string
   *
   * @returns a Date object corresponding to the passed string
   */
  function stringToDate(dateStr) {
    const parts = dateStr.split("-");
    const month = parseInt(parts[0], 10) - 1; // Adjusting month to zero-index
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Create a Date Object
    const date = new Date(year, month, day);

    // Remove Time Information
    date.setHours(0, 0, 0, 0);

    return date;
  }

  /**
   * Removes the question from the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   */
  const removeQuestion = (targetQuestionId) => {
    if (setSelectedGroupId !== "") {
      // Create updated questions object, remove specified question
      const updatedQuestions = JSON.parse(JSON.stringify(input.questions));
      delete updatedQuestions[targetQuestionId];

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  if (!isOpen) {
    return;
  }

  return (
    <div className="PopupModal__container">
      <div className="PopupModal__background" />
      <div className="max-w-2xl rounded-lg text-center fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white z-[1000]">
        <div className="bg-[#f3f5f8] p-3 grid grid-cols-3 rounded-t-lg">
          <h1 className="font-semibold text-lg col-start-2">Create Question</h1>
          <button
            className="justify-self-end"
            onClick={() => {
              setIsOpen(false);
              close();
            }}
          >
            <FontAwesomeIcon icon={faXmark} className="text-[#495052]" />
          </button>
        </div>

        <div className="p-5">
          <div className="grid grid-cols-3 justify-items-start mb-1">
            <label htmlFor="group-select" className="text-xs text-gray-700">
              Group
            </label>
          </div>
          <div className="grid grid-cols-3 items-center">
            <select
              id="group-select"
              value={selectedGroupId ?? ""}
              onChange={(e) => {
                setSelectedGroupId(e.target.value);
                setSelectedTag("");
              }}
              className="select select-bordered border-[#ededed] bg-[#f3f5f7]"
            >
              <option value={""} disabled>
                Select
              </option>
              {groups.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
            <div
              className="ml-2 tooltip w-fit tooltip-right"
              data-tip="Select the group for which you would like to schedule a question"
            >
              <FontAwesomeIcon icon={faCircleInfo} className="text-[#3333cc]" />
            </div>
          </div>

          <div className="divider my-2" />

          {selectedGroupId ? (
            <>
              <div className="flex items-center mb-2">
                <div className="dropdown grow">
                  <input
                    type="text"
                    placeholder="Question"
                    value={searchedQuestion}
                    onChange={(e) => setSearchedQuestion(e.target.value)}
                    className="input bg-[#f3f5f7] border-[#ededed] mb-1"
                    tabIndex={0}
                  />
                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[1] menu p-2 shadow w-full bg-[#f3f5f7] rounded-lg"
                  >
                    {filteredQuestions.length === 0 && !searchedQuestion && (
                      <p className="text-sm text-gray-800">
                        No existing questions. Start typing to add a question...
                      </p>
                    )}
                    {searchedQuestion && (
                      <li>
                        <button
                          onClick={() => {
                            addQuestion(null, null, searchedQuestion);
                          }}
                        >
                          <b>+ Create New Question:</b> {searchedQuestion}
                        </button>
                      </li>
                    )}
                    {filteredQuestions.map(([questionId, { question }]) => (
                      <li key={questionId}>
                        <input
                          type="button"
                          value={question}
                          onClick={() => {
                            const sourceQId = questionId;
                            const displayedQuestion =
                              fullData[selectedGroupId].displayedQuestions[
                                sourceQId
                              ];
                            const questionsText = Object.values(
                              input.questions
                            ).map((obj) => obj.question);
                            const isDuplicate =
                              questionsText.reduce((count, question) => {
                                if (
                                  question.trim().toLowerCase() ===
                                  displayedQuestion.question
                                    .trim()
                                    .toLowerCase()
                                ) {
                                  count++;
                                }
                                return count;
                              }, 0) > 0;

                            if (isDuplicate) {
                              notification.error(
                                "Duplicate questions are not allowed."
                              );
                            } else {
                              // Add displayed question
                              setSelectedQuestion(question);

                              addQuestion(null, {
                                question: displayedQuestion.question,
                                options: displayedQuestion.options,
                                tags: displayedQuestion.tags,
                              });
                              // Reset forceCloseStatus
                              forceClose.current = {
                                ...forceClose.current,
                                forceCloseStatus: false,
                              };
                            }
                          }}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <select
                  id="tag-select"
                  value={selectedTag}
                  disabled={!selectedGroupId}
                  onChange={(e) => setSelectedTag(e.target.value)}
                  className="select select-bordered border-[#ededed] bg-[#f3f5f7] col-start-3"
                >
                  <option value="">None</option>
                  {tags.map((tag) => (
                    <option key={tag} value={tag}>
                      {tag}
                    </option>
                  ))}
                </select> */}
                <div
                  className="mx-2 tooltip w-fit tooltip-left"
                  data-tip="Help"
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className="text-[#3333cc]"
                  />
                </div>
              </div>
              <div className="max-h-80 overflow-y-scroll overflow-x-hidden flex flex-col gap-y-8 px-1 py-4">
                {input.questions &&
                  Object.keys(input.questions).map((tempQuestionId, index) => (
                    <div
                      key={tempQuestionId}
                      className="rounded-lg border border-[#ededed] p-2"
                    >
                      <h2>{input.questions[tempQuestionId].title}</h2>
                      <div className="flex flex-col gap-y-2">
                        <div className="flex items-center gap-x-4">
                          <textarea
                            id={`question-input-${tempQuestionId}`}
                            className="question-input"
                            value={input.questions[tempQuestionId].question}
                            placeholder="Enter question"
                            onInput={(event) => {
                              // Run duplicate check
                              const questionsText = Object.values(
                                input.questions
                              ).map((obj) => obj.question);
                              const result = questionsText.reduce(
                                (acc, question, i) => {
                                  if (
                                    question.trim().toLowerCase() ===
                                    event.target.value.trim().toLowerCase()
                                  ) {
                                    acc.idx.push(i);
                                    acc.count++;
                                  }
                                  return acc;
                                },
                                { count: 0, idx: [] }
                              );
                              const isDuplicate =
                                result.count > 0 && result.idx[0] !== index;
                              if (
                                isDuplicate &&
                                event.target.value.trim() !== ""
                              ) {
                                event.target.style.setProperty(
                                  "border-bottom",
                                  "2px solid rgb(255, 0, 0)",
                                  "important"
                                );
                              } else {
                                event.target.style.removeProperty(
                                  "border-bottom"
                                );
                              }

                              // Create updated question object
                              const updatedQuestions = {
                                ...input.questions,
                                [tempQuestionId]: {
                                  ...input.questions[tempQuestionId],
                                  question: event.target.value,
                                  dates: [formatDateToString(selectedDateIn)],
                                  earliestDate:
                                    formatDateToString(selectedDateIn),
                                },
                              };

                              // Update input
                              setInput({
                                ...input,
                                questions: updatedQuestions,
                              });

                              // Reset forceCloseStatus
                              forceClose.current.forceCloseStatus = false;
                            }}
                            onBlur={(event) => {
                              // Run duplicate check
                              const questionsText = Object.values(
                                input.questions
                              ).map((obj) => obj.question);
                              const isDuplicate =
                                questionsText.reduce((count, question) => {
                                  if (
                                    question.trim().toLowerCase() ===
                                    event.target.value.trim().toLowerCase()
                                  ) {
                                    count++;
                                  }
                                  return count;
                                }, 0) > 1;
                              // Remove question from input text box when user clicks away if duplicate
                              if (isDuplicate) {
                                // Create updated questions object
                                const updatedQuestions = {
                                  ...input.questions,
                                  [tempQuestionId]: {
                                    ...input.questions[tempQuestionId],
                                    question: "",
                                  },
                                };

                                // Update input
                                setInput({
                                  ...input,
                                  questions: updatedQuestions,
                                });

                                event.target.style.removeProperty(
                                  "border-bottom"
                                );
                              }
                            }}
                            style={{
                              resize: "none",
                              overflow: "hidden",
                              maxHeight: "35px",
                            }}
                          />

                          <button className="btn rounded-full px-3 h-1 border-none shadow-none text-gray-400 hover:text-gray-800">
                            <FontAwesomeIcon
                              icon={faXmark}
                              onClick={() => removeQuestion(tempQuestionId)}
                            />
                          </button>
                        </div>
                        <div>
                          {input.questions[tempQuestionId].options.map(
                            (option, optIndex) => (
                              <div
                                className="option-row-admin-modal"
                                key={optIndex}
                              >
                                <textarea
                                  id={`option-input-${tempQuestionId}-${optIndex}`}
                                  className="option-input"
                                  type="text"
                                  value={option}
                                  placeholder="Enter option"
                                  onChange={(event) => {
                                    // Change border color to red if duplicate
                                    const result = input.questions[
                                      tempQuestionId
                                    ].options.reduce(
                                      (acc, opt, i) => {
                                        if (
                                          opt.trim().toLowerCase() ===
                                          event.target.value
                                            .trim()
                                            .toLowerCase()
                                        ) {
                                          acc.idx.push(i);
                                          acc.count++;
                                        }
                                        return acc;
                                      },
                                      { count: 0, idx: [] }
                                    );
                                    const isDuplicate =
                                      result.count > 0 &&
                                      result.idx[0] !== optIndex;

                                    if (
                                      isDuplicate &&
                                      event.target.value.trim() !== ""
                                    ) {
                                      event.target.style.setProperty(
                                        "border-bottom",
                                        "2px solid rgb(255, 0, 0)",
                                        "important"
                                      );
                                    } else {
                                      event.target.style.removeProperty(
                                        "border-bottom"
                                      );
                                    }

                                    // Unconditionally update input with user input
                                    const updatedOptions = JSON.parse(
                                      JSON.stringify(
                                        input.questions[tempQuestionId].options
                                      )
                                    );
                                    updatedOptions[optIndex] =
                                      event.target.value;
                                    const updatedQuestions = {
                                      ...input.questions,
                                      [tempQuestionId]: {
                                        ...input.questions[tempQuestionId],
                                        options: updatedOptions,
                                      },
                                    };
                                    setInput({
                                      ...input,
                                      questions: updatedQuestions,
                                    });

                                    // Reset forceCloseStatus
                                    forceClose.current.forceCloseStatus = false;
                                  }}
                                  // Clear duplicate option input text boxes when user clicks away
                                  onBlur={(event) => {
                                    const isDuplicate =
                                      input.questions[
                                        tempQuestionId
                                      ].options.reduce((count, option) => {
                                        if (
                                          option.trim().toLowerCase() ===
                                          event.target.value
                                            .trim()
                                            .toLowerCase()
                                        ) {
                                          count++;
                                        }
                                        return count;
                                      }, 0) > 1;
                                    if (isDuplicate) {
                                      const updatedOptions = JSON.parse(
                                        JSON.stringify(
                                          input.questions[tempQuestionId]
                                            .options
                                        )
                                      );
                                      updatedOptions[optIndex] = "";
                                      const updatedQuestions = {
                                        ...input.questions,
                                        [tempQuestionId]: {
                                          ...input.questions[tempQuestionId],
                                          options: updatedOptions,
                                        },
                                      };
                                      setInput({
                                        ...input,
                                        questions: updatedQuestions,
                                      });
                                      event.target.style.removeProperty(
                                        "border-bottom"
                                      );
                                    }

                                    // Reset forceCloseStatus
                                    forceClose.current.forceCloseStatus = false;
                                  }}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      addOption(
                                        event,
                                        tempQuestionId,
                                        optIndex,
                                        event.key
                                      );
                                    }
                                  }}
                                  style={{
                                    resize: "none",
                                    overflow: "hidden",
                                    maxHeight: "35px",
                                  }}
                                />
                                <IconButton
                                  icon={faX}
                                  backgroundColor="rgb(255, 255, 255)"
                                  className="option-remove-button"
                                  style={{
                                    backgroundColor: "red",
                                    fontSize: "10px",
                                    color: "white",
                                  }} // Add this style to make the icon red
                                  onClick={() => {
                                    removeOption(tempQuestionId, optIndex);
                                  }}
                                ></IconButton>
                              </div>
                            )
                          )}
                        </div>
                        <div
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <p
                            className="AdminModalScheduler__add-option-text"
                            onClick={(event) =>
                              addOption(event, tempQuestionId)
                            }
                          >
                            Add Option
                          </p>{" "}
                        </div>
                        <div className="mt-4 text-left">
                          <h3 className="text-sm mb-2">Tags</h3>
                          <div className="flex gap-x-2 gap-y-1 items-center flex-wrap">
                            {input.questions[tempQuestionId]?.tags.map(
                              (tag) => (
                                <span
                                  key={tag}
                                  className="badge gap-2 rounded-md"
                                >
                                  {tag}
                                  <button
                                    onClick={() =>
                                      removeTag(tempQuestionId, tag)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faX} size="xs" />
                                  </button>
                                </span>
                              )
                            )}
                          </div>
                          <div className="dropdown dropdown-right">
                            <div
                              tabIndex={0}
                              role="button"
                              className="badge rounded-md hover:bg-gray-100 m-1 ml-0"
                            >
                              + Add/Create
                            </div>
                            <div
                              tabIndex={0}
                              className="bg-gray-50 dropdown-content z-[1] rounded-lg w-60 p-1 shadow"
                            >
                              <input
                                type="text"
                                placeholder="Start typing..."
                                value={searchedTag}
                                className="input input-sm"
                                onChange={(e) => setSearchedTag(e.target.value)}
                              />
                              <ul className="menu menu-sm px-0">
                                {searchedTag && (
                                  <li>
                                    <button
                                      onClick={() =>
                                        addTag(tempQuestionId, searchedTag)
                                      }
                                    >
                                      <b className="text-sm">+ Create:</b>{" "}
                                      {searchedTag}
                                    </button>
                                  </li>
                                )}
                                {classesDetails[selectedGroupId].tags?.length >
                                0 ? (
                                  classesDetails[selectedGroupId].tags
                                    .filter(
                                      (tag) =>
                                        !searchedTag ||
                                        tag
                                          .toLowerCase()
                                          .includes(searchedTag.toLowerCase())
                                    )
                                    .map((tag) => (
                                      <li key={tag}>
                                        <button
                                          onClick={() =>
                                            addTag(tempQuestionId, tag)
                                          }
                                        >
                                          {tag}
                                        </button>
                                      </li>
                                    ))
                                ) : (
                                  <p className="text-xs text-gray-500">
                                    No existing tags. Start typing to create a
                                    tag.
                                  </p>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <p className="text-gray-600">
              Select a group to start adding questions.
            </p>
          )}

          <div className="divider my-2" />

          <div className="grid grid-rows-2 grid-cols-3 gap-x-2 items-center mb-4 justify-items-start ">
            <label
              htmlFor="start-date"
              className="text-xs text-gray-700 self-end"
            >
              {repeating && "Start "}Date
            </label>
            {repeating && (
              <label
                htmlFor="end-date"
                className="text-xs text-gray-700 self-end"
              >
                End Date
              </label>
            )}
            <DateInput
              id="start-date"
              className="bg-[#f3f5f7] row-start-2 col-start-1"
              value={formatDateToString(selectedDateIn)}
              onChange={(event) => {
                const dateStr = event.target.value;
                const parts = dateStr.split("-");
                // Note: Months are 0-indexed in JavaScript (0 for January, 1 for February, etc.)
                const date = new Date(parts[0], parts[1] - 1, parts[2]);
                date.setHours(0, 0, 0, 0);
                setSelectedDateIn(date);
              }}
            />
            {repeating && (
              <DateInput
                id="end-date"
                type="date"
                className="bg-[#f3f5f7] row-start-2 col-start-2"
                value={formatDateToString(selectedDateOut)}
                onChange={(event) => {
                  const dateStr = event.target.value;
                  const parts = dateStr.split("-");
                  // Note: Months are 0-indexed in JavaScript (0 for January, 1 for February, etc.)
                  const date = new Date(parts[0], parts[1] - 1, parts[2]);
                  date.setHours(0, 0, 0, 0);
                  setSelectedDateOut(date);
                }}
              />
            )}
            <button
              className={clsx(
                "btn rounded-lg border-none drop-shadow-md text-[#3333cc] row-start-2 col-start-3 w-full",
                {
                  "text-[#3333cc]/50": !repeating,
                }
              )}
              onClick={() => setRepeating((r) => !r)}
            >
              <FontAwesomeIcon icon={faRepeat} />
              REPEATING
            </button>
          </div>

          {repeating && (
            <div
              role="tablist"
              className="tabs tabs-boxed mb-8 border border-[#ededed] bg-[#f3f5f7] font-medium text-[#595a5a] max-w-sm mx-auto"
            >
              {Object.values(REPEAT_INTERVALS).map((INTERVAL) => (
                <button
                  key={INTERVAL}
                  role="tab"
                  className={clsx("tab", {
                    "tab-active bg-[#6a757f] text-white":
                      repeatInterval === INTERVAL,
                  })}
                  onClick={() => setRepeatInterval(INTERVAL)}
                >
                  {INTERVAL}
                </button>
              ))}
            </div>
          )}

          <div className="flex justify-end">
            <button
              className="btn rounded-full border-none bg-[#3333cc] text-white btn-sm"
              onClick={submit}
            >
              CREATE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
