import BarChart from "../BarChart/BarChart";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useNotification } from "../../providers/NotificationProvider";

import "./AdminDisplay.css";

const AdminDisplay = ({
  selectedData,
  selectedDate,
  setSelectedDate,
  lineStruct,
  chartOptions,
  lineProperties,
}) => {
  /**
   * SelecetedData {} : the data for the selected question
   * selectedDate (str) in format XX-XX-XXXX and representing the selected date
   * setSelectedDate (func) function to change the selected date in the parent component
   * lineStruct {}: the datastructure controlling the line plot passed in from the parent component. Contains data compiled from selected question data
   * chartOptions {} : the options passed into the line plot
   *
   */

  const notification = useNotification();

  const [chartType, setChartType] = useState("bar");

  const dateKeys = Object.keys(selectedData["responseData"]);

  //reset the graph type to bar to ensure the default when switching question
  useEffect(() => {
    setChartType("bar");
  }, [selectedData]);

  /*
  Function to check whether there exists data for more than one date for the 
  selected question before switching to the line plot. There is no point to have
  a line plot if there is no data for more than one date
  */
  const setLine = () => {
    if (dateKeys.length > 1) {
      setChartType("time");
    } else {
      notification.info(
        "data exists for only one date... cannot view data over time"
      );
    }
  };

  return (
    <div className="AdminDisplay__ctn">
      <div className="AdminDisplay__chart-ctn">
        {selectedDate &&
          selectedData["responseData"][selectedDate]["responseTallies"] !==
            null && (
            <div>
              {chartType === "bar" && (
                <BarChart
                  labels={selectedData?.options}
                  data={
                    selectedData["responseData"][selectedDate][
                      "responseTallies"
                    ]
                  }
                  title={`${selectedData?.question}`}
                />
              )}
              {chartType === "time" && (
                <Line options={chartOptions} data={lineStruct} />
              )}

              <div>
                {chartType === "bar" && (
                  <h3>
                    Participation Percentage:{" "}
                    {(
                      (selectedData["responseData"][selectedDate]["responses"] /
                        selectedData["responseData"][selectedDate][
                          "enrolled"
                        ]) *
                      100
                    ).toFixed(2)}
                    %
                  </h3>
                )}
              </div>
            </div>
          )}
      </div>

      <div className="AdminDisplay__chart-settings">
        <div
          className="AdminDisplay__chart-toggles"
          style={{ marginBottom: "10px" }}
        >
          <button
            className={chartType === "bar" ? "cta-btn" : "admin-btn"}
            onClick={() => setChartType("bar")}
          >
            By Day
          </button>
          <button
            className={chartType === "time" ? "cta-btn" : "admin-btn"}
            onClick={() => setLine()}
          >
            Over Time
          </button>
        </div>
        <div className="AdminDisplay__right">
          {chartType === "bar" &&
            // Start the mapping directly inside the parentheses
            dateKeys.map((date) => (
              <button
                key={date}
                className={selectedDate === date ? "cta-btn" : "admin-btn"}
                onClick={() => setSelectedDate(date)}
              >
                {date}
              </button>
            ))}
          {chartType === "time" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "60%",
              }}
            >
              <h2>Legend</h2>

              {selectedData?.options.map((option, i) => (
                <div
                  key={option}
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    gap: "5px",
                    justifyContent: "space-between",
                    alignItems: "center", // Align elements to the top
                  }}
                >
                  <h3>{option}</h3>
                  <div
                    className="option-circle"
                    style={{
                      width: "20px",
                      height: "10px",
                      borderRadius: "2px",
                      backgroundColor: lineProperties[i]?.backgroundColor,
                    }}
                  ></div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDisplay;
