import { Link } from "react-router-dom";

import { useFirestore } from "../../providers/FirestoreProvider";
import "./GroupCard.css";

export const routeOptions = {
  ANALYTICS: "analytics",
  DASHBOARD: "dashboard",
};

const GroupCard = ({ groupData, minimal = false }) => {
  const firestore = useFirestore();


  return (
    <Link
      to={`/dashboardgroup/${firestore.userData?.universityId}/${groupData.id}`}
      className={
        minimal ? "GroupCard__card GroupCard__card-minimal" : "GroupCard__card"
      }
      groupData={groupData}
    >
      <div className="GroupCard__layout">
        <div className="GroupCard__class-info">
          <div>
            <h3>{groupData?.name}</h3>
            {!minimal && <p>{groupData?.title}</p>}
          </div>
        </div>

        {!minimal && (
          <div className="GroupCard__join-code">
            Join Code: {groupData?.joinCode}
          </div>
        )}
      </div>
    </Link>
  );
};

export default GroupCard;
