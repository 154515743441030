import React from "react";
import { Link } from "react-router-dom";

import { useFirestore } from "../../providers/FirestoreProvider";
import "./ScheduleCard.css";

const ScheduleCard = ({ classData, showGoButton = false }) => {
  const firestore = useFirestore();

  const getClassStartTime = () => {
    const now = new Date();

    const startTime = classData.times[now.getDay()].startTime.toDate();

    const hour = startTime.getHours();
    const minute = startTime.getMinutes();
    const suffix = hour >= 12 ? "pm" : "am";

    let minutesUntilClass =
      hour * 60 + minute - (now.getHours() * 60 + now.getMinutes());

    if (minutesUntilClass <= 0) {
      return "Now";
    } else if (minutesUntilClass <= 15) {
      return `In ${minutesUntilClass} min`;
    }

    return `At ${((hour + 11) % 12) + 1}:${String(minute).padStart(
      2,
      "0"
    )} ${suffix}`;
  };

  return (
    <div className="ScheduleCard__container">
      <div className="ScheduleCard__header">
        <h3>{classData.name}</h3>
        <p>{getClassStartTime()}</p>
      </div>

      {showGoButton && (
        <Link
          className="ScheduleCard__go-btn"
          to={`/dashboard/${firestore.userData?.universityId}/${classData.id}`}
        >
          <h3>Go to Class</h3>
        </Link>
      )}
    </div>
  );
};

export default ScheduleCard;
