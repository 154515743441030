import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import "./ClassCard.css";

export const routeOptions = {
  ANALYTICS: "analytics",
  DASHBOARD: "dashboard",
};

const ClassCard = ({
  classData,
  minimal = false,
  route = routeOptions.DASHBOARD,
}) => {
  const firestore = useFirestore();
  const notification = useNotification();

  const [teacherName, setTeacherName] = useState("");

  /**
   * Get class data
   */
  useEffect(() => {
    let isMounted = true;

    if (!classData?.teacherUID) {
      return;
    }

    firestore
      .getDocument(`users/${classData.teacherUID}`)
      .then((doc) => {
        if (isMounted) {
          const teacher = firestore.docData(doc);

          setTeacherName(`${teacher.firstName} ${teacher.lastName}`);
        }
      })
      .catch(() => {
        notification.error("Error retrieving data");
      });

    return () => {
      isMounted = false;
    };
  }, [notification, firestore, classData?.teacherUID]);

  /**
   * Convert a number from 0-6 to a single letter week day abbreviation
   *
   * @param {Number} number number from 0-6 to convert to a weekday
   * @returns {String} abbreviated weekday
   */
  const getWeekDayFromNumber = (number) => {
    const weekDays = ["U", "M", "T", "W", "R", "F", "S"];

    return weekDays[number % weekDays.length];
  };

  return (
    <Link
      to={`/${route}/${firestore.userData?.universityId}/${classData.id}`}
      className={
        minimal ? "ClassCard__card ClassCard__card-minimal" : "ClassCard__card"
      }
    >
      <div className="ClassCard__layout">
        <div className="ClassCard__class-info">
          <div>
            <h3>{classData?.name}</h3>
            {!minimal && <p>{classData?.title}</p>}
          </div>

          <div className="ClassCard__text-right">
            <p>{teacherName}</p>
            {!minimal && (
              <p>
                {Object.keys(classData?.times).map((day, i) => (
                  <span key={i}>{getWeekDayFromNumber(day)}</span>
                ))}
              </p>
            )}
          </div>
        </div>

        {!minimal && (
          <div className="ClassCard__join-code">
            Join Code: {classData?.joinCode}
          </div>
        )}
      </div>
    </Link>
  );
};

export default ClassCard;
