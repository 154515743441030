import * as Accordion from "@radix-ui/react-accordion";

import { responseConfig } from "../TopicDisplay/TopicDisplay";
import "./TopicAnalyticsAccordion.css";

const TopicAnalyticsAccordion = ({ lectureSummaries }) => {
  const topics = [];

  for (const [id, summary] of Object.entries(lectureSummaries)) {
    for (const [topic, understandingCounts] of Object.entries(
      summary.topicUnderstandings
    )) {
      topics.push({
        date: id.replaceAll("-", "/"),
        topic,
        understandingCounts,
        participation: Math.round(summary.participation * 100),
      });
    }
  }

  topics.sort((a, b) => {
    const [aMonth, aDay, aYear] = a.date.split("/");
    const [bMonth, bDay, bYear] = b.date.split("/");

    const aDate = new Date(Number(aYear), Number(aMonth) - 1, Number(aDay));
    const bDate = new Date(Number(bYear), Number(bMonth) - 1, Number(bDay));

    return aDate - bDate;
  });

  return (
    <Accordion.Root
      type="single"
      collapsible
      className="TopicAnalyticsAccordion__accordion-root"
    >
      {topics.map(({ date, topic, understandingCounts, participation }) => (
        <Accordion.Item value={`${date}-${topic}`} key={`${date}-${topic}`}>
          <Accordion.Header className="TopicAnalyticsAccordion__accordion-header">
            <Accordion.Trigger className="TopicAnalyticsAccordion__accordion-trigger">
              <h2>{topic}</h2>
              <p>{date}</p>
            </Accordion.Trigger>
          </Accordion.Header>
          <Accordion.Content className="TopicAnalyticsAccordion__accordion-content">
            <div className="TopicAnalyticsAccordion__participation">
              <h3>Participation:</h3>
              <p>{participation}%</p>
            </div>

            <hr className="TopicAnalyticsAccordion__accordion-content-divider" />

            <div className="TopicAnalyticsAccordion__understanding">
              <h3>Understanding</h3>
              <div>
                <p>
                  {responseConfig.understanding.options.Lost}:{" "}
                  {understandingCounts[0]}
                </p>
                <p>
                  {responseConfig.understanding.options.Confused}:{" "}
                  {understandingCounts[1]}
                </p>
                <p>
                  {responseConfig.understanding.options.Almost}:{" "}
                  {understandingCounts[2]}
                </p>
                <p>
                  {responseConfig.understanding.options["Got It"]}:{" "}
                  {understandingCounts[3]}
                </p>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
};

export default TopicAnalyticsAccordion;
