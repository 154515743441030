import React, { useEffect, useState, useRef } from "react";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import "./ClassDateModal.css";

//components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faChevronDown, faChevronRight, faCircleInfo} from "@fortawesome/free-solid-svg-icons";
import PopupModal from "../PopupModal/PopupModal";
import Select, { components } from "react-select";
import IconButton from "../IconButton/IconButton";
import { Tooltip } from "react-tooltip";

const ClassDateModal = ({
  isOpen,
  setIsOpen,
  fullData,
  setParentData,
  classesDetails,
  selectedDateIn,
  selectedClass,
  selectedClassData,
}) => {
  /**
   * isOpen (boolean) : variable that states whether the modal is open or not
   * setIsOpen (func) : variable to control whether the modal is open or not
   * fullData (Object): the full data passed in from the main calendar component. Contains all questions and topics scheduled for a class
   * setParentData (func) : sets the overall data for the entire calendar
   * classesDetails (Object) : contains class information such as the title, name, and times (keys are the doc id for each given class in the db)
   * selectedDateIn (Date) : the date of the currently selected class
   * selectedClass (string) : the doc id of the current selected class
   * selectedClassData (Object) : object that contains the selected topics and questions for a scheduled class on a certain date
   */

  /* Mount-time Initializations */

  /* Utilities */
  const firestore = useFirestore();
  const notification = useNotification();

  /* Inputs */
  const [input, setInput] = useState({questions: {}});

  /* UI State Management */
  const forceClose = useRef({
    forceCloseStatus: true,
  });

  /* Constants */
  const MAX_CHARS_PER_LINE_TOPICS = 36;
  const MAX_CHARS_PER_LINE_QUESTIONS = 38;
  const MAX_CHARS_PER_LINE_OPTIONS = 36;

  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );
  console.log("START ClassDateModal.jsx");
  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );

  console.log("fullData");
  console.log(fullData);

  console.log("selectedClassData");
  console.log(selectedClassData);

  console.log("selectedClass");
  console.log(selectedClass);

  console.log("forceClose");
  console.log(forceClose);

  console.log("input");
  console.log(input);

  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );
  console.log("END ClassDateModal.jsx");
  console.log(
    "------------------------------------------------------------------------------------------------------------------------"
  );

  /**
   * Builds questionsStruct, a hashmap <K:question <str>, V: qid <str>>.
   * It is used by the dropdown menu to display suggested questions.
   *
   * @param None.
   *
   */
  const buildQuestionsStruct = () => {
    // Return empty object if user hasn't selected a class yet
    if (selectedClass === null) {
      return {};
    }

    // Otherwise, build and return questionsStruct
    return Object.keys(fullData[selectedClass].displayedQuestions).map(
      (qid) => ({
        value: qid,
        label: fullData[selectedClass].displayedQuestions[qid].question,
      })
    );
  };

  const questionsStruct = buildQuestionsStruct();

  /**
   * Initializes state variables derived from selectedClassData (which is in turn derived from fullData).
   * Also initializes input for particular modal.
   * Runs whenever the modal is opened by the user.
   */
  useEffect(() => {
    // Run only when modal is being opened
    if (isOpen === true) {
      /* Initialize UI */
      const updatedInput = {
                                questions: {}
                           };
      updatedInput.topics = [...selectedClassData.topics];
      const questionIds = Object.keys(selectedClassData.questions);
      // Ensure qids in input are always index values, and never true DB IDs
      if (questionIds.length > 0) {
        questionIds.forEach((qid, index) => {
          updatedInput.questions[index] = selectedClassData.questions[qid];
        });
      }
      // Set input
      setInput({
        ...updatedInput,
      });

      // Dynamically resize input text boxes based on contents
      const timer = setTimeout(() => {
        // Resize topic text boxes
        for (const index in updatedInput.topics) {
          resizeTextarea(`#topic-input-${index}`, MAX_CHARS_PER_LINE_TOPICS);
        }
        // Resize question / option text boxes
        for (const tempQuestionId in updatedInput.questions) {
          resizeTextarea(
            `#question-input-${tempQuestionId}`,
            MAX_CHARS_PER_LINE_QUESTIONS
          );
          const currentQuestion = updatedInput.questions[tempQuestionId];
          const currentOptions = currentQuestion.options;
          for (const optionIndex in currentOptions) {
            resizeTextarea(
              `#option-input-${tempQuestionId}-${optionIndex}`,
              MAX_CHARS_PER_LINE_OPTIONS
            );
          }
        }
      }, 0);

      // Set forceCloseStatus to true
      forceClose.current.forceCloseStatus = true;

      // Clean up timer
      return () => clearTimeout(timer);
    }
  }, [isOpen, fullData, selectedClass, selectedClassData]);

  /**
   * Adds an empty input field on the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {event} event
   * @param {key}   signals whether user used Enter key to create topic
   */
  const addTopic = (event, index = null, key = null) => {
    event.preventDefault();

    if (selectedClass !== "") {
      // Add empty topic field to input.topics
      const updatedTopics = [...input.topics];
      if (index === null) {
        updatedTopics.push("");
      } else {
        updatedTopics.splice(index + 1, 0, "");
      }
      setInput({
        ...input,
        topics: updatedTopics,
      });

      if (key === "Enter") {
        // Move cursor to next topic input text box if user added topic with Enter key
        setTimeout(() => {
          const nextInput = document.querySelector(`#topic-input-${index + 1}`);
          if (nextInput) {
            nextInput.focus();
          }
        }, 5);
      }
    }
  };

  /**
   * Removes the specified topic from UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {event} event
   */
  const removeTopic = (targetIdx) => {
    if (selectedClass !== "") {
      // Create updated topics array
      const updatedTopics = JSON.parse(JSON.stringify(input.topics));

      // Remove topic at targetIdx
      updatedTopics.splice(targetIdx, 1);

      // Update input
      setInput({
        ...input,
        topics: updatedTopics,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  /**
   * Adds an empty question to the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {event} event
   */
  const addQuestion = (event = null, questionObj = null) => {
    if (event !== null) {
      event.preventDefault();
    }

    if (selectedClass !== "") {
      const date = formatDateToString(selectedDateIn);
      // Create blank questionObj, if null
      if (questionObj === null) {
        questionObj = {
          question: "",
          options: [],
          dates: [date],
          earliestDate: formatDateToString(selectedDateIn),
          type: null,
        };
      }

      // Add questionObj to bottom of UI
      const tempQuestionIds = Object.keys(input.questions) || [];
      let nextTempQuestionId = null;
      if (tempQuestionIds.length === 0) {
        nextTempQuestionId = 0;
      } else {
        nextTempQuestionId = parseInt(tempQuestionIds[tempQuestionIds.length - 1]) + 1;
      }

      const updatedQuestions = {
        ...input.questions,
        [nextTempQuestionId]: questionObj,
      };

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;

      if (event === null) {
        setTimeout(() => {
          // Perform dynamic resizing on target textarea element for displayed questions
          resizeTextarea(
            `#question-input-${nextTempQuestionId}`,
            MAX_CHARS_PER_LINE_QUESTIONS
          );
          for (const oi in questionObj.options) {
            resizeTextarea(
              `#option-input-${nextTempQuestionId}-${oi}`,
              MAX_CHARS_PER_LINE_OPTIONS
            );
          }
        }, 10);
      }
    } else {
      notification.warn("Select a target group before adding questions.");
    }
  };

  /**
   * Dynamically resizes given textarea based on specified maximum characters per length.
   *
   * @param {textareaId} the textarea ID
   * @param {maxCharsPerLine} the maximum number of characters a line can hold
   */
  const resizeTextarea = (textareaId, maxCharsPerLine) => {
    // Get textarea element
    let textarea = null;
    try {
      textarea = document.querySelector(textareaId);
    }
    catch (error) {
      return;
    }

    // Resize element according to maxCharsPerLine
    if (
      textarea.value.length >= maxCharsPerLine ||
      textarea.value.includes("\n")
    ) {
      textarea.style.height = "auto";
      textarea.style.maxHeight = "none"; // Remove maxHeight restriction
      textarea.style.height = textarea.scrollHeight + "px";
    }
    if (
      textarea.value.length < maxCharsPerLine &&
      textarea.value.includes("\n") === false
    ) {
      textarea.style.maxHeight = "35px";
    }
  };

  /**
   * Removes the specified question from the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {targetQId} the target qid
   */
  const removeQuestion = (targetQId) => {
    if (selectedClass !== "") {
      // Create updated questions object, remove specified question
      const updatedQuestions = JSON.parse(JSON.stringify(input.questions));
      delete updatedQuestions[targetQId];

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  /**
   * Adds an empty option field under the specified question on the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {event} event
   * @param {qid}   the qid
   * @param {key}   signals whether user used Enter key to create topic
   */
  const addOption = (event, qid, index = null, key = null) => {
    event.preventDefault();

    if (selectedClass !== "") {
      // Add empty option field to input.questions[qid]
      const updatedOptions = [...input.questions[qid].options];
      if (index === null) {
        updatedOptions.push("");
      } else {
        updatedOptions.splice(index + 1, 0, "");
      }
      const updatedQuestions = {
        ...input.questions,
        [qid]: {
          ...input.questions[qid],
          options: updatedOptions,
        },
      };
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      if (key === "Enter") {
        // Move cursor to next option input text box if user added option with Enter key
        setTimeout(() => {
          const nextInput = document.querySelector(
            `#option-input-${qid}-${index + 1}`
          );
          if (nextInput) {
            nextInput.focus();
          }
        }, 5);
      }
    }
  };

  /**
   * Removes the specified option from specified question from the UI.
   * Changes will be tracked and stored in input.
   * All data will be copied to internal data models
   * and committed to the database at time of submission.
   *
   * @param {targetQId} the target qid
   * @param {targetIdx} the target option index
   */
  const removeOption = (targetQId, targetIdx) => {
    if (selectedClass !== "") {
      // Create updated option set for passed question
      const updatedOptions = JSON.parse(
        JSON.stringify(input.questions[targetQId].options)
      );
      updatedOptions.splice(targetIdx, 1);

      // Create updated question object
      const updatedQuestions = {
        ...input.questions,
        [targetQId]: {
          ...input.questions[targetQId],
          options: updatedOptions,
        },
      };

      // Update input
      setInput({
        ...input,
        questions: updatedQuestions,
      });

      // Reset forceCloseStatus
      forceClose.current.forceCloseStatus = false;
    }
  };

  /**
   * Closes modal.
   * Warns user against closing without submitting changes.
   *
   * @param None
   */
  const close = () => {
    // Warn user that unsaved changes will be discarded if modal is closed without submitting survey
    if (forceClose.current.forceCloseStatus === false) {
      notification.warn(
        "Submit changes before closing. Click again to override."
      );
      forceClose.current.forceCloseStatus = true;
      setIsOpen(true);
    } else {
      // Close if user overrides warning
      setIsOpen(false);
    }
  };

  /**
   * Submits topics and questions to database and writes to fullData for in-session persistence.
   *
   * @param {event} the event
   */
  const submit = async (event = null) => {
    event.preventDefault();

    // Create deepcopy of fullData
    const newFullData = JSON.parse(JSON.stringify(fullData));

    /**
     * Topics
     *
     * {Update internal data model}
     * Filter input.topics to exclude blank entries and topics already in fullData && database
     * Write input.topics to fullData[selectedClass][selectedDateIn].topics
     *
     * {Update database}
     * Write updated topics to =>lectures / *lectureId
     */

    // Update internal data model
    const updatedTopics = input.topics.filter(
      (topic) =>
        topic.trim() !== "" &&
        selectedClassData.topics.includes(topic.trim()) === false
    );
    newFullData[selectedClass][formatDateToString(selectedDateIn)].topics =
      updatedTopics;

    // Update database
    firestore.updateDoc(
      `universities/${
        firestore.userData.universityId
      }/classes/${selectedClass}/lectures/${formatDateToString(
        selectedDateIn
      )}`,
      {
        topics: updatedTopics,
      },
      { merge: true }
    );

    /**
     * Questions
     *
     */
    const updatedQuestions = {};
    for (const questionId in input.questions) {
      const question = input.questions[questionId];

      // Disallow empty questions or empty option sets
      if (
        question.question.trim() === "" ||
        question.options.every((opt) => opt.trim() === "")
      ) {
        notification.error("Incomplete questions are not allowed.");
        return;
      }

      // Remove all blank options from question option set
      const updatedOptions = question.options.filter(
        (opt) => opt.trim() !== ""
      );
      question.options = updatedOptions;

      // Search for match in displayedQuestions
      let matchingDisplayedQuestionId = null;
      for (const displayedQuestionId in fullData[selectedClass]
        .displayedQuestions) {
        const displayedQuestion =
          fullData[selectedClass].displayedQuestions[displayedQuestionId];

        if (
          question.question.toLowerCase().trim() ===
          displayedQuestion.question.toLowerCase()
        ) {
          // Potential match, compare option sets
          if (question.options.length === displayedQuestion.options.length) {
            const sortedQuestionOptions = [...question.options].sort();
            const sortedDisplayedQuestionOptions = [
              ...displayedQuestion.options,
            ].sort();
            // Option sets match perfectly, matching displayed question found so get ID
            if (
              sortedQuestionOptions.every(
                (value, index) =>
                  value.toLowerCase() ===
                  sortedDisplayedQuestionOptions[index].toLowerCase()
              )
            ) {
              matchingDisplayedQuestionId = displayedQuestionId; // db id + idx
              break;
            }
          }
        }
      }

      if (matchingDisplayedQuestionId !== null) {
        // Get deepcopy of matchingDisplayedQuestion to avoid prematurely mutating data model and its derived states
        const matchingDisplayedQuestion = {
          question:
            fullData[selectedClass].displayedQuestions[
              matchingDisplayedQuestionId
            ].question,
          options: JSON.parse(
            JSON.stringify(
              fullData[selectedClass].displayedQuestions[
                matchingDisplayedQuestionId
              ].options
            )
          ),
          dates: JSON.parse(
            JSON.stringify(
              fullData[selectedClass].displayedQuestions[
                matchingDisplayedQuestionId
              ].dates
            )
          ),
          earliestDate:
            fullData[selectedClass].displayedQuestions[
              matchingDisplayedQuestionId
            ].earliestDate,
        };
        const matchingDisplayedQuestionType =
          fullData[selectedClass].displayedQuestions[
            matchingDisplayedQuestionId
          ].type; // "global" or "local"
        /**
         * if questionType === "local":
         *  Get local qid from matchingDisplayedQuestion by substringing characters [0, 20]
         *
         *  {Update matchingDisplayedQuestion deepcopy}
         *    @dates
         *    @earliestDate
         *
         *  {Update internal data model}
         *    Copy updated matchingDisplayedQuestion:
         *      @fullData [selectedClass][selectedDateIn].questions
         *      @fullData [selectedClass].allQuestions
         *      @fullData [selectedClass].displayedQuestions
         *
         *  {Update database}
         *  =>classes / =>questions:
         *    *dates
         *    *earliestDate
         *
         *
         */
        if (matchingDisplayedQuestionType === "local") {
          /* Update matchingDisplayedQuestion */
          // @dates
          const currentDate = formatDateToString(selectedDateIn);
          if (matchingDisplayedQuestion.dates.includes(currentDate) === false) {
            matchingDisplayedQuestion.dates.push(currentDate);
          }
          // @earliestDate
          if (
            new Date(currentDate) <
              new Date(matchingDisplayedQuestion.earliestDate) ||
            matchingDisplayedQuestion.earliestDate === ""
          ) {
            matchingDisplayedQuestion.earliestDate = currentDate;
          }

          /* Update internal data model */
          const localQuestionId = matchingDisplayedQuestionId;

          // @fullData [selectedClass][selectedDateIn].questions
          updatedQuestions[localQuestionId] = matchingDisplayedQuestion;

          // @fullData [selectedClass].allQuestions
          newFullData[selectedClass].allQuestions[localQuestionId] =
            matchingDisplayedQuestion;

          // @fullData [selectedClass].displayedQuestions
          newFullData[selectedClass].displayedQuestions[localQuestionId] = {
            ...matchingDisplayedQuestion,
            type: matchingDisplayedQuestionType,
          };

          /* Update database */
          /*
           *  =>classes / =>questions:
           *    *dates
           *    *earliestDate
           */
          firestore.updateDoc(
            `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions/${localQuestionId}`,
            {
              ...matchingDisplayedQuestion,
            },
            { merge: true }
          );
        } else if (matchingDisplayedQuestionType === "global") {
          /**
           * if questionType === "global":
           *
           *  {Update matchingDisplayedQuestion deepcopy}
           *    @dates
           *    @earliestDate
           *
           *  {Update database}
           *    Create document in =>classes / =>questions
           *    Get localQuestionId
           *
           *  {Update internal data model}
           *    Copy updated matchingDisplayedQuestion:
           *      @fullData [selectedClass][selectedDateIn].questions
           *      @fullData [selectedClass].allQuestions
           *      @fullData [selectedClass].displayedQuestions
           *
           *
           */
          /* Update matchingDisplayedQuestion */
          // @dates
          const currentDate = formatDateToString(selectedDateIn);
          matchingDisplayedQuestion.dates.push(currentDate);

          // @earliestDate
          matchingDisplayedQuestion.earliestDate = currentDate;

          /* Update database */
          // Create document in =>classes / =>questions
          const result = await firestore.createDoc(
            `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions`,
            true,
            {
              ...matchingDisplayedQuestion,
            }
          );
          const localQuestionId = result.id;

          /* Update internal data model */
          // @fullData [selectedClass][selectedDateIn].questions
          updatedQuestions[localQuestionId] = matchingDisplayedQuestion;

          // @fullData [selectedClass].allQuestions
          newFullData[selectedClass].allQuestions[localQuestionId] =
            matchingDisplayedQuestion;

          // @fullData [selectedClass].displayedQuestions
          newFullData[selectedClass].displayedQuestions[localQuestionId] = {
            ...matchingDisplayedQuestion,
            type: "local",
          };
          delete newFullData[selectedClass].displayedQuestions[
            matchingDisplayedQuestionId
          ];

          // @fullData [selectedClass].globalQuestions
          delete newFullData[selectedClass].globalQuestions[
            matchingDisplayedQuestionId
          ];
        } else {
          // Error invalid question type
          throw new Error(
            `Invalid question type in fullData[${selectedClass}].displayedQuestions object`
          );
        }
      } else {
        /**
         * if questionType === null: i.e. new custom user question
         *
         *  Create new question object i.e. userQuestion
         *
         *  {Update database}
         *    Create document in =>classes / =>questions
         *    Get localQuestionId
         *
         *  {Update internal data model}
         *    Copy userQuestion:
         *      @fullData [selectedClass][selectedDateIn].questions
         *      @fullData [selectedClass].allQuestions
         *      @fullData [selectedClass].displayedQuestions
         *
         *
         */
        /* Create new question object */
        const currentDate = formatDateToString(selectedDateIn);
        const userQuestion = {
          question: question.question.trim(),
          options: question.options,
          dates: [currentDate],
          earliestDate: formatDateToString(selectedDateIn),
        };

        /* Update database */
        // Create document in =>classes / =>questions
        const result = await firestore.createDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions`,
          true,
          {
            ...userQuestion,
          }
        );
        const localQuestionId = result.id;

        /* Update internal data model */
        // @fullData [selectedClass][selectedDateIn].questions
        updatedQuestions[localQuestionId] = userQuestion;

        // @fullData [selectedClass].allQuestions
        newFullData[selectedClass].allQuestions[localQuestionId] = userQuestion;

        // @fullData [selectedClass].displayedQuestions
        // Delete existing identical question in fullData[selectedClass].displayedQuestions, if any, and add userQuestion to object
        for (const displayedQuestionId in fullData[selectedClass]
          .displayedQuestions) {
          const displayedQuestion =
            fullData[selectedClass].displayedQuestions[displayedQuestionId];
          if (
            displayedQuestion.question.toLowerCase() ===
            userQuestion.question.toLowerCase()
          ) {
            delete newFullData[selectedClass].displayedQuestions[
              displayedQuestionId
            ];
            break;
          }
        }
        newFullData[selectedClass].displayedQuestions[localQuestionId] = {
          ...userQuestion,
          type: "local",
        };
      }
    } // end loop over temporary questionIds

    /**
     *
     * {Update internal data model}
     *  @fullData [selectedClass][selectedDateIn].questionIds
     *  Call setParentData
     *
     * {Update database}
     *    =>lectures
     *      *questionIds i.e. copy updatedQuestionIds aggregator
     *
     *    =>classes / =>questions
     *      *dates i.e. correct *dates fields in question docs
     */

    /* Update internal data model */
    // @fullData [selectedClass][selectedDateIn].questionIds
    const updatedQuestionIds = Object.keys(updatedQuestions);
    newFullData[selectedClass][formatDateToString(selectedDateIn)].questionIds =
      updatedQuestionIds;

    newFullData[selectedClass][formatDateToString(selectedDateIn)].questions =
      updatedQuestions;

    // Call setParentData
    setParentData(newFullData);

    /* Update database */
    /**
     *  =>lectures
     *    *questionIds i.e. copy updatedQuestionIds aggregator
     */
    firestore.updateDoc(
      `universities/${
        firestore.userData.universityId
      }/classes/${selectedClass}/lectures/${formatDateToString(
        selectedDateIn
      )}`,
      {
        questionIds: updatedQuestionIds
      },
      { merge: true }
    );

    /**    =>classes / =>questions
     *       i.e. find all questions in db where *dates field includes currentDate
     *            verify that they have not been deleted by the user from the current date in the course of the most recent session by
     *            checking to see if the doc ID is stored in updatedQuestionIds
     *            if updatedQuestionIds.includes(questionDocId) === false {
     *               question has been deleted by user in most recent session, do the following:
     *                delete selectedDateIn from *dates field
     *                modify *earliestDate if needed
     *            }
     *           else {
     *             question has not been deleted in most recent session, no modifications to questionDoc needed
     *           }
     */

    // Iterate over =>classes / =>questions docs
    const currentDate = formatDateToString(selectedDateIn);
    const questionsQuery = firestore.query(
      `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions`
    );
    const questionsSnapshot = await firestore.getDocuments(questionsQuery);
    const questionDocuments = questionsSnapshot.docs;
    for (const questionDoc of questionDocuments) {
      const questionData = questionDoc.data();
      const dates = [...questionData.dates];
      if (dates.includes(currentDate) === false) {
        continue;
      } // Disregard questions without any ties to the current date

      const questionId = questionDoc.id;
      if (updatedQuestionIds.includes(questionId) === false) { // Question represented by current doc has been deleted by user in most recent session
        // Delete selectedDateIn from *dates
        const updatedDates = dates.filter((date) => date !== currentDate);

        // Update *earliestDate, if currently stored earliest date is selectedDateIn
        
        let updatedEarliestDate = null;
        if (updatedDates.length === 0) {
          updatedEarliestDate = "";
        } 
        else {
          // Find minimum in updatedDates
          const updatedDatesYearFirst = updatedDates.map((date) => {
            const [month, day, year] = date.split("-");
            return `${year}-${month}-${day}`;
          });
          updatedDatesYearFirst.sort();
          updatedEarliestDate = updatedDatesYearFirst[0];
        }



        // Write updated dates to database
        firestore.updateDoc(
          `universities/${firestore.userData.universityId}/classes/${selectedClass}/questions/${questionId}`,
          {
            dates: updatedDates,
            earliestDate: updatedEarliestDate
          },
          { merge: true }
        );
      }
    }

    // Close modal
    setIsOpen(false);

    // Send success notification
    notification.success("Survey submitted!");
  };

  /**
   * Converts a date object to a string
   * @param {Date} date
   * @param {String} order
   * @returns a string representing the inputted date object
   */
  function formatDateToString(date) {
    // Get the year, month, and day components from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with '0'
    const day = String(date.getDate()).padStart(2, "0");

    // Return formatted date string representation
    return `${month}-${day}-${year}`;
  }

  /**
   * Dropdown indicator component
   */
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon
            icon={props.selectProps.menuIsOpen ? faChevronDown : faChevronRight}
          />
        </components.DropdownIndicator>
      )
    );
  };

  const customDropdownMenuStyle = {
    control: (provided) => ({
      ...provided,
      position: "relative",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      position: "absolute",
      left: "8px", // Adjust as needed for proper alignment
      padding: "2px", // Adjust padding to align the indicator correctly
      // You might need additional styles here
      pointerEvents: "none", // This makes it non-interactive, you may want to keep the pointer events if it should be clickable
    }),
    // You may need to adjust other parts like the value container to add left padding
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "30px", // Adjust the padding to make room for the indicator
    }),
    // Make sure the indicatorsContainer is positioned correctly to not block the view
    indicatorsContainer: (provided) => ({
      ...provided,
      width: "30px",
      marginRight: "4px",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
  };

  return (
    <PopupModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={close}
      minHeight={"45rem"}
      style={{ overflowY: "auto" }}
    >
      {selectedClass && (
        <div className="ClassDateModal__container">
          {" "}
          {/* Modal Container */}
          {/* Modal Header */}
          <div>
            <h1>{classesDetails[selectedClass].name}</h1>
            <h2>{formatDateToString(selectedDateIn)}</h2>
          </div>
          {/* Topics Section */}
          <div>
            {/* Topics Prompt */}
            <div className="topic-header-row">
              <h3 className="topics-header">
                What topics would you like to cover?
              </h3>
              <IconButton
                icon={faCircleInfo}
                className="topic-info-icon"
                color="rgb(var(--primary))"
                backgroundColor="rgb(255, 255, 255)"
                data-tooltip-id="topic-info-tooltip"
                data-tooltip-place="top"
              />
              <Tooltip id="topic-info-tooltip">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: "12px" }}>
                    Students will be asked to rank their understanding
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    for each topic on a 4-point Likert scale
                  </span>
                </div>
              </Tooltip>
            </div>
            {/* View Topics / Add Topics */}
            <div>
              {input.topics &&
                input.topics.map((topic, index) => (
                  <div className="topic-item-row" key={index}>
                    <textarea
                      id={`topic-input-${index}`}
                      className="topic-input"
                      type="text"
                      value={topic}
                      placeholder="Enter topic"
                      onChange={(event) => {
                        // Dynamically resize text area
                        const textareaId = `#topic-input-${index}`;
                        resizeTextarea(textareaId, MAX_CHARS_PER_LINE_TOPICS);

                        // Change border color to red if duplicate
                        const result = input.topics.reduce(
                          (acc, topic, i) => {
                            if (
                              topic.trim().toLowerCase() ===
                              event.target.value.trim().toLowerCase()
                            ) {
                              acc.idx.push(i);
                              acc.count++;
                            }
                            return acc;
                          },
                          { count: 0, idx: [] }
                        );
                        const isDuplicate =
                          result.count > 0 && result.idx[0] !== index;

                        if (isDuplicate && event.target.value.trim() !== "") {
                          event.target.style.setProperty(
                            "border-bottom",
                            "2px solid rgb(255, 0, 0)",
                            "important"
                          );
                        } else {
                          event.target.style.removeProperty("border-bottom");
                        }

                        // Unconditionally update input with user input
                        const updatedTopics = [
                          ...input.topics.slice(0, index), // Elements before the changed element
                          event.target.value, // New element
                          ...input.topics.slice(index + 1), // Elements after the changed element
                        ];
                        setInput({
                          ...input,
                          topics: updatedTopics,
                        });

                        // Reset forceCloseStatus
                        forceClose.current.forceCloseStatus = false;
                      }}
                      // Clear duplicate topic input text boxes when user clicks away
                      onBlur={(event) => {
                        const isDuplicate =
                          input.topics.reduce((count, topic) => {
                            if (
                              topic.trim().toLowerCase() ===
                              event.target.value.trim().toLowerCase()
                            ) {
                              count++;
                            }
                            return count;
                          }, 0) > 1;
                        if (isDuplicate) {
                          const updatedTopics = [
                            ...input.topics.slice(0, index), // Elements before the changed element
                            "",
                            ...input.topics.slice(index + 1), // Elements after the changed element
                          ];
                          setInput({
                            ...input,
                            topics: updatedTopics,
                          });
                          event.target.style.removeProperty("border-bottom");
                        }
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          addTopic(event, index, event.key);
                        }
                      }}
                      style={{
                        resize: "none",
                        overflow: "hidden",
                        maxHeight: "35px",
                      }}
                    />
                    <IconButton
                      icon={faX}
                      backgroundColor="rgb(255, 255, 255)"
                      className="topic-remove-button"
                      onClick={() => {
                        removeTopic(index);
                      }}
                    ></IconButton>
                  </div>
                ))}
              <p className="add-topic-text" onClick={addTopic}>
                Add Topic
              </p>
            </div>
          </div>{" "}
          {/* End Topics Container */}
          {/* Questions Section */}
          <div>
            <div className="question-header-row">
              <h3 className="questions-header">
                What would you like to ask your students?
              </h3>
              <IconButton
                icon={faCircleInfo}
                className="question-info-icon"
                color="rgb(var(--primary))"
                backgroundColor="rgb(255, 255, 255)"
                data-tooltip-id="question-info-tooltip"
                data-tooltip-place="top"
              />
              <Tooltip id="question-info-tooltip">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    zIndex: 1500,
                  }}
                >
                  <span style={{ fontSize: "12px" }}>
                    Choose questions from the dropdown below
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    or create new multiple-choice questions
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    by clicking Add Question
                  </span>
                </div>
              </Tooltip>
            </div>
            <div>
              {/* Question Dropdown Menu */}
              <Select
                options={questionsStruct}
                value={null}
                onChange={(selectedDisplayedQuestionIn) => {
                  // Run duplicate check
                  const sourceQId = selectedDisplayedQuestionIn.value;
                  const displayedQuestion =
                    fullData[selectedClass].displayedQuestions[sourceQId];
                  const questionsText = Object.values(input.questions).map(
                    (obj) => obj.question
                  );
                  const isDuplicate =
                    questionsText.reduce((count, question) => {
                      if (
                        question.trim().toLowerCase() ===
                        displayedQuestion.question.trim().toLowerCase()
                      ) {
                        count++;
                      }
                      return count;
                    }, 0) > 0;

                  if (isDuplicate) {
                    notification.error("Duplicate questions are not allowed.");
                  } else {
                    // Add displayed question
                    addQuestion(null, displayedQuestion);

                    // Reset forceCloseStatus
                    forceClose.current.forceCloseStatus = false;
                  }
                }}
                isSearchable={false}
                styles={customDropdownMenuStyle}
                components={{ DropdownIndicator }}
                className="ClassDateModal__select"
              />

              {/* Questions */}
              {Object.keys(input.questions) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  {Object.keys(input.questions).map((tempQuestionId, index) => (
                    <div
                      key={tempQuestionId}
                      className="ClassDateModal__topic-oval"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px",
                        gap: "5px",
                        width: "100%",
                      }}
                    >
                      {/* Question Oval Component */}
                      <div className="input-icon-container">
                        {/* Input Text Box */}
                        <textarea
                          id={`question-input-${tempQuestionId}`}
                          className="question-input"
                          value={input.questions[tempQuestionId].question}
                          placeholder="Enter question"
                          onInput={(event) => {
                            // Dynamically resize text area
                            const textareaId = `#question-input-${index}`;
                            resizeTextarea(
                              textareaId,
                              MAX_CHARS_PER_LINE_QUESTIONS
                            );

                            // Run duplicate check
                            const questionsText = Object.values(
                              input.questions
                            ).map((obj) => obj.question);
                            const result = questionsText.reduce(
                              (acc, question, i) => {
                                if (
                                  question.trim().toLowerCase() ===
                                  event.target.value.trim().toLowerCase()
                                ) {
                                  acc.idx.push(i);
                                  acc.count++;
                                }
                                return acc;
                              },
                              { count: 0, idx: [] }
                            );
                            const isDuplicate =
                              result.count > 0 && result.idx[0] !== index;
                            if (
                              isDuplicate &&
                              event.target.value.trim() !== ""
                            ) {
                              event.target.style.setProperty(
                                "border-bottom",
                                "2px solid rgb(255, 0, 0)",
                                "important"
                              );
                            } else {
                              event.target.style.removeProperty(
                                "border-bottom"
                              );
                            }

                            // Create updated question object
                            const updatedQuestions = {
                              ...input.questions,
                              [tempQuestionId]: {
                                ...input.questions[tempQuestionId],
                                question: event.target.value,
                                dates: [formatDateToString(selectedDateIn)],
                                earliestDate:
                                  formatDateToString(selectedDateIn),
                              },
                            };

                            // Update input
                            setInput({
                              ...input,
                              questions: updatedQuestions,
                            });

                            // Reset forceCloseStatus
                            forceClose.current.forceCloseStatus = false;
                          }}
                          onBlur={(event) => {
                            // Run duplicate check
                            const questionsText = Object.values(
                              input.questions
                            ).map((obj) => obj.question);
                            const isDuplicate =
                              questionsText.reduce((count, question) => {
                                if (
                                  question.trim().toLowerCase() ===
                                  event.target.value.trim().toLowerCase()
                                ) {
                                  count++;
                                }
                                return count;
                              }, 0) > 1;
                            // Remove question from input text box when user clicks away if duplicate
                            if (isDuplicate) {
                              // Create updated questions object
                              const updatedQuestions = {
                                ...input.questions,
                                [tempQuestionId]: {
                                  ...input.questions[tempQuestionId],
                                  question: "",
                                },
                              };

                              // Update input
                              setInput({
                                ...input,
                                questions: updatedQuestions,
                              });

                              event.target.style.removeProperty(
                                "border-bottom"
                              );
                            }
                          }}
                          style={{
                            resize: "none",
                            overflow: "hidden",
                            maxHeight: "35px",
                          }}
                        />
                        {/* Close Icon */}
                        <IconButton
                          icon={faX}
                          backgroundColor="rgb(255, 255, 255)"
                          className="question-remove-button"
                          onClick={() => removeQuestion(tempQuestionId)}
                        ></IconButton>
                      </div>
                      {/* End Question Input Text Box + X Icon */}
                      {/* Options Section */}
                      {input.questions[tempQuestionId].options.map((option, optIndex) => (
                        <div className="option-row" key={optIndex}>
                          <textarea
                            id={`option-input-${tempQuestionId}-${optIndex}`}
                            className="option-input"
                            type="text"
                            value={option}
                            placeholder="Enter option"
                            onChange={(event) => {
                              // Dynamically resize text area
                              const textareaId = `#option-input-${tempQuestionId}-${optIndex}`;
                              resizeTextarea(
                                textareaId,
                                MAX_CHARS_PER_LINE_OPTIONS
                              );

                              // Change border color to red if duplicate
                              const result = input.questions[
                                tempQuestionId
                              ].options.reduce(
                                (acc, opt, i) => {
                                  if (
                                    opt.trim().toLowerCase() ===
                                    event.target.value.trim().toLowerCase()
                                  ) {
                                    acc.idx.push(i);
                                    acc.count++;
                                  }
                                  return acc;
                                },
                                { count: 0, idx: [] }
                              );
                              const isDuplicate =
                                result.count > 0 && result.idx[0] !== optIndex;

                              if (
                                isDuplicate &&
                                event.target.value.trim() !== ""
                              ) {
                                event.target.style.setProperty(
                                  "border-bottom",
                                  "2px solid rgb(255, 0, 0)",
                                  "important"
                                );
                              } else {
                                event.target.style.removeProperty(
                                  "border-bottom"
                                );
                              }

                              // Unconditionally update input with user input
                              const updatedOptions = JSON.parse(
                                JSON.stringify(input.questions[tempQuestionId].options)
                              );
                              updatedOptions[optIndex] = event.target.value;
                              const updatedQuestions = {
                                ...input.questions,
                                [tempQuestionId]: {
                                  ...input.questions[tempQuestionId],
                                  options: updatedOptions,
                                },
                              };
                              setInput({
                                ...input,
                                questions: updatedQuestions,
                              });

                              // Reset forceCloseStatus
                              forceClose.current.forceCloseStatus = false;
                            }}
                            // Clear duplicate option input text boxes when user clicks away
                            onBlur={(event) => {
                              const isDuplicate =
                                input.questions[tempQuestionId].options.reduce(
                                  (count, option) => {
                                    if (
                                      option.trim().toLowerCase() ===
                                      event.target.value.trim().toLowerCase()
                                    ) {
                                      count++;
                                    }
                                    return count;
                                  },
                                  0
                                ) > 1;
                              if (isDuplicate) {
                                const updatedOptions = JSON.parse(
                                  JSON.stringify(input.questions[tempQuestionId].options)
                                );
                                updatedOptions[optIndex] = "";
                                const updatedQuestions = {
                                  ...input.questions,
                                  [tempQuestionId]: {
                                    ...input.questions[tempQuestionId],
                                    options: updatedOptions,
                                  },
                                };
                                setInput({
                                  ...input,
                                  questions: updatedQuestions,
                                });
                                event.target.style.removeProperty(
                                  "border-bottom"
                                );
                              }

                              // Reset forceCloseStatus
                              forceClose.current.forceCloseStatus = false;
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                addOption(event, tempQuestionId, optIndex, event.key);
                              }
                            }}
                            style={{
                              resize: "none",
                              overflow: "hidden",
                              maxHeight: "35px",
                            }}
                          />
                          <IconButton
                            icon={faX}
                            backgroundColor="rgb(255, 255, 255)"
                            className="option-remove-button"
                            onClick={() => {
                              removeOption(tempQuestionId, optIndex);
                            }}
                          ></IconButton>
                        </div>
                      ))}{" "}
                      {/* End Options Section */}
                      <p
                        className="add-option-text"
                        onClick={(event) => addOption(event, tempQuestionId)}
                      >
                        Add Option
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <p className="add-question-text" onClick={addQuestion}>
              Add Question
            </p>
          </div>{" "}
          {/* End Questions Section */}
          <button
            type="button"
            style={{
              width: "80%",
              backgroundColor: "rgb(var(--primary))", // Darker blue
              color: "white",
              padding: "10px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              marginLeft: "25px",
              marginTop: "80px",
            }}
            onClick={submit}
          >
            Submit
          </button>
          {/* End of Modal Container */}
        </div>
      )}
    </PopupModal>
  );
};
export default ClassDateModal;
