import React from "react";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./PopupModal.css";

const PopupModal = ({
  children,
  isOpen = false,
  setIsOpen = () => {},
  onClose = () => {},
  minHeight,
  height,
  width,
}) => {
  if (!isOpen) {
    return;
  }

  /**
   * Runs on modal close
   */
  const handleClose = () => {
    setIsOpen(false);

    onClose();
  };

  const modalStyle = {
    minHeight : minHeight,
    maxHeight: height,
    width: width,
  };

  return (
    <div className="PopupModal__container">
      <div className="PopupModal__background" />
      <div className="PopupModal__modal" style={modalStyle}>
        <div className="PopupModal__scrollable">
          <button className="PopupModal__close-btn" onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} />
          </button>

          {children}
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
