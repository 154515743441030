import React from "react";
import { useNavigate } from "react-router-dom";

import AuthForm from "../../components/AuthForm/AuthForm";
import { useAuth } from "../../providers/AuthProvider";
import "./AppStoreRedirect.css";

const AppStoreRedirect = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const getNavLink = () => {
    return (
      <button className="link-btn" onClick={() => auth.logout(navigate)}>
        Log out
      </button>
    );
  };

  return (
    <div className="auth-container">
      <AuthForm
        headerLeft="Log Out"
        subheaderLeft="Click the button below to log out"
        buttonLeftText="Log Out"
        buttonLeftLink="/login"
        headerRight="Welcome to Brite!"
        subheaderRight="Download our mobile app by clicking below or searching 'Brite Mobile' on the App Store or Google Play Store."
        submitText="Download Brite"
        onSubmit={() => {
          window.location = "https://tosto.re/brite";
        }}
        navLink={getNavLink()}
      />
    </div>
  );
};

export default AppStoreRedirect;
