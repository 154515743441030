import "./CalendarClassOval.css";

const CalendarClassOval = ({
  classId,
  date,
  handleClassClick,
  topicsFilled,
  questionsFilled,
  legend,
  className,
}) => {
//   const [mouseHovering, setMouseHovering] = useState(false);

  const topicsFilledStyle = {
    backgroundColor: legend[classId],
    border: "0px solid black",
    color: "white",
  };

  const topicsNotFilledStyle = {
    backgroundColor: "white",
    border: "2px solid black",
    color: "black",
  };

  const buttonStyle =
    topicsFilled || questionsFilled ? topicsFilledStyle : topicsNotFilledStyle;

  return (
    <div
      className="CalendarClassOval__container"
      style={{ position: "relative" }}
    >
      <button
        className="CalendarClassOval__button"
        style={buttonStyle}
        onClick={() => handleClassClick(classId, date)}
      >
        <p
          className="text-small"
          style={{ fontWeight: "450", textAlign: "left" }}
        >
          {className}
        </p>
      </button>
    </div>
  );
};

export default CalendarClassOval;
