import { useParams, useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";

import useClassDataSnapshot from "../../hooks/useClassDataSnapshot";
import TopicAnalytics from "../../components/TopicAnalytics/TopicAnalytics";
import Loader from "../../components/Loader/Loader";
import "./Analytics.css";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const lineProperties = {
  backgroundColor: "rgba(0, 93, 172, 0.5)",
  borderColor: "rgba(0, 93, 172, 1)",
  pointRadius: 5,
  cubicInterpolationMode: "monotone",
  tension: 0.4,
};

const chartOptions = {
  responsive: true,
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        callback: (value) => `${value}%`,
      },
    },
  },
};

const Analytics = () => {
  const { universityId, classId } = useParams();
  const navigate = useNavigate();

  const classData = useClassDataSnapshot(universityId, classId);

  if (!classData) {
    return <Loader />;
  }

  if (!classData.lectureSummaries) {
    return (
      <div className="Analytics__container">
        <main className="Analytics__main-section">
          <div className="Analytics__main-section-header">
            <h1>
              {classData.name}: {classData.title}
            </h1>
            <button
              onClick={() => navigate("/analytics")}
              className="Analytics__back-btn"
            >
              All classes
            </button>
          </div>
          <p>No data</p>
        </main>
      </div>
    );
  }

  const participationData = {
    labels: [],
    datasets: [{ data: [], ...lineProperties }],
  };
  const understandingData = {
    labels: [],
    datasets: [{ data: [], ...lineProperties }],
  };

  const sortedDates = Object.keys(classData.lectureSummaries).sort((a, b) => {
    const [aMonth, aDay, aYear] = a.split("-");
    const [bMonth, bDay, bYear] = b.split("-");

    const aDate = new Date(Number(aYear), Number(aMonth) - 1, Number(aDay));
    const bDate = new Date(Number(bYear), Number(bMonth) - 1, Number(bDay));

    return aDate - bDate;
  });

  for (const date of sortedDates) {
    const formattedDate = date.replaceAll("-", "/");
    const summary = classData.lectureSummaries[date];

    participationData.labels.push(formattedDate);
    understandingData.labels.push(formattedDate);
    participationData.datasets[0].data.push(summary.participation * 100);
    understandingData.datasets[0].data.push(summary.averageUnderstanding * 100);
  }

  const understandingChartOptions = {
    interaction: { mode: "point" },
    plugins: {
      tooltip: {
        callbacks: {
          beforeFooter: () => "Topics:",
          footer: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            const date = sortedDates[index];
            return Object.keys(
              classData.lectureSummaries[date].topicUnderstandings,
            );
          },
        },
      },
    },
  };

  return (
    <div className="Analytics__container">
      <main className="Analytics__main-section">
        <div className="Analytics__main-section-header">
          <h1>
            {classData.name}: {classData.title}
          </h1>
          <button
            onClick={() => navigate("/analytics")}
            className="Analytics__back-btn"
          >
            All classes
          </button>
        </div>

        <section>
          <h2 className="Analytics__graph-title">Participation</h2>
          <Line options={chartOptions} data={participationData} />
        </section>

        <section>
          <h2 className="Analytics__graph-title">Understanding</h2>
          <Line
            options={{ ...chartOptions, ...understandingChartOptions }}
            data={understandingData}
          />
        </section>
      </main>

      <TopicAnalytics lectureSummaries={classData.lectureSummaries} />
    </div>
  );
};

export default Analytics;
