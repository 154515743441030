import TopicAnalyticsAccordion from "../TopicAnalyticsAccordion/TopicAnalyticsAccordion";
import "./TopicAnalytics.css";

const TopicAnalytics = ({ lectureSummaries }) => {
  return (
    <div className="TopicAnalytics__container">
      <h1>Topic analytics</h1>

      <hr className="TopicAnalytics__divider" />

      <TopicAnalyticsAccordion lectureSummaries={lectureSummaries} />
    </div>
  );
};

export default TopicAnalytics;
