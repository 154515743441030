import "./AdminQuestion.css";

const AdminQuestion = ({ question, questionData, isSelected, setSelected }) => {
  /**
   * Question(string) : the string representation of question being asked
   * questionData (map) : the rest of the data associated with the question that was pulled from the database
   *      contains attributes such as "dates", "earliestDate", "options", etc
   * isSelected (bool) : true if the question is currently selected
   * setSelected (func) : function to set the question to be selected
   */

  console.log(questionData["participationCompiled"]);
  return (
    <button
      onClick={setSelected}
      className={`AdminQuestion__btn ${isSelected ? "selected" : ""}`}
    >
      <div className="AdminQuestion__question">
        <h4 className="AdminQuestion__question-text">{question}</h4>
      </div>
      <h4 className="AdminQuestion__earliestDate">
        {questionData["earliestDate"]}
      </h4>
      <h4 className="AdminQuestion__dates"> {questionData["dates"].length}</h4>
      <h4 className="AdminQuestion__lastScheduled">
        {questionData["dates"][questionData["dates"].length - 1]}
      </h4>
      <div className="AdminQuestion__participation-div">
        {!isNaN(questionData["participationCompiled"]) ? (
          <div>
            <h4 style={{ width: "100%", textAlign: "center" }}>
              {(questionData["participationCompiled"] * 100).toFixed(3)}%
            </h4>
            <div
              style={{
                backgroundColor: "rgb(var(--primary))",
                height: "2px",
                width: `${questionData["participationCompiled"] * 100}%`,
              }}
            ></div>
          </div>
        ) : (
          <div>
            <h4 style={{ textAlign: "center" }}>No Data</h4>
          </div>
        )}
      </div>
    </button>
  );
};

export default AdminQuestion;
