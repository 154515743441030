import "./TimeCard.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../IconButton/IconButton";
import { red } from "../../colors";

const TimeCard = ({ time, handleDelete, showDeleteButton = true }) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getFormattedTime = (time) => {
    return time.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  return (
    <div className="TimeCard__container">
      <div className="TimeCard__info">
        <h4>{weekdays[time.weekday]}</h4>
        <h4 className="TimeCard__times">
          {getFormattedTime(time.startTime)} - {getFormattedTime(time.endTime)}
        </h4>
      </div>

      {showDeleteButton && (
        <IconButton
          icon={faXmark}
          backgroundColor={`rgb(${red})`}
          color="white"
          onClick={handleDelete}
        />
      )}
    </div>
  );
};

export default TimeCard;
