import { useState, useEffect } from "react";

import { useFirestore } from "../providers/FirestoreProvider";
import { useNotification } from "../providers/NotificationProvider";

/**
 * Get the list of classes associated with the currently authenticated user.
 *
 * @returns {object[]} list of class documents
 */
export default function useClassList() {
  const firestore = useFirestore();
  const notification = useNotification();

  const [classList, setClassList] = useState([]);

  useEffect(() => {
    let ignore = false;

    const { userData } = firestore;

    if (!userData) {
      return;
    }

    const promises = userData.classList.map((classId) =>
      firestore.getDocument(
        `universities/${userData.universityId}/classes/${classId}`
      )
    );

    Promise.all(promises)
      .then((values) => {
        const classes = values
          .map((doc) => firestore.docData(doc))
          .sort((a, b) => a.name.localeCompare(b.name));

        if (!ignore) {
          setClassList(classes);
        }
      })
      .catch(() => notification.error("Error fetching classes"));

    return () => {
      ignore = true;
    };
  }, [firestore, notification]);

  return classList;
}
