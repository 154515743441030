import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthForm from "../../components/AuthForm/AuthForm";
import { useAuth } from "../../providers/AuthProvider";

import "./Login.css";

const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  /**
   * Log the user out if they go to this page
   */
  useEffect(() => {
    auth.logout();
  }, [auth]);

  /**
   * Log the user in
   *
   * @param {Event} evt the event triggered by submitting the form
   */
  const login = (evt) => {
    evt.preventDefault();

    auth.login(email, password, () => navigate("/"));
  };

  const getNavLink = () => {
    return (
      <p className="text-small text-gray">
        Don't have an account?&nbsp;
        <Link className="text-small no-underline" to="/sign-up">
          Sign Up
        </Link>
      </p>
    );
  };

  const getFooter = () => {
    return (
      <Link to="/reset-password" className="text-small text-gray">
        Forgot Password?
      </Link>
    );
  };

  return (
    <div className="auth-container">
      <AuthForm
        headerLeft="Welcome Back!"
        subheaderLeft="We're glad to have you back. Log in or create an account using the button below."
        buttonLeftText="Student Sign Up"
        buttonLeftLink="/sign-up"
        headerRight="Log In"
        subheaderRight="Please enter your email and password"
        submitText="Log In"
        navLink={getNavLink()}
        footer={getFooter()}
        onSubmit={login}
      >
        <input
          type="email"
          placeholder="Email"
          onInput={(data) => setEmail(data.target.value)}
          required
        />

        <input
          type="password"
          placeholder="Password"
          onInput={(data) => setPassword(data.target.value)}
          required
        />
      </AuthForm>
    </div>
  );
};

export default Login;
