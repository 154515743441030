import React, { useEffect, useState } from "react";
import { useNotification } from "../../providers/NotificationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useFirestore } from "../../providers/FirestoreProvider";
import PopupModal from "../../components/PopupModal/PopupModal";
import UserCard from "../../components/UserCard/UserCard";
import "./GroupSettings.css";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../components/IconButton/IconButton";

const GroupSettings = () => {
  const firestore = useFirestore();
  const notification = useNotification();
  const navigate = useNavigate();

  const { universityId, classId } = useParams();

  const [classData, setClassData] = useState(null);
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [courseName, setCourseName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");

  /**
   * Get class data
   */
  useEffect(() => {
    let isMounted = true;

    firestore
      .getDocument(`universities/${universityId}/classes/${classId}`)
      .then((doc) => {
        if (isMounted) {
          let classData = firestore.docData(doc);

          setClassData(classData);

          setCourseName(classData.name);
          setCourseTitle(classData.title);
        }
      })
      .catch(() => notification.error("Error fetching class data"));

    return () => {
      isMounted = false;
    };
  }, [firestore, notification, universityId, classId]);

  /**
   * Get enrolled users
   */
  useEffect(() => {
    let isMounted = true;

    const query = firestore.query(
      `universities/${universityId}/classes/${classId}/enrolled`
    );

    firestore
      .getDocuments(query)
      .then((querySnapshot) => {
        let enrolled = [];

        querySnapshot.forEach((doc) => enrolled.push(doc.id));

        if (isMounted) {
          setEnrolledUsers(enrolled);
        }
      })
      .catch(() => notification.error("Error fetching enrolled users"));

    return () => {
      isMounted = false;
    };
  }, [firestore, notification, classId, universityId]);

  /**
   * Updates class information
   * @param {Event} event
   */
  const updateClass = async (event) => {
    event.preventDefault();

    await firestore.updateDoc(
      `universities/${universityId}/classes/${classId}`,
      {
        title: courseTitle,
        name: courseName,
      },
      { merge: true }
    );

    setClassData((prevClassData) => ({
      ...prevClassData,
      title: courseTitle,
      name: courseName,
    }));

    notification.success("Changes Saved!");
  };

  /**
   * Begins the removal of a user
   *
   * @param {Object} user the user to remove
   */
  const startDelete = (user) => {
    setUserToDelete(user);
    setIsModalOpen(true);
  };

  /**
   * Removes a user from a class
   */
  const confirmDelete = () => {
    firestore.updateUser(userToDelete.id, {
      classList: firestore.arrayRemove(classData.id),
    });
    firestore.removeDocument(
      `universities/${universityId}/classes/${classId}/enrolled/${userToDelete.id}`
    );

    setEnrolledUsers((prevEnrolledUsers) =>
      prevEnrolledUsers.filter((uid) => uid !== userToDelete.id)
    );
    setIsModalOpen(false);
    setUserToDelete(null);
  };

  /**
   * Cancels a deletion
   */
  const cancelDelete = () => {
    setUserToDelete(null);
    setIsModalOpen(false);
  };

  return (
    <div className="ClassSettings__container">
      <PopupModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <div className="ClassSettings__modal-contents">
          <h1>Delete User</h1>
          <p>Are you sure you would like to delete this user?</p>

          <UserCard user={userToDelete} showDeleteButton={false} />

          <div className="ClassSettings__modal-btns">
            <button className="cta-btn-gray" onClick={cancelDelete}>
              Cancel
            </button>
            <button className="cta-btn-red" onClick={confirmDelete}>
              Delete
            </button>
          </div>
        </div>
      </PopupModal>

      <div className="ClassSettings__class-details">
        <div className="ClassSettings__header">
          <div className="ClassSettings__header-left">
            <IconButton icon={faChevronLeft} onClick={() => navigate(-1)} />
            <h1>{classData?.name} Settings</h1>
          </div>
          <div className="ClassSettings__join-code">
            <h3>Join Code: {classData?.joinCode}</h3>
          </div>
          {/* <IconButton icon={faChevronLeft} onClick={() => navigate(-1)} /> */}
        </div>

        <form className="ClassSettings__settings-form" onSubmit={updateClass}>
          <label htmlFor="ClassSettings__courseName">
            <h3>Course Name:</h3>
          </label>
          <input
            type="text"
            id="ClassSettings__courseName"
            placeholder="Course Name (ex: MATH-101-001)"
            value={courseName}
            onChange={(event) => setCourseName(event.target.value)}
            required
          />

          <label htmlFor="ClassSettings__courseTitle">
            <h3>Course Title:</h3>
          </label>
          <input
            type="text"
            id="ClassSettings__courseTitle"
            placeholder="Course Title (ex: Concepts of Math)"
            value={courseTitle}
            onChange={(event) => setCourseTitle(event.target.value)}
            required
          />

          <div />
          <button type="submit" className="ClassSettings__save-btn cta-btn">
            Save
          </button>
        </form>
      </div>
      <div className="ClassSettings__enrolled-students">
        <h1 className="ClassSettings__header">
          Enrolled: {enrolledUsers.length}
        </h1>

        {enrolledUsers.map((uid, i) => (
          <UserCard
            key={i}
            uid={uid}
            removeUser={(user) => startDelete(user)}
          ></UserCard>
        ))}
      </div>
    </div>
  );
};

export default GroupSettings;
