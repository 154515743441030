import CalendarClassOval from "../CalendarClassOval/CalendarClassOval";
import "./CalendarDate.css";

/**
 * This component is used to render each individual date on the calendar
 */

const CalendarDate = ({
  blank,
  date,
  classesDetails,
  dateData,
  legend,
  current,
  past,
  handleDateClick,
  handleClassClick,
  handleResultsClick,
}) => {
  /**
   * blank : whether the date is blank or not
   * date : the date of the date object as a string
   * classesDetails : the field attributes for a given class in the db
   * topicData :
   * legend : dictionary with classIds and colors assigned to each
   * current : true if the date object == the current date
   * past : true if the date object < the current date
   */

  const data = dateData;
  console.log(data)

  /**
   * Returns a date
   * @param {Date} date
   * @returns
   */
  const extractDayFromDate = (date) => {
    const day = date.getDate();
    return day;
  };

  //styling for the current day
  const currentDayStyle = {
    backgroundColor: "rgb(229, 231, 240)",
  };

  /*Topics objects are grouped in divs by their respective class */
  return (
    <div>
      {blank && <div className="CalendarDate__date-container"></div>}
      {!blank && (
        <div
          className="CalendarDate__date-container"
          style={current ? currentDayStyle : {}}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "3px 0px",
              justifyContent: "center",
            }}
          >
            <p
              className="text-medium"
            >
              {extractDayFromDate(date)}
            </p>
            {past && Object.keys(data).length > 0 && (
              <div>
                <button
                  onClick={() => handleResultsClick(date)}
                  className="CalendarDate__corner-button"
                ></button>
              </div>
            )}
          </div>

          {Object.keys(data).map((classId) => {
            return (
              <div key={classId} style={{ padding: "0px 5px" }}>
                <CalendarClassOval
                  classId={classId}
                  date={date}
                  handleClassClick={() =>
                    handleClassClick(classId, date, classesDetails[classId].type)
                  }
                  topicsFilled={
                    data[classId].topics === undefined ||
                    data[classId].topics.length > 0
                  }
                  questionsFilled={
                    data[classId].questionIds === undefined ||
                    data[classId].questionIds.length > 0
                  }
                  legend={legend}
                  className={classesDetails[classId].name}
                  data={dateData}
                  tags={classesDetails[classId].tags || []} 
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CalendarDate;
