import React, { useEffect, useState, useCallback } from "react";
import { useNotification } from "../../providers/NotificationProvider";
import { useFirestore } from "../../providers/FirestoreProvider";
import "./Calendar.css";

//components
import Select from "react-select";
import moment from "moment";
import BarChart from "../../components/BarChart/BarChart";
import SideModal from "../../components/SideModal/SideModal";
import CalendarDays from "../../components/CalendarDays/CalendarDays";
import ClassDateModal from "../../components/ClassDateModal/ClassDateModal";
import Loader from "../../components/Loader/Loader";
import AdminModalScheduler from "../../components/AdminModalScheduler/NewQuestionModal";
import GroupDateModal from "../../components/GroupDateModal/GroupDateModal";
import ClassLegend from "../../components/ClassLegend/ClassLegend";
const status = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN PROGRESS",
  DONE: "DONE",
};

const Calendar = () => {
  const firestore = useFirestore();
  const notification = useNotification();

  const months = [
    { value: 0, label: "January" },
    { value: 1, label: "February" },
    { value: 2, label: "March" },
    { value: 3, label: "April" },
    { value: 4, label: "May" },
    { value: 5, label: "June" },
    { value: 6, label: "July" },
    { value: 7, label: "August" },
    { value: 8, label: "September" },
    { value: 9, label: "October" },
    { value: 10, label: "November" },
    { value: 11, label: "December" },
  ];

  //the possible years the user is capable of selecting from
  const years = [...new Array(8)].map((_, i) => ({
    value: new Date().getFullYear() - 1 + i,
    label: new Date().getFullYear() - 1 + i,
  }));

  const currentDate = new Date(); //the current date of the system
  const [month, setMonth] = useState(currentDate.getMonth()); //the user selected month
  const [year, setYear] = useState(currentDate.getFullYear()); //the user selected year
  const [classes, setClasses] = useState(null); //state variable to store the data structure containing all lecture info
  const [classesDetails, setClassesDetails] = useState([]); //to make a color guide for classes similar to a legend
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDateData, setSelectedDateData] = useState({}); //state to store the result of the getData function for the selectedDate
  const [selectedClass, setSelectedClass] = useState(null);

  const [selectedClassData, setSelectedClassData] = useState({});
  const [classLegend, setClassLegend] = useState({}); //dictionary to initialize the class legend component
  const [classDateModalOpen, setClassDateModalOpen] = useState(false);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [adminModalOpen, setAdminModalOpen] = useState(false);

  const [loadingStatus, setLoadingStatus] = useState(status.NOT_STARTED);

  // const [selectedClassScroll, setSelectedClassScroll] = useState(null);
  // const [selectedQuestionScroll, setSelectedQuestionScroll] = useState(null);

  // const [selectedDateScroll, setSelectedDateScroll] = useState(null);
  // const [selectedQuestionData, setSelectedQuesftionData] = useState(null);

  const [resultsWindow, setResultsWindow] = useState(null);

  /**
   * Creates a dictionary with classIds as keys and topics as values
   * Gets all the classes with topics scheduled for a given date
   * @param {String} date the date for which to get data
   */
  const getData = useCallback(
    (data, date) => {
      const dateData = {};
      if (data) {
        firestore.userData.classList.forEach((classId) => {
          if (data[classId][date] !== undefined) {
            dateData[classId] = data[classId][date];
          }
        });
        return dateData;
      }
    },
    [firestore.userData.classList],
  );

  /**
   * Use effect to collect all of the data surrounding each class from the firestore db
   * Puts all of the data into a state variable called classDetails
   */
  useEffect(() => {
    let isMounted = true;
    const classesInfo = {}; //stores the field information of every class
    const classLegendSetter = {};
    const colors = [
      "mediumblue",
      "firebrick",
      "darkorange",
      "darkviolet",
      "darkgreen",
      "black",
      "coral",
      "dodgerblue",
      "indigo",
      "orangered",
      "crimson",
      "olive",
    ];

    if (firestore?.userData) {
      // Iterate through classIds
      firestore.userData.classList.forEach((classId, i) => {
        // Getting the field data for a given classId from the db
        firestore
          .getDocument(
            `universities/${firestore.userData.universityId}/classes/${classId}`,
          )
          .then((doc) => {
            classesInfo[classId] = firestore.docData(doc);
            classLegendSetter[classId] = colors[i];
            // Check if all data has been fetched
            if (i === firestore.userData.classList.length - 1 && isMounted) {
              setClassesDetails(classesInfo);
              setClassLegend(classLegendSetter);
            }
          })
          .catch(() => notification.error("Error fetching class data"));
      });
    }

    // Cleanup when component unmounts
    return () => {
      isMounted = false;
    };
  }, [firestore, notification]);

  /**
   * UseEffect to create a datastructure that stores every lecture for each of the user's classes
   * The data structure has keys of class ids, and their value pair is another dictionary with keys as lecture dates
   * and values of the given topics for each lecture date
   */
  useEffect(() => {
    const fetchData = async () => {
      setLoadingStatus(status.IN_PROGRESS);
      const classesStructure = {}; // <K: classId, V: classLectures> (where classLectures <K: lectureId, V: lectureData})
      //const todaysStructure = {}; // keys as classIds and values as an array of questionIds for the current date
      let isMounted = true;

      if (firestore?.userData) {
        // Get generic question documents from global =>questions
        const questionsQuery = firestore.query(`questions`);
        const globalQuestionDocuments =
          await firestore.getDocuments(questionsQuery);

        /* Build classesStructure <K: classId, V: classLectures> (where classLectures <K: lectureId, V: lectureData}) */
        // Iterate over classes
        for (const classId of firestore.userData.classList) {
          // Initialize classLectures && first-level constituent objects
          const classLectures = {};
          const allQuestions = {};
          const globalQuestions = {};
          let displayedQuestions = {};
          const allQuestionPrompts = [];

          // Create classLectures[lectureId] spread and initialize objs

          const lecturesQuery = firestore.query(
            `universities/${firestore.userData.universityId}/classes/${classId}/lectures`,
          );
          const lecturesSnapshot = await firestore.getDocuments(lecturesQuery);
          const lectureDocuments = lecturesSnapshot.docs;
          // Initialize classLectures[lectureId] for each lectureId
          for (const lectureDoc of lectureDocuments) {
            const lectureId = lectureDoc.id;
            const lectureData = lectureDoc.data();
            classLectures[lectureId] = {};
            classLectures[lectureId].questions = {};

            // Backward compatibility null check for *questionIds field in lectureData which is absent in old lecture docs
            if (Object.hasOwn(lectureData, "questionIds") === true) {
              classLectures[lectureId].questionIds = lectureData.questionIds;
            } else {
              classLectures[lectureId].questionIds = [];
            }

            classLectures[lectureId].topics = lectureData.topics;
          }

          // Non-linearly populate classLectures[lectureId].questions by iterating over questions and adding each question doc to all corresponding classLectures[lectureId] obj
          const questionsQuery = firestore.query(
            `universities/${firestore.userData.universityId}/classes/${classId}/questions`,
          );
          const questionsSnapshot =
            await firestore.getDocuments(questionsQuery);
          const questionDocuments = questionsSnapshot.docs;

          // Sort questionDocuments in descending order according to earliestDate field
          questionDocuments.sort((questionDocA, questionDocB) => {
            const dateA = new Date(questionDocA.data().earliestDate);
            const dateB = new Date(questionDocB.data().earliestDate);

            return dateB - dateA;
          });
          for (const questionDoc of questionDocuments) {
            const questionId = questionDoc.id;
            const questionData = questionDoc.data();
            const dates = questionData.dates;

            // Backward comptability null check for *datesMetadata field in questionData, which is absent in old question documents
            if (firestore?.userData.role === "admin") {
              if (Object.hasOwn(questionData, "datesMetadata") === false) {
                questionData.datesMetadata = {};
                for (const date of dates) {
                  questionData.datesMetadata[date] = {
                    repeating: false,
                  }; // Assume all existing questions without datesMetadata are one-off questions
                }
              }
            }

            // Display all previously asked questions (most recent version if there are any duplicate questions w/ different option sets)
            if (allQuestionPrompts.includes(questionData.question) === false) {
              displayedQuestions[questionId] = {
                ...questionData,
                type: "local",
              };
            }

            // Add question to allQuestions obj
            allQuestions[questionId] = questionData;
            allQuestionPrompts.push(questionData.question);

            for (const date of dates) {
              if (Object.hasOwn(classLectures, date) === false) {
                classLectures[date] = {};
                classLectures[date].questions = {};
              }
              classLectures[date].questions[questionId] = questionData;
            }
          }
          // Add allQuestions to classLectures
          classLectures.allQuestions = allQuestions;

          // Construct globalQuestions / displayedQuestions but exclude all questions already posed in class history
          globalQuestionDocuments.forEach((doc) => {
            const data = doc.data();
            const question = data.question;
            if (allQuestionPrompts.includes(question) === false) {
              globalQuestions[doc.id] = data;
              displayedQuestions[doc.id] = {
                ...data,
                dates: [],
                datesMetadata: {},
                earliestDate: "",
                type: "global",
              };
            }
          });
          classLectures.globalQuestions = globalQuestions;

          // Reverse displayedQuestions ordering so globalQuestions at the top && place in classesStructure
          const reversedDisplayedQuestions = {};
          const reversedDisplayedQuestionIds =
            Object.keys(displayedQuestions).reverse();
          reversedDisplayedQuestionIds.forEach((qid) => {
            reversedDisplayedQuestions[qid] = displayedQuestions[qid];
          });
          displayedQuestions = reversedDisplayedQuestions;
          classLectures.displayedQuestions = displayedQuestions;

          // Add classLectures to classesStructure using classId as the key
          classesStructure[classId] = classLectures;
        }

        // Update state with the created data structures
        if (isMounted) {
          setLoadingStatus(status.DONE);
          setClasses(classesStructure);
        }
      }
    };

    fetchData();
  }, [selectedClass, firestore, notification]);

  // useEffect(() => {
  //   //access the db and retreive the data for the selected question
  //   //set a new state var to reflect these changes

  //   const fetchQuestionData = async () => {
  //     const questionDoc = await firestore.getDocument(
  //       `universities/${firestore.userData.universityId}/classes/${selectedClassScroll}/questions/${selectedQuestionScroll}`
  //     );
  //     console.log(firestore.docData(questionDoc));
  //     setSelectedQuestionData(firestore.docData(questionDoc));
  //   };
  //   fetchQuestionData();
  // }, [selectedClassScroll, selectedQuestionScroll]);

  // Update selectedClassData
  useEffect(() => {
    if (selectedClass && selectedDate) {
      const dateData = getData(
        classes,
        formatDateToString(selectedDate, "MM-DD-YYYY"),
      );

      if (Object.keys(dateData).length !== 0) {
        setSelectedClassData(dateData[selectedClass]);
      }
    }
  }, [classes, selectedClass, selectedDate, getData]);

  // Display loading animation only during initialization stage
  if (
    loadingStatus !== status.DONE &&
    Object.keys(selectedClassData).length === 0
  ) {
    return <Loader />;
  }

  /**
   *
   * @param {string} month
   * @returns the first day of the month in decimal format
   */
  const firstDayOfMonth = (year, month) => {
    // Create a moment object for the first day of the given month
    const firstDay = moment(`${year}-${month + 1}-01`, "YYYY-MM-DD");

    // Get the day of the week (0 for Sunday, 1 for Monday, etc.)
    const dayOfWeek = firstDay.day();

    return dayOfWeek;
  };

  /**
   * Converts a date object to a string
   * @param {Date} date
   * @param {String} order
   * @returns a string representing the inputted date object
   */
  function formatDateToString(date, order) {
    // Get the year, month, and day components from the date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1 and pad with '0'
    const day = String(date.getDate()).padStart(2, "0");

    // Create formatted string according to order param
    if (order === "MM-DD-YYYY") {
      return `${month}-${day}-${year}`;
    } else if (order === "YYYY-MM-DD") {
      return `${year}-${month}-${day}`;
    }
  }

  /**
   * When topics are added or removed via the calendar's pop up modal
   * this function is called, updating the classes state to incorporate
   * the changes made via the modal.
   * @param {Object} newData
   */
  const setParentData = (newData) => {
    setClasses(newData);
  };

  // const handleScrollClick = (classId, question, date) => {
  //   setSelectedClassScroll(classId);
  //   setSelectedQuestionScroll(question);
  //   setSelectedDateScroll(date);
  // };

  /**
   * Changes the selected date based on a click on one of the calendar
   * date components
   * @param {Date} date
   */
  const handleDateClick = (date) => {
    //setSelectedDate(date); // Schedule the state update
    //setAddTopicModalOpen(true);
  };

  const handleClassClick = (classId, date, type) => {
    if (type === "class") {
      setSelectedDate(date); // Schedule the state update
      setSelectedClass(classId);
      const dateData = getData(classes, formatDateToString(date, "MM-DD-YYYY"));
      setSelectedClassData(dateData[classId]);

      console.log("ClassDateModalOpen");
      setClassDateModalOpen(true);
    } else if (type === "group") {
      setSelectedDate(date); // Schedule the state update
      setSelectedClass(classId);
      const dateData = getData(classes, formatDateToString(date, "MM-DD-YYYY"));
      setSelectedClassData(dateData[classId]);

      console.log("GroupDateModalOpen");
      setGroupModalOpen(true);
    }
  };

  const handleResultsClick = (date) => {
    setSelectedDate(date);
    const dateData = getData(classes, formatDateToString(date, "MM-DD-YYYY")); //getting each's classes data for the given date
    setSelectedDateData(dateData);
    // Set selectedClass to the first key in dateData
    const firstKey = Object.keys(dateData)[0];
    setSelectedClass(firstKey);

    setResultsWindow(true);
  };

  return (
    <div className="Calendar__main-container">
      {classes && classesDetails !== undefined && (
        <div>
          <ClassDateModal
            isOpen={classDateModalOpen}
            setIsOpen={setClassDateModalOpen}
            fullData={classes}
            setParentData={setParentData}
            classesDetails={classesDetails}
            selectedDateIn={selectedDate}
            selectedClass={selectedClass}
            selectedClassData={selectedClassData}
          ></ClassDateModal>
          <GroupDateModal
            isOpen={groupModalOpen}
            setIsOpen={setGroupModalOpen}
            fullData={classes}
            setParentData={setParentData}
            classesDetails={classesDetails}
            selectedDateIn={selectedDate}
            selectedClass={selectedClass}
            selectedClassData={selectedClassData}
          ></GroupDateModal>
          <AdminModalScheduler
            isOpen={adminModalOpen}
            setIsOpen={setAdminModalOpen}
            fullData={classes}
            setParentData={setParentData}
            classesDetails={classesDetails}
            setClassesDetails={setClassesDetails}
            legend={classLegend}
          ></AdminModalScheduler>
        </div>
      )}

      <div className="Calendar__main">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: "0.2", borderRight: "1px solid black" }}>
            <div
              className="Calendar__header"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <h1 style={{ textAlign: "center" }}>
                {months[month].label} {year}
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Select
                  options={months}
                  value={months[month]} // Set the initial value based on the current month
                  onChange={(selectedMonth) => setMonth(selectedMonth.value)} // Update the month state with the selected value
                />
                <Select
                  options={years}
                  value={years[year]} // Set the initial value as a single integer (selectedYear.value is an object)
                  placeholder="Select Year"
                  onChange={(selectedYear) => setYear(selectedYear.value)} // Update the year state with the selected value
                />
              </div>
              <div>
                <ClassLegend
                  classesDetails={classesDetails}
                  legend={classLegend}
                ></ClassLegend>
              </div>
              {firestore?.userData.role === "admin" && (
                <button
                  className="cta-btn"
                  onClick={() => setAdminModalOpen(true)}
                >
                  Create Group Survey
                </button>
              )}
            </div>
          </div>
          <div className="Calendar__dates-container">
            {classes && (
              <div>
                <CalendarDays
                  month={month}
                  year={year}
                  firstDay={firstDayOfMonth}
                  data={classes}
                  classesDetails={classesDetails}
                  legend={classLegend}
                  filterfunc={getData}
                  handleDateClick={handleDateClick}
                  handleClassClick={handleClassClick}
                  handleResultsClick={handleResultsClick}
                />
                {selectedDateData && selectedDate && selectedClass && (
                  <SideModal
                    isOpen={resultsWindow}
                    setIsOpen={setResultsWindow}
                    width={"40%"}
                  >
                    <div
                      style={{
                        borderBottom: "4px solid rgb(5, 70, 152)",
                        textAlign: "center",
                        marginBottom: "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <h2>{formatDateToString(selectedDate, "MM-DD-YYYY")}</h2>
                      <h2>Custom Question Results</h2>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "100%",
                      }}
                    >
                      {Object.keys(selectedDateData).map((classId) => (
                        <div
                          key={classId}
                          style={{ borderBottom: "1px solid black" }}
                        >
                          <h3>{classesDetails[classId].name}</h3>

                          {Object.keys(selectedDateData[classId].questions)
                            .length !== 0 ? (
                            <div>
                              {/* Mapping through each of the classes questions for a given date */}
                              {Object.entries(
                                selectedDateData[classId].questions,
                              ).map(([questionId, question], index) => (
                                <div
                                  key={questionId}
                                  style={{ marginBottom: "10px" }}
                                >
                                  {question["responseData"] &&
                                    question["responseData"][
                                      formatDateToString(
                                        selectedDate,
                                        "MM-DD-YYYY",
                                      )
                                    ] &&
                                    question["responseData"][
                                      formatDateToString(
                                        selectedDate,
                                        "MM-DD-YYYY",
                                      )
                                    ].responseTallies !== undefined && (
                                      <div>
                                        <BarChart
                                          labels={
                                            selectedDateData[classId].questions[
                                              questionId
                                            ]?.options
                                          }
                                          data={
                                            question["responseData"][
                                              formatDateToString(
                                                selectedDate,
                                                "MM-DD-YYYY",
                                              )
                                            ]["responseTallies"]
                                          }
                                          title={question?.question}
                                          chartWidth="100%" // Take up all available width
                                          chartHeight="100%" // Take up all available height
                                          titleFontSize="15px"
                                        />
                                      </div>
                                    )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <p style={{ padding: "5px", textAlign: "center" }}>
                              No data available for this class and date.
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </SideModal>
                )}
              </div>
            )}
          </div>
        </div>

        {/* <div className="Calendar__calendar-bottom">
          <h1>Month at A Glance</h1>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              className="Calendar__scroll-ctn"
              style={{ maxHeight: "300px", width: "50%" }}
            >
              <CalendarScroll
                month={month}
                year={year}
                firstDay={firstDayOfMonth}
                data={classes}
                classesDetails={classesDetails}
                legend={classLegend}
                filterfunc={getData}
                handleDateClick={handleDateClick}
                handleClassClick={handleClassClick}
                handleScrollClick={handleScrollClick}
              />
            </div>
            {selectedQuestionData && (
              <BarChart
                labels={selectedQuestionData?.options}
                data={
                  selectedQuestionData["responseData"][
                    formatDateToString(selectedDateScroll, "MM-DD-YYYY")
                  ]["responseTallies"]
                }
                title={`Results of: ${selectedQuestionData?.question}`}
                chartWidth="50%" // Adjust as needed
                chartHeight="300px" // Adjust as needed
              />
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Calendar;
