import React, { useEffect, useState } from "react";

import "./CodeForm.css";

const CodeForm = ({ codeLength = 6, onChange = () => {} }) => {
  const [code, setCode] = useState(new Array(codeLength).fill(""));

  useEffect(() => {
    onChange(code.join(""));
  }, [code, onChange]);

  const handleChange = (event, index) => {
    if (event.key === "Backspace") {
      let prev = event.target.previousElementSibling;

      if (prev && !event.target.value) {
        prev.focus();

        setValueAtIndex(index - 1, "");
      }

      setValueAtIndex(index, "");
    } else {
      setValueAtIndex(index, event.target.value);

      let next = event.target.nextElementSibling;

      if (next && event.target.value) {
        next.focus();
      }
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace") {
      let prev = event.target.previousElementSibling;

      if (prev && !event.target.value) {
        prev.focus();

        setValueAtIndex(index - 1, "");
      }
    }
  };

  const setValueAtIndex = (index, value) => {
    setCode((prevCode) => {
      let newCode = [...prevCode];

      newCode[index] = value;

      return newCode;
    });
  };

  return (
    <div className="CodeForm__container">
      {code.map((value, i) => (
        <input
          key={i}
          className="CodeForm__input"
          type="text"
          value={value}
          onKeyDown={(event) => handleKeyDown(event, i)}
          onChange={(event) => handleChange(event, i)}
          maxLength={1}
        />
      ))}
    </div>
  );
};

export default CodeForm;
