import React, { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCrown } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "../../providers/AuthProvider";
import "./ChatMessage.css";
import { primary } from "../../colors";
import { useFirestore } from "../../providers/FirestoreProvider";

const ChatMessage = ({
  message,
  sender,
  teacherUID,
  setSender = () => {},
  setDeleteMessage = () => {},
  showInfo = true,
  showCheckbox = false,
}) => {
  const auth = useAuth();
  const firestore = useFirestore();

  useEffect(() => {
    let isMounted = true;

    if (typeof sender === "string") {
      return;
    }

    sender.then((result) => {
      const sender = firestore.docData(result);

      if (isMounted) {
        setSender(`${sender.firstName} ${sender.lastName}`);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [firestore, sender, setSender]);

  /**
   * Gets a random color to display for the message based on message data
   *
   * @param {Object} message message to be colored
   * @returns an hsl string containing the randomized color
   */
  const getRandomColor = (message) => {
    const seed = parseInt(message.userUID, 36);
    const random = Math.abs(Math.sin(seed));

    const hue =
      random *
      360 *
      Math.round(message.date?.getYear() / (message.date?.getDay() + 1));

    return `hsl(${hue}, 50%, 50%)`;
  };

  if (typeof sender !== "string") {
    return;
  }

  return (
    <div
      className={
        message !== undefined && message.userUID === auth.user.uid
          ? "ChatMessage__message-user-owned"
          : "ChatMessage__message"
      }
    >
      {showInfo && (
        <div className="ChatMessage__message-info">
          <div
            className="ChatMessage__user-icon"
            style={{
              backgroundColor:
                message?.userUID === auth.user.uid
                  ? `rgb(${primary})`
                  : getRandomColor(message),
            }}
          >
            <FontAwesomeIcon
              icon={message?.userUID === teacherUID ? faCrown : faUser}
              size="xs"
            />
          </div>
          <p className="text-small">
            {message?.userUID === auth.user.uid
              ? "You"
              : message?.userUID === teacherUID
              ? "Teacher"
              : sender}
          </p>
          <p className="text-small">
            {message?.date
              ?.toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
              })
              .toLowerCase()}
          </p>
        </div>
      )}
      <div className="ChatMessage__message-row">
        <div className="ChatMessage__message-content">
          <p className="ChatMessage__message-text">{message?.text}</p>
        </div>

        {showCheckbox && (
          <input
            type="checkbox"
            onClick={(event) =>
              setDeleteMessage(message?.id, event.target.checked)
            }
          />
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
