import React from "react";
import { Navigate } from "react-router-dom";

import { useFirestore } from "../../providers/FirestoreProvider";

const RequireRole = ({ roles = [], children }) => {
  const firestore = useFirestore();

  if (!firestore.userData || !roles.includes(firestore.userData.role)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default RequireRole;
