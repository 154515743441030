import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";

import PopupModal from "../PopupModal/PopupModal";
import TabSwitcher from "../TabSwitcher/TabSwitcher";
import CodeForm from "../CodeForm/CodeForm";
// import ToggleInput from "../../components/ToggleInput/ToggleInput";
import TimeInput from "../TimeInput/TimeInput";
import { useAuth } from "../../providers/AuthProvider";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";

import "./GroupClassModal.css";
const weekdayOptions = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
  (day, i) => ({
    label: day,
    value: i,
  })
);

const classJoinReasons = [
  "Core class for major",
  "Core class for minor",
  "Elective",
  "General Education Foundation (GEF)",
  "Personal interest",
  "Credit requirement for scholarships",
];

const GroupClassModal = ({ isOpen = false, setIsOpen = () => {}, role }) => {
  const auth = useAuth();
  const firestore = useFirestore();
  const notification = useNotification();

  const [selectionType, setSelectionType] = useState(null);

  const modalOptionsClass = ["Create Class", "Join Class"];
  const modalOptionsGroup = ["Create Group", "Join Group"];

  const [modalTabsClass] = useState(
    firestore.userData?.role === "teacher" ||
      firestore.userData?.role === "admin"
      ? modalOptionsClass
      : [modalOptionsClass[1]]
  );

  const [modalTabsGroup] = useState(
    firestore.userData?.role === "teacher" ||
      firestore.userData?.role === "admin"
      ? modalOptionsGroup
      : [modalOptionsGroup[1]]
  );

  const [selectedTabClass, setSelectedTabClass] = useState(modalTabsClass[0]);
  const [selectedTabGroup, setSelectedTabGroup] = useState(modalTabsGroup[0]);

  const [classJoinCode, setClassJoinCode] = useState("");
  const [classJoinReason, setClassJoinReason] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [isAsync, setIsAsync] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [groupJoinCode, setGroupJoinCode] = useState("");
  const [groupName, setGroupName] = useState("");

  const [classDays, setClassDays] = useState([0, 0, 0, 0, 0, 0, 0]); //stores 1s and 0s. 1s indicate that a class will occur on that day

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [daySelected, setDaySelected] = useState(false); //variable to check if at least on day has been selected

  const [classTimes, setClassTimes] = useState({
    0: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
    1: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
    2: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
    3: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
    4: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
    5: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
    6: {
      startTime: new Date(),
      endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
    },
  });

  /**
   * Resets the modal forms back to their default values
   */

  const resetForms = useCallback(() => {
    //group resets
    setSelectionType(null);
    setGroupJoinCode("");
    setGroupName("");
    setSelectedTabGroup(modalTabsGroup[0]);

    //class resets
    setClassJoinCode("");
    setIsAsync(false);
    setSelectedTabClass(modalTabsClass[0]);
    // setSelectedEvalQuestions([]);
    // setSelectedEvalQuestionsText([]);

    setEndDate("");
    setStartDate("");

    const startDate = new Date();
    const endDate = new Date(startDate);

    endDate.setHours(endDate.getHours() + 1);
    setClassDays([0, 0, 0, 0, 0, 0, 0]);
    setClassTimes({
      0: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      1: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      2: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      3: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      4: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      5: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      6: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
    });
  }, [modalTabsClass, modalTabsGroup]);

  useEffect(() => {
    resetForms();
  }, [resetForms]);

  /**
   * Updates daySelected based on changes to classDays
   *
   */

  useEffect(() => {
    if (classDays.includes(1)) {
      setDaySelected(true);
    } else {
      setDaySelected(false);
    }
  }, [classDays]);

  /**
   * Updates the data structure storing the days in which class is scheduled
   *
   * @param {int} startOrEnd determines whether to update the startTime (0) or the endTime (1)
   * @param {int} day the int form of the day for which to update
   * @param {} time
   */
  const updateClassTimes = (startOrEnd, day, time) => {
    setClassTimes((prevClassTimes) => {
      const newClassTimes = { ...prevClassTimes }; // Create a shallow copy
      const newDate = new Date();

      if (startOrEnd === 0) {
        //if changing the startTime
        newDate.setHours(...time.split(":"), 0); //create a new date with the inputted time
        if (newDate > prevClassTimes[day].endTime) {
          //checks to ensure the new start time is before the end time
          notification.warn("Select a start time before the end time");
          return prevClassTimes;
        }

        newClassTimes[day].startTime = newDate; //updates the data structure
      } else if (startOrEnd === 1) {
        //if changing the endTime
        newDate.setHours(...time.split(":"), 0);
        if (newDate < prevClassTimes[day].startTime) {
          //checks to see if the new end time is after the start time
          notification.warn("Select an end time after the start time");
          return prevClassTimes;
        }

        newClassTimes[day].endTime = newDate; //update the data structure
      }

      return newClassTimes;
    });
  };

  /**
   * Updates the class days structure, button presses will convert 0s to 1s and 1s to 0s

  * @param {int} day the integer representation of the day for which to toggle  
   */
  const updateClassDays = (day) => {
    setClassDays((prevClassDays) => {
      const newClassDays = [...prevClassDays]; // Create a new array
      newClassDays[day] = newClassDays[day] === 1 ? 0 : 1; // Toggle the value
      return newClassDays;
    });
  };

  //CLASS SPECIFIC FUNCTIONS

  /**
   * Add a class to the database and to the current user
   *
   * @param {Event} event the event triggered when the form is submit
   */
  const addClass = async (event) => {
    event.preventDefault();

    // Generate a new join code for the class
    let joinCode;

    do {
      joinCode = generateRandomCode(6);
    } while (await codeExists(joinCode));

    //filter classTimes only for days that are selected
    let filteredClassTimes = classDays.reduce((result, day, i) => {
      if (day === 1) {
        result[i] = classTimes[i];
      }
      return result;
    }, {});

    const times = filteredClassTimes; //renaming variable

    let classRef = await firestore.createDoc(
      `universities/${firestore.userData.universityId}/classes`,
      true,
      {
        title: courseTitle,
        name: courseName,
        teacherUID: auth.user.uid,
        times,
        joinCode,
        tags: [],
        isAsync,
        chatDisabled: false,
        type: "class",
        // selectedEvalQuestions,
      }
    );

    firestore.updateUser(auth.user.uid, {
      classList: firestore.arrayUnion(classRef.id),
    });
    firestore.setUserData({
      ...firestore.userData,
      classList: [...firestore.userData.classList, classRef.id],
    });

    firestore.createDoc(
      `universities/${firestore.userData.universityId}/joincodes/${joinCode}`,
      false,
      {
        classId: classRef.id,
      }
    );
    // the code below initializes lecture documents for the class

    let trackedDate = new Date(yearMonthDayToMonthDayYear(startDate));
    let endDateDate = new Date(yearMonthDayToMonthDayYear(endDate));
    let i = 0;

    while (trackedDate <= endDateDate) {
      //goes through every date from startDate to endDate
      trackedDate = new Date(
        trackedDate.getFullYear(),
        trackedDate.getMonth(),
        trackedDate.getDate() + 1
      );
      const trackedWeekday = getWeekdayName(trackedDate); //gets the weekday; "Monday" for example of the tracked date
      const trackedDateFormat = trackedDate
        .toLocaleString("en-us", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        })
        .replaceAll("/", "-");
      //if the date is of a weekday that is selected (its corresponding index has a value of 1 in the classDays array),
      //initialize the lecture on that date

      if (classDays[weekdays.indexOf(trackedWeekday)] === 1) {
        // if (i === selectedEvalQuestions.length) {
        //   i = 0;
        // }
        // let evalQuestion = selectedEvalQuestions[i];
        firestore.updateDoc(
          `universities/${firestore.userData.universityId}/classes/${classRef.id}/lectures/${trackedDateFormat}`,
          {
            topics: [],
            questionIds: [],
          },
          { merge: true }
        );
        i = i + 1;
      }
    }

    setCourseName("");
    setCourseTitle("");
    setIsAsync(false);
    // setSelectedEvalQuestions([]);
    // setSelectedEvalQuestionsText([]);
    setStartDate("");
    setEndDate("");
    setIsOpen(false);
    setClassDays([0, 0, 0, 0, 0, 0, 0]);
    setClassTimes({
      0: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      1: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      2: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      3: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      4: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      5: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
      6: {
        startTime: new Date(),
        endTime: new Date(new Date().getTime() + 60 * 60 * 1000),
      },
    });

    notification.success("Class successfully created!");
  };

  /**
   * Adds a time to a class
   *
   * @param {Event} event the event triggered on form submit
   */
  // const addTime = (event) => {
  //   event.preventDefault();

  //   const startDate = new Date();
  //   const endDate = new Date();

  //   startDate.setHours(...startTime.split(":"), 0);
  //   endDate.setHours(...endTime.split(":"), 0);

  //   const newClassDay = selectedDay.label;
  //   if (classDays.includes(newClassDay) === false) {
  //     const updatedClassDays = [...classDays, newClassDay];
  //     setClassDays(updatedClassDays);
  //   }

  //   setTimes((prevTimes) => {
  //     if (endDate < startDate) {
  //       notification.warn("Select an end time after the start time");
  //       return prevTimes;
  //     }

  //     const updatedTimes = {
  //       ...prevTimes,
  //       [selectedDay.value]: {
  //         startTime: startDate,
  //         endTime: endDate,
  //       },
  //     };

  //     return updatedTimes;
  //   });
  // };

  //GROUP SPECIFIC FUNCTIONS

  /**
   * Adds the user to a group using a join code, if they are not
   * already enrolled in the group
   */
  const joinGroup = () => {
    firestore
      .getDocument(
        `universities/${firestore.userData.universityId}/joincodes/${groupJoinCode}`
      )
      .then((doc) => {
        const classId = firestore.docData(doc).classId;

        if (firestore.userData.classList.includes(classId)) {
          notification.error("Already enrolled in class");
        } else {
          firestore.updateUser(auth.user.uid, {
            classList: firestore.arrayUnion(classId),
          });

          firestore.setUserData({
            ...firestore.userData,
            classList: [...firestore.userData.classList, classId],
          });

          if (firestore.userData.role !== "admin") {
            // Add all non admin users to enrolled
            firestore.createDoc(
              `universities/${firestore.userData.universityId}/classes/${classId}/enrolled/${auth.user.uid}`,
              false,
              {
                email: firestore.userData.email,
                firstName: firestore.userData.firstName,
                lastName: firestore.userData.lastName,
                joinReason: "",
              }
            );
          }

          notification.success("Group successfully joined");
        }
      })
      .catch((e) => {
        notification.error("Invalid join code");
      });
  };

  /**
   * Adds the user to a class using a join code, if they are not
   * already enrolled in the class
   *
   *
   * Because join codes have the same structure for both classes and groups, we also
   * check to ensure that a teacher is joining a class and not trying to join a group
   */
  const joinClass = () => {
    //getting the classID corresponding to the inputted join code by accessing the
    //joincode collection in the db

    firestore
      .getDocument(
        `universities/${firestore.userData.universityId}/joincodes/${classJoinCode}`
      )
      .then((doc) => {
        const classId = firestore.docData(doc).classId;
        firestore
          .getDocument(
            `universities/${firestore.userData.universityId}/classes/${classId}`
          )
          .then((doc) => {
            const classType = firestore.docData(doc).type; //getting the type of the object pulled from the db (either group or class)
            if (classType === "group") {
              notification.error(
                "That is a group join code. You cannot join a group as a teacher. Request an admin account to do so."
              );
            } else if (firestore.userData.classList.includes(classId)) {
              notification.error("Already enrolled in class");
            } else {
              firestore.updateUser(auth.user.uid, {
                classList: firestore.arrayUnion(classId),
              });

              firestore.setUserData({
                ...firestore.userData,
                classList: [...firestore.userData.classList, classId],
              });

              if (firestore.userData.role !== "admin") {
                // Add all non admin users to enrolled
                firestore.createDoc(
                  `universities/${firestore.userData.universityId}/classes/${classId}/enrolled/${auth.user.uid}`,
                  false,
                  {
                    email: firestore.userData.email,
                    firstName: firestore.userData.firstName,
                    lastName: firestore.userData.lastName,
                    joinReason: classJoinReason,
                  }
                );
              }

              notification.success("Class successfully joined");
            }
          });
      })
      .catch((e) => {
        notification.error("Invalid join code");
      });
  };

  /**
   * Add a class to the database and to the current user
   *
   * @param {Event} event the event triggered when the form is submit
   */
  const addGroup = async (event) => {
    event.preventDefault();

    // Generate a new join code for the class
    let joinCode;

    do {
      joinCode = generateRandomCode(6);
    } while (await codeExists(joinCode));

    let classRef = await firestore.createDoc(
      `universities/${firestore.userData.universityId}/classes`,
      true,
      {
        name: groupName,
        title: "",
        teacherUID: auth.user.uid,
        joinCode,
        tags: [],
        type: "group",
        // selectedEvalQuestions,
      }
    );

    firestore.updateUser(auth.user.uid, {
      classList: firestore.arrayUnion(classRef.id),
    });
    firestore.setUserData({
      ...firestore.userData,
      classList: [...firestore.userData.classList, classRef.id],
    });

    firestore.createDoc(
      `universities/${firestore.userData.universityId}/joincodes/${joinCode}`,
      false,
      {
        classId: classRef.id,
      }
    );

    setGroupName("");

    setIsOpen(false);
    notification.success("Group successfully created!");
  };

  /**
   * Deletes a time for a class
   * @param {Number} index index of the time to delete
   */
  // const deleteTime = (day) => {
  //   setTimes((prevTimes) => {
  //     let updatedTimes = { ...prevTimes };
  //     delete updatedTimes[day];
  //     return updatedTimes;
  //   });
  // };

  function getWeekdayName(date) {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayIndex = date.getDay();
    return weekdays[dayIndex];
  }

  /**
   * Convert date string from YYYY-MM-DD to MM-DD-YYYY
   *
   * @param {String} dateString date string formatted in YYYY-MM-DD to convert
   * @returns date string formatted to MM-DD-YYYY
   */
  const yearMonthDayToMonthDayYear = (dateString) => {
    const [year, month, day] = dateString.split("-");

    return `${month}-${day}-${year}`;
  };

  /**
   * Check if a join code exists
   *
   * @param {String} code
   * @returns true if the code exists, otherwise false
   */
  const codeExists = async (code) => {
    return (
      (await firestore.getDocument(
        `universities/${firestore.userData.universityId}/joincodes/${code}`
      )) !== null
    );
  };

  /**
   * Generates a random code with the specified length using
   * uppercase and lowercase letters and numbers
   *
   * @param {Number} length
   * @returns a randomly generated code
   */
  const generateRandomCode = (length) => {
    const characters = "ABCDEFGHJKLMNPQRSTUVWKYZ123456789";

    return [...new Array(length)].reduce(
      (accumulator, _) =>
        (accumulator +=
          characters[Math.floor(Math.random() * characters.length)]),
      ""
    );
  };

  return (
    <PopupModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={resetForms}>
      {role === "admin" && (
        <>
          {selectionType === null && (
            <div>
              <div className="GroupClassModal__modal-contents">
                <h1 style={{ textAlign: "center" }}>Join or Add</h1>

                <button
                  className="cta-btn"
                  onClick={() => setSelectionType(true)}
                >
                  Class
                </button>
                <button
                  className="cta-btn"
                  onClick={() => setSelectionType(false)}
                >
                  Group
                </button>
              </div>
            </div>
          )}

          {role === "admin" && selectionType === false && (
            <div className="AddClassModal__modal-contents">
              <TabSwitcher
                options={modalTabsGroup}
                selectedValue={selectedTabGroup}
                setSelectedValue={setSelectedTabGroup}
              />

              {selectedTabGroup === modalOptionsGroup[0] && (
                <div className="AddClassModal__modal-tab">
                  <div className="AddClassModal__create-class-form">
                    <h1>Group Information</h1>

                    {/* <div className="AddClassModal__async-toggle">
                  <span className="text-small">Asynchronous</span>
                  <ToggleInput
                    checked={isAsync}
                    onChange={(event) => setIsAsync(event.target.checked)}
                  />
                </div> */}

                    <input
                      type="text"
                      placeholder="Group Name"
                      value={groupName}
                      onChange={(event) => setGroupName(event.target.value)}
                      required
                    />
                  </div>

                  <div className="AddClassModal__bottom-btns">
                    <button
                      className="cta-btn-gray"
                      onClick={() => {
                        resetForms();
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="cta-btn"
                      onClick={addGroup}
                      disabled={!groupName}
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}

              {selectedTabGroup === modalOptionsGroup[1] && (
                <div className="AddClassModal__modal-tab">
                  <div className="AddClassModal__join-code-form">
                    <h1>Enter Join Code</h1>

                    <CodeForm onChange={setGroupJoinCode} />

                    {firestore.userData?.role === "teacher" && (
                      <p>For TA's and colleagues to view class data</p>
                    )}

                    <div className="AddClassModal__bottom-btns">
                      <button
                        className="cta-btn-gray"
                        onClick={() => {
                          resetForms();
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="cta-btn"
                        onClick={joinGroup}
                        disabled={groupJoinCode.length < 6}
                      >
                        <h3>Join</h3>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {(role === "teacher" || (role === "admin" && selectionType === true)) && (
        <div>
          <div>
            <div className="AddClassModal__modal-contents">
              <TabSwitcher
                options={modalTabsClass}
                selectedValue={selectedTabClass}
                setSelectedValue={setSelectedTabClass}
              />

              {selectedTabClass === modalOptionsClass[0] && (
                <div className="AddClassModal__modal-tab">
                  <div className="AddClassModal__create-class-form">
                    <h1>Class Information</h1>

                    {/* <div className="AddClassModal__async-toggle">
                <span className="text-small">Asynchronous</span>
                <ToggleInput
                  checked={isAsync}
                  onChange={(event) => setIsAsync(event.target.checked)}
                />
              </div> */}

                    <input
                      type="text"
                      placeholder="Course Name (ex: MATH-101-001)"
                      value={courseName}
                      onChange={(event) => setCourseName(event.target.value)}
                      required
                    />
                    <input
                      type="text"
                      placeholder="Course Title (ex: Concepts of Math)"
                      value={courseTitle}
                      onChange={(event) => setCourseTitle(event.target.value)}
                      required
                    />

                    <div className="AddClassModal__class-duration">
                      <div style={{ textAlign: "center" }}>
                        <p>Start Date</p>
                        <input
                          type="date"
                          value={startDate}
                          onChange={(event) => setStartDate(event.target.value)}
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <p>End Date</p>
                        <input
                          type="date"
                          value={endDate}
                          onChange={(event) => setEndDate(event.target.value)}
                        />
                      </div>
                    </div>

                    <h2>Course Schedule</h2>
                    {!isAsync && (
                      <div>
                        {weekdayOptions.map((option, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <button
                              style={{ width: "20px" }}
                              onClick={() => updateClassDays(i)}
                              className={
                                classDays[i] === 1
                                  ? "GroupClassModal__selected"
                                  : "GroupClassModal__unselected"
                              }
                            >
                              {option.label}
                            </button>

                            <TimeInput
                              value={classTimes[i]["startTime"].toLocaleString(
                                [],
                                {
                                  hourCycle: "h23",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                              onChange={(event) => {
                                updateClassTimes(
                                  0,
                                  option.value,
                                  event.target.value
                                );
                              }}
                              disabled={isAsync}
                            />
                            <span className="text-small">to</span>
                            <TimeInput
                              value={classTimes[i]["endTime"].toLocaleString(
                                [],
                                {
                                  hourCycle: "h23",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                              onChange={(event) =>
                                updateClassTimes(
                                  1,
                                  option.value,
                                  event.target.value
                                )
                              }
                              disabled={isAsync}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {/* {!isAsync && (
                      <form
                        className="AddClassModal__add-time-form"
                        onSubmit={addTime}
                      >
                        <h2 style={{ width: "100%", textAlign: "center" }}>
                          Enter Course Schedule
                        </h2>

                        <div className="AddClassModal__time-and-add">
                          <div className="AddClassModal__time-selector">
                            <TimeInput
                              value={startTime}
                              onChange={(event) => {
                                setStartTime(event.target.value);

                                const endDate = new Date();

                                endDate.setHours(
                                  ...event.target.value.split(":"),
                                  0
                                );
                                endDate.setHours(endDate.getHours() + 1);

                                setEndTime(
                                  endDate.toLocaleString([], {
                                    hourCycle: "h23",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                );
                              }}
                              disabled={isAsync}
                            />
                            <span className="text-small">to</span>
                            <TimeInput
                              value={endTime}
                              onChange={(event) =>
                                setEndTime(event.target.value)
                              }
                              disabled={isAsync}
                            />
                          </div>

                          <IconButton
                            icon={faPlus}
                            type="submit"
                            disabled={isAsync}
                          />
                        </div>
                      </form>
                    )} */}

                    {/* {!isAsync && (
                      <div className="AddClassModal__time-list">
                        {Object.keys(times).map((day, i) => (
                          <TimeCard
                            key={i}
                            time={{
                              weekday: day,
                              ...times[day],
                            }}
                            handleDelete={() => deleteTime(day)}
                          />
                        ))}
                      </div>
                    )} */}
                  </div>

                  {/* <h2>Select Pulse Questions</h2>
            <p style={{ textAlign: "center" }}>
              Select 3 Questions You Would Like to Consistently Ask Your
              Students Throughout the Semester
            </p>
            <div className="AddClassModal__eval-questions-selection">
              <form
                onSubmit={addEvalQuestion}
                style={{
                  width: "90%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Select
                    options={evalQuestions}
                    value={selectedEvalQuestion}
                    onChange={setSelectedEvalQuestion}
                    className="AddClassModal__select"
                  />

                  <IconButton icon={faPlus} type="submit" disabled={isAsync} />
                </div>
              </form>
            </div> */}
                  {/* 
            <div>
              {selectedEvalQuestionsText.map((evalQuestion, i) => {
                return (
                  <div className="AddClassModal__selected-eval-question-div">
                    <p key={i}>{evalQuestion}</p>
                    <IconButton
                      className="CalendarTopicModal__trash"
                      icon={faXmark}
                      onClick={() => {
                        deleteEvalQuestion(i);
                      }}
                    />
                  </div>
                );
              })}
            </div> */}

                  <div className="AddClassModal__bottom-btns">
                    <button
                      className="cta-btn-gray"
                      onClick={() => {
                        resetForms();
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="cta-btn"
                      onClick={addClass}
                      disabled={
                        !courseName ||
                        !courseTitle ||
                        (!isAsync && daySelected === false)
                      }
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}

              {selectedTabClass === modalOptionsClass[1] && (
                <div className="AddClassModal__modal-tab">
                  <div className="AddClassModal__join-code-form">
                    <h1>Enter Join Code</h1>

                    <CodeForm onChange={setClassJoinCode} />

                    {firestore.userData?.role === "teacher" && (
                      <p>For TA's and colleagues to view class data</p>
                    )}

                    {firestore.userData?.role === "student" && (
                      <Select
                        options={classJoinReasons.map((value) => ({
                          label: value,
                          value,
                        }))}
                        placeholder="Class Join Reason"
                        className="AddClassModal__select"
                        classNamePrefix="Select"
                        value={classJoinReason}
                        onChange={setClassJoinReason}
                        maxMenuHeight={112}
                      />
                    )}

                    <div className="AddClassModal__bottom-btns">
                      <button
                        className="cta-btn-gray"
                        onClick={() => {
                          resetForms();
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        className="cta-btn"
                        onClick={joinClass}
                        disabled={classJoinCode.length < 6 && !classJoinReason}
                      >
                        <h3>Join</h3>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </PopupModal>
  );
};

export default GroupClassModal;
