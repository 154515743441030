import React from "react";

import IconButton from "../IconButton/IconButton";

import "./PercentDisplay.css";

const PercentDisplay = ({ icon, title, percent = 0 }) => {
  return (
    <div className="PercentDisplay__container">
      <IconButton clickable={false} icon={icon} scale={2} />

      <div className="PercentDisplay__data">
        <div className="PercentDisplay__header">
          <h3>{title}</h3>
          <h3>{Math.round(percent)}%</h3>
        </div>

        <div className="PercentDisplay__progress-container">
          <div
            className="PercentDisplay__percent-bar"
            style={{ width: `${percent}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default PercentDisplay;
