import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
  } from "react";
  
  import {
    collection,
    doc,
    getDoc,
    getDocs,
    deleteDoc,
    setDoc,
    addDoc,
    query as queryDB,
    onSnapshot,
    arrayUnion,
    arrayRemove,
    where,
    orderBy,
    deleteField,
    startAfter,
    endBefore,
    limit,
    serverTimestamp,
    getCountFromServer,
    documentId,
    increment
  } from "@firebase/firestore";
  
  import { firestore } from "../services/firebase";
  import { useNotification } from "./NotificationProvider";
  import { useAuth } from "./AuthProvider";
  
  const FirestoreContext = createContext({});
  
  const FirestoreProvider = ({ children }) => {
    const NO_AUTH_USER = "no-auth-user";
    const CREATING_USER_DOC = "creating-user-doc";
    const LOADED_USER_DOC = "loaded-user-doc";
    const LOADING_USER_DOC = "loading-user-doc";
    const NO_USER_DOC = "no-user-doc";
  
    const auth = useAuth();
    const notification = useNotification();
  
    const [userData, setUserData] = useState({});
    const [state, setState] = useState(LOADING_USER_DOC);
  
    /**
     * Convert a string into a document or collection reference. References passed into this
     * will remain the same
     *
     * @param {String | DocumentReference | CollectionReference} ref path to convert
     *
     * @returns {DocumentReference | CollectionReference} the converted reference
     */
    const stringToRef = useCallback((ref) => {
      if (typeof ref === "string") {
        if (ref.split("/").length % 2 === 0) {
          // ref is a document
          return doc(firestore, ref);
        } else {
          // ref is a collection
          return collection(firestore, ref);
        }
      }
  
      return ref;
    }, []);
  
    /**
     * Inserts the document id into the document data, then returns the data
     *
     * @param {DocumentSnapshot<any>} docSnapshot document snapshot to read data from
     *
     * @returns {Object} data contained in the document along with the document id
     */
    const docData = useCallback((docSnapshot) => {
      return docSnapshot?.exists()
        ? {
            ...docSnapshot.data(),
            id: docSnapshot.id,
          }
        : null;
    }, []);
  
    /**
     * Queries a collection using the given constraints
     *
     * @param {String | CollectionReference} ref
     * @param {QueryConstraint[]} queryConstraints constraints for the query
     *
     * @return {Query<any>} the query results
     */
    const query = useCallback(
      (ref, ...queryConstraints) => {
        ref = stringToRef(ref);
  
        if (ref.type !== "collection") {
          return null;
        }
  
        return queryDB(ref, ...queryConstraints);
      },
      [stringToRef]
    );
  
    /**
     * Subscribes to a document,
     * running the success callback each time changes are detected
     *
     * @param {String | DocumentReference<any>} ref reference to subscribe to
     * @param {Function} successCallback function to be called on success
     * @param {Function} errorCallback optional, function to be called on error
     *
     * @returns {Function} function to call to unsubscribe from the reference
     */
    const subscribe = useCallback(
      (ref, successCallback, errorCallback) => {
        ref = stringToRef(ref);
  
        return onSnapshot(ref, (result) => {
          if (result === undefined) {
            if (!errorCallback) {
              notification.error("Error retrieving data");
            } else {
              errorCallback();
            }
          } else {
            successCallback(result);
          }
        });
      },
      [notification, stringToRef]
    );
  
    /**
     * Subscribes to a query,
     * running the success callback each time changes are detected
     *
     * @param {Query<any>} ref reference to subscribe to
     * @param {Function} successCallback function to be called on success
     * @param {Function} errorCallback optional, function to be called on error
     *
     * @returns {Function} function to call to unsubscribe from the reference
     */
    const subscribeToQuery = useCallback(
      (q, successCallback, errorCallback) => {
        return onSnapshot(q, (result) => {
          if (result === undefined) {
            if (!errorCallback) {
              notification.error("Error retrieving data");
            } else {
              errorCallback();
            }
          } else {
            successCallback(result);
          }
        });
      },
      [notification]
    );
  
    /**
     * Gets the number of documents that match a query
     *
     * @param {Query<any>} query the query to count
     *
     * @returns {Number} number of matching documents
     */
    const count = useCallback((query, successCallback) => {
      const promise = getCountFromServer(query);
  
      if (successCallback) {
        promise.then((result) => {
          successCallback(result.data().count);
        });
      } else {
        return promise;
      }
    }, []);
  
    /**
     * Creates a document with the given path, generating a random ID if autoId is set to true, and
     * populating the new document with the specified data
     *
     * @param {String} path the path where the new document should be added. If autoId is set to true,
     * this should be the path to the collection the document should be added to
     * @param {Boolean} autoId set to true to automatically generate a document ID
     * @param {Object} data the values to populate the new document with
     *
     * @returns {DocumentReference<{}>} reference to the newly created document
     */
    const createDoc = useCallback(async (path, autoId = false, data = {}) => {
      return new Promise((resolve, reject) => {
        if (autoId) {
          addDoc(collection(firestore, path), data)
            .then((result) => resolve(result))
            .catch((error) => reject(error));
        } else {
          setDoc(doc(firestore, path), data)
            .then((result) => resolve(result))
            .catch((error) => reject(error));
        }
      });
    }, []);
  
    /**
     * Gets the data of the document with the specified path
     *
     * @param {String | DocumentReference<any>} ref reference to the document to retrieve
     *
     * @returns {Object} data contained in the document along with the document id
     */
    const getDocument = useCallback(
      (ref) => {
        return new Promise((resolve, reject) => {
          ref = stringToRef(ref);
  
          if (ref.type !== "document") {
            reject("Invalid document reference");
          }
  
          getDoc(ref)
            .then((docSnapshot) => {
              if (!docSnapshot.exists()) {
                resolve(null);
              }
  
              resolve(docSnapshot);
            })
            .catch((error) => reject(error));
        });
      },
      [stringToRef]
    );
  
    /**
     * Gets the a query snapshot of the documents matching the specified reference and where clause
     *
     * @param {String | DocumentReference<any>} ref reference to the collection containing the
     * documents to retrieve
     * @param {QueryFieldFilterConstraint | null} where optional where clause
     *
     * @returns {QuerySnapshot<any>} query snapshot containing the requested documents
     */
    const getDocuments = useCallback((query) => {
      return getDocs(query);
    }, []);
  
    /**
     * Updates a document with the provided data
     *
     * @param {String | DocumentReference<any>} ref reference to the document to update
     * @param {Object} data values to set in the document
     * @param {Object} options configuration for how to set the data. To merge with existing data,
     * include the key-value pair merge: true
     */
    const updateDoc = useCallback(
      (ref, data, options) => {
        return new Promise((resolve, reject) => {
          ref = stringToRef(ref);
  
          if (ref.type !== "document") {
            return;
          }
  
          setDoc(ref, data, options)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              console.log(error.code);
              reject(error);
            });
        });
      },
      [stringToRef]
    );
  
    /**
     * Get the user document
     */
    const getUser = useCallback(
      async (uid) => {
        return await getDocument(`users/${uid}`);
      },
      [getDocument]
    );
  
    /**
     * Updates the data contained in the specified user's document. Defaults to merging the data
     *
     * @param {String} uid ID of the user to update
     * @param {Object} userData values to set in the document
     * @param {Object} options configuration for how to set the data. Defaults to { merge: true }
     */
    const updateUser = useCallback(
      (uid, userData, options = { merge: true }) => {
        updateDoc(doc(firestore, "users", uid), userData, options);
      },
      [updateDoc]
    );
  
    const removeDocument = useCallback(
      (ref) => {
        return new Promise((resolve, reject) => {
          ref = stringToRef(ref);
          deleteDoc(ref)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              console.log(error.code);
  
              reject(error);
            });
        });
      },
      [stringToRef]
    );
  
    useEffect(() => {
      let isMounted = true;
  
      // Check server every 2 seconds until user data retrieved
      const interval = setInterval(async () => {
        if (auth.user) {
          const user = await getUser(auth.user.uid);
  
          if (isMounted) {
            if (user?.exists()) {
              const data = docData(user);
  
              if (auth.user.uid === data.id) {
                setUserData(data);
                setState(LOADED_USER_DOC);
                clearInterval(interval);
              }
            } else {
              setState((prevState) =>
                prevState !== CREATING_USER_DOC ? NO_USER_DOC : prevState
              );
            }
          }
        } else {
          setState(NO_AUTH_USER);
        }
      }, 2000);
  
      return () => {
        isMounted = false;
  
        clearInterval(interval);
        setUserData(null);
        setState(LOADING_USER_DOC);
      };
    }, [auth.user, auth.user?.uid, getUser, docData]);
  
    const memoizedValue = useMemo(
      () => ({
        userData,
        setUserData,
        state,
        setState,
        docData,
        subscribe,
        subscribeToQuery,
        collection,
        count,
        createDoc,
        getDocs,
        getDocument,
        removeDocument,
        getDocuments,
        increment,
        updateDoc,
        updateUser,
        arrayUnion,
        deleteField,
        deleteDoc,
        arrayRemove,
        where,
        orderBy,
        startAfter,
        endBefore,
        limit,
        query,
        serverTimestamp,
        documentId,
        setDoc,
        doc,
        CREATING_USER_DOC,
        LOADED_USER_DOC,
        LOADING_USER_DOC,
        NO_USER_DOC,
      }),
      [
        count,
        createDoc,
        docData,
        state,
        getDocument,
        getDocuments,
        query,
        removeDocument,
        subscribe,
        subscribeToQuery,
        updateDoc,
        updateUser,
        userData,
      ]
    );
  
    return (
      <FirestoreContext.Provider value={memoizedValue}>
        {children}
      </FirestoreContext.Provider>
    );
  };
  
  const useFirestore = () => {
    return useContext(FirestoreContext);
  };
  
  export { FirestoreProvider, useFirestore };
  