import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AuthForm from "../../components/AuthForm/AuthForm";
import { useAuth } from "../../providers/AuthProvider";
import "./PasswordReset.css";

const PasswordReset = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  /**
   * Sends a reset password email
   */
  const reset = () => {
    if (emailSent) {
      navigate("/login");
    } else {
      auth.resetPassword(email, () => {
        setEmailSent(true);
      });
    }
  };

  const getNavLink = () => {
    return (
      <Link className="text-gray text-small" to="/login">
        Back to login
      </Link>
    );
  };

  return (
    <div className="auth-container">
      <AuthForm
        headerLeft="Forgot Password?"
        subheaderLeft="Enter your email and we'll send a password reset link."
        buttonLeftText="Back to Login"
        buttonLeftLink="/login"
        headerRight={emailSent ? "Email Sent!" : "Reset Password"}
        subheaderRight={
          emailSent
            ? "Check your inbox to continue"
            : "Please enter your email below"
        }
        submitText={emailSent ? "Back to Login" : "Reset"}
        onSubmit={reset}
        navLink={getNavLink()}
      >
        {!emailSent && (
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(data) => setEmail(data.target.value)}
            required
          />
        )}
      </AuthForm>
    </div>
  );
};

export default PasswordReset;
