import React, { useState, useEffect } from "react";
import Select from "react-select";

import AuthForm from "../../components/AuthForm/AuthForm";
import Loader from "../../components/Loader/Loader";
import "./Setup.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";

const role = "student";
const points = 0;
const trophies = 0;

const Setup = () => {
  const years = [...new Array(8)].map((_, i) => ({
    value: new Date().getFullYear() - 1 + i,
    label: new Date().getFullYear() - 1 + i,
  }));

  const auth = useAuth();
  const firestore = useFirestore();
  const notification = useNotification();
  const navigate = useNavigate();

  const [major, setMajor] = useState("");
  const [classYear, setClassYear] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [universityId, setUniversityId] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  /**
   * Get university from email domain
   */
  useEffect(() => {
    if (universityId) {
      return;
    }

    let ignore = false;

    const emailDomain = auth.user.email.split("@")[1];

    const query = firestore.query(
      "universities",
      firestore.where("allowedEmailDomain", "==", emailDomain),
    );

    firestore
      .getDocuments(query)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          throw new Error(
            `Couldn't find a university with allowed email domain ${emailDomain}`,
          );
        }

        if (querySnapshot.size !== 1) {
          throw new Error(
            `Found more than one university with allowed email domain ${emailDomain}`,
          );
        }

        if (!ignore) {
          setUniversityId(querySnapshot.docs[0].id);
        }
      })
      .catch(() => {
        notification.error(
          "There was an error finding your university. Please try again.",
        );
        // Unless we absolutely trust that the university will be found,
        // it might be a good idea to redirect them to avoid creating
        // a user doc with universityId ""
      });

    return () => {
      ignore = true;
    };
  }, [firestore, notification, universityId]);

  if (!universityId) {
    return <Loader />;
  }

  const addAdditionalInfo = async () => {
    if (referralCode.trim()) {
      setSubmitDisabled(true);

      const query = firestore.query(
        "referralCodes",
        firestore.where("referralCode", "==", referralCode.trim()),
      );

      try {
        const querySnapshot = await firestore.getDocuments(query);
        if (querySnapshot.empty) {
          notification.warn("Invalid referral code.");
          return;
        }
      } catch (e) {
        notification.error("Error submitting. Please try again.");
        return;
      } finally {
        setSubmitDisabled(false);
      }
    }

    if (firestore.state === firestore.NO_USER_DOC) {
      firestore.setState(firestore.CREATING_USER_DOC);
    }

    firestore.updateUser(auth.user.uid, {
      universityId: firestore.userData?.universityId || universityId,
      email: auth.user.email,
      classList: [],
      firstName,
      lastName,
      major,
      classYear,
      role,
      points,
      trophies,
      referredBy: referralCode,
    });

    firestore.setUserData((prevUserData) => ({
      universityId: universityId,
      email: auth.user.email,
      ...prevUserData,
      classList: [],
      firstName,
      lastName,
      major,
      classYear,
      role,
      points,
      trophies,
      referredBy: referralCode,
    }));

    navigate("/");
  };

  return (
    <div className="auth-container">
      <AuthForm
        headerLeft="Almost done!"
        subheaderLeft="We just need a bit more information before you can start using Brite."
        headerRight="Additional Information"
        subheaderRight="Please fill out the form below"
        submitText="Continue"
        lastPageSubmitText="Finish"
        onSubmit={addAdditionalInfo}
        submitBtnDisabled={submitDisabled}
      >
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          required
        />

        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          required
        />

        <input
          type="text"
          placeholder="Major"
          value={major}
          onChange={(event) => setMajor(event.target.value)}
          required
        />

        <Select
          options={years}
          placeholder="Graduation Year"
          className="Setup__select"
          classNamePrefix="Select"
          maxMenuHeight={150}
          value={years.find((x) => x.value === classYear)}
          onChange={(data) => setClassYear(data.value)}
          required
        />

        <input
          type="text"
          placeholder="Referral Code (optional)"
          value={referralCode}
          onChange={(event) => setReferralCode(event.target.value)}
        />
      </AuthForm>
    </div>
  );
};

export default Setup;
