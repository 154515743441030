import React, { useState } from "react";

import "./DateInput.css";

const DateInput = ({ value = "", onChange = () => {}, ...props }) => {
  const [inputValue, setInputValue] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);

  /**
   * Handler for the change event of the date input
   *
   * @param {Event} event event triggered on input change
   */
  const handleChange = (event) => {
    setInputValue(event.target.value);

    onChange(event);
  };

  return (
    <input
      type="date"
      className={
        inputValue || hasFocus ? "DateInput__full" : "DateInput__empty"
      }
      value={inputValue}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DateInput;
