import React from "react";

import { secondary } from "../../colors";

import "./IconButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconButton = ({
  icon,
  backgroundColor = `rgb(${secondary})`,
  color = "rgb(0, 0, 0, .8)",
  className = "",
  clickable = true,
  scale = 1,
  ...props
}) => {
  return (
    <button
      className={`${className} IconButton__btn`}
      style={{
        backgroundColor,
        color,
        cursor: clickable ? "pointer" : "default",
        width: `${scale * 2}rem`,
        height: `${scale * 2}rem`,
      }}
      {...props}
    >
      <FontAwesomeIcon size={`${scale}x`} icon={icon} />
    </button>
  );
};

export default IconButton;
