import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import { useAuth } from "../../providers/AuthProvider";
import { useFirestore } from "../../providers/FirestoreProvider";

const Loading = () => {
  const auth = useAuth();
  const firestore = useFirestore();
  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }

    if (!auth.user) {
      navigate("/sign-up");
      return;
    }

    if (firestore.state === firestore.NO_USER_DOC) {
      // If there's no user doc, the user must be a student.
      navigate(auth.user.emailVerified ? "/setup" : "/verify-email");
      return;
    }

    if (
      auth.user.uid === firestore.userData?.id &&
      firestore.state === firestore.LOADED_USER_DOC
    ) {
      if (firestore.userData?.role === "student") {
        navigate(
          state?.previousPage && state?.previousPage !== "/setup"
            ? state.previousPage
            : "/redirect",
        );
      } else {
        navigate(
          state?.previousPage && state?.previousPage !== "/setup"
            ? state.previousPage
            : "/classes",
        );
      }
      return;
    }
  }, [
    auth,
    navigate,
    auth.isLoading,
    firestore.userData?.id,
    firestore.userData?.role,
    firestore?.state,
    firestore.NO_USER_DOC,
    firestore.LOADED_USER_DOC,
    state?.previousPage,
  ]);

  return <Loader />;
};

export default Loading;
