import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../../providers/AuthProvider";
import { useFirestore } from "../../providers/FirestoreProvider";
import { useNotification } from "../../providers/NotificationProvider";
import AuthForm from "../../components/AuthForm/AuthForm";
import "./SignUp.css";

const SignUp = () => {
  const auth = useAuth();
  const firestore = useFirestore();
  const notification = useNotification();
  const navigate = useNavigate();

  const [universities, setUniversities] = useState([]);
  const [universityId, setUniversityId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  /**
   * Get universities
   */
  useEffect(() => {
    let isMounted = true;

    const query = firestore.query(
      "universities",
      firestore.orderBy("name", "desc"),
      firestore.where("name", "!=", "Test University"),
    );

    firestore.getDocuments(query).then((querySnapshot) => {
      const universityList = [];

      querySnapshot.forEach((doc) => {
        const data = firestore.docData(doc);

        universityList.push({
          id: data.id,
          value: data.name,
          label: data.name,
          allowedEmailDomain: data.allowedEmailDomain,
        });
      });

      if (isMounted) {
        setUniversities(universityList);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [firestore]);

  /**
   * Log the user out if they go to this page
   */
  useEffect(() => {
    auth.logout();
  }, [auth]);

  /**
   * Sign up the user
   */
  const signup = () => {
    if (validateEmailDomain() && validatePassword()) {
      auth.signUpWithEmailAndPassword(email, password, navigate);
    }
  };

  /**
   * Check if the email ends with the correct domain.
   */
  const validateEmailDomain = () => {
    const selectedUniversity = universities.find(
      (university) => university.id === universityId,
    );

    if (!selectedUniversity?.allowedEmailDomain) {
      notification.error(
        "Couldn't find selected university. Try refreshing the page or contacting support.",
      );
      return false;
    }

    const domainRe = new RegExp(`@${selectedUniversity.allowedEmailDomain}$`);

    if (!domainRe.test(email)) {
      notification.warn(
        `Please use university provided email ending in '@${selectedUniversity.allowedEmailDomain}'`,
      );
      return false;
    }

    return true;
  };

  /**
   * Checks password validity, ensuring the password contains
   * 8 characters minumum
   * uppercase letters
   * lowercase letters
   * numbers
   * password and confirm password are the same
   *
   * @returns true if valid, otherwise false
   */
  const validatePassword = () => {
    // ensure password is at least 8 characters
    if (password?.length < 8) {
      notification.warn("Password must have at least 8 characters");
      return false;
    }

    // contain uppercase letters
    if (!/[A-Z]/.test(password)) {
      notification.warn("Password must contain an uppercase letter");
      return false;
    }

    // contain lowercase letters
    if (!/[a-z]/.test(password)) {
      notification.warn("Password must contain a lowercase letter");
      return false;
    }

    // contain numbers
    if (!/\d+/.test(password)) {
      notification.warn("Password must contain a number");
      return false;
    }

    if (password !== confirmPassword) {
      notification.warn("Passwords do not match");
      return false;
    }

    return true;
  };

  /**
   * Gets the jsx for an AuthForm navLink
   *
   * @returns {any} jsx for the navLink of an AuthForm component
   */
  const getNavLink = () => {
    return (
      <p className="text-small text-gray">
        Already a member?&nbsp;
        <Link className="text-small no-underline" to="/login">
          Log in
        </Link>
      </p>
    );
  };

  /**
   * Gets the jsx for an AuthForm footer
   *
   * @returns {any} jsx for the footer of an AuthForm component
   */
  const getFooter = () => {};

  return (
    <div className="auth-container">
      <AuthForm
        headerLeft="Welcome to Brite!"
        subheaderLeft="Create an account to get started or click the button below to log in."
        buttonLeftText="Log In"
        buttonLeftLink="/login"
        headerRight="Create Your Student Account"
        subheaderRight="if you are a professor or administrator, please email info@brite.company to create an account"
        submitText="Sign Up"
        navLink={getNavLink()}
        footer={getFooter()}
        onSubmit={signup}
      >
        <Select
          options={universities}
          placeholder="Find your School"
          className="SignUp__select"
          classNamePrefix="Select"
          onChange={(data) => setUniversityId(data.id)}
          required
        />

        <input
          type="email"
          placeholder="School Email"
          value={email}
          onChange={(data) => setEmail(data.target.value)}
          required
        />

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(data) => setPassword(data.target.value)}
          required
        />

        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(data) => setConfirmPassword(data.target.value)}
          required
        />
      </AuthForm>
    </div>
  );
};

export default SignUp;
