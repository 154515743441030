import "./Question.css";

const Question = ({ question, questionsStruct, isSelected, setSelected }) => {
  return (
    <button
      className={
        isSelected
          ? "Question__question-btn Question__selected"
          : "Question__question-btn"
      }
      onClick={setSelected}
    >
      <h3>{questionsStruct[question].question}</h3>
    </button>
  );
};

export default Question;
